import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c("Snackbar", {
        attrs: {
          deleteApiType: "contractors",
          status: _vm.contractorFilterType,
          allItemsSelected: _vm.allContractorsSelected,
          someItemsSelected: _vm.someContractorsSelected,
          showOnlyDelete: true,
          items: _vm.contractorFilterList,
        },
      }),
      _c("ActionListingDialog", {
        attrs: {
          itemName: "Contractors",
          onClickEvent: "proceedToActionOnContractors",
          itemsPreference: this.contractorFilterType,
        },
      }),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogContractor },
        on: {
          cancel: function ($event) {
            _vm.dialogContractor = false
          },
          proceed: _vm.proceedContractorDeletion,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("contractorsDeletePromtTitle")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("contractorsDeletePromtSubtitle")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        VRow,
        {
          staticClass: "flex-grow-0 px-9 pt-4",
          attrs: { "no-gutters": "", cols: "12" },
        },
        [
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(
                VRow,
                { staticStyle: { "max-width": "520px" } },
                [
                  _c(
                    VCol,
                    { staticClass: "px-0", attrs: { cols: "9" } },
                    [
                      _c(VTextField, {
                        staticClass: "rd-rt-no agd-txtfield mr-4",
                        attrs: {
                          placeholder: _vm.$stringConstants(
                            "contractorSearchPlaceholder"
                          ),
                          outlined: "",
                          "prepend-inner-icon": "mdi-magnify",
                          clearable: "",
                          dense: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.applySearchTimer(
                              _vm.applySearchContractorFilter
                            )
                          },
                        },
                        model: {
                          value: _vm.contractorSearchText,
                          callback: function ($$v) {
                            _vm.contractorSearchText = $$v
                          },
                          expression: "contractorSearchText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "px-0", attrs: { cols: "3" } },
                    [
                      _c(VSelect, {
                        staticClass: "rd-lf-no",
                        attrs: {
                          items: _vm.statusFilterTypeList,
                          "item-value": "value",
                          "item-text": "title",
                          placeholder:
                            _vm.$stringConstants("statusPlaceholder"),
                          dense: "",
                          outlined: "",
                          "append-icon": "mdi-chevron-down",
                        },
                        on: { change: _vm.getContactorListByStatus },
                        model: {
                          value: _vm.contractorFilterType,
                          callback: function ($$v) {
                            _vm.contractorFilterType = $$v
                          },
                          expression: "contractorFilterType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(
                VRow,
                { staticClass: "text-right" },
                [
                  _c(
                    VCol,
                    { staticClass: "px-0", attrs: { cols: "12" } },
                    [
                      _c("Button", {
                        staticClass: "mx-4",
                        attrs: {
                          variant: "secondary",
                          outlined: "",
                          outlineColor: "gray",
                          textValue: _vm.$stringConstants("manageText"),
                          leftIcon: "mdi-arrow-down",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.openManageContractorForm($event)
                          },
                        },
                      }),
                      _c("Button", {
                        attrs: {
                          variant: "secondary",
                          color: "primary",
                          outlined: "",
                          outlineColor: "primary",
                          textValue: _vm.$stringConstants("addNewContractor"),
                          leftIcon: "mdi-plus",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.openAddContractorForm($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.contractorFilterType == 0
        ? _c("List", {
            key: "accepted-contractors",
            attrs: { listItems: _vm.contractorFilterList },
            on: {
              setFilterOptionsAndGetListData:
                _vm.setFilterOptionsAndGetContractors,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "list-heading",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "fw-6 list-check width-perc-25" },
                        [
                          _c(
                            "div",
                            { staticClass: "ib" },
                            [
                              _c(VCheckbox, {
                                attrs: {
                                  indeterminate: _vm.someContractorsSelected,
                                  "input-value": _vm.allContractorsSelected,
                                },
                                on: { change: _vm.selectAllContractors },
                                model: {
                                  value: _vm.isChecked,
                                  callback: function ($$v) {
                                    _vm.isChecked = $$v
                                  },
                                  expression: "isChecked",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ib v-a-t pl-2" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("businessName"))
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "width-perc-16" }, [
                        _vm._v(_vm._s(_vm.$stringConstants("emailAddress"))),
                      ]),
                      _c("div", { staticClass: "width-perc-16" }, [
                        _vm._v(_vm._s(_vm.$stringConstants("phoneNumber"))),
                      ]),
                      _c("div", { staticClass: "width-perc-33" }, [
                        _vm._v(_vm._s(_vm.$stringConstants("address"))),
                      ]),
                      _c("div", { staticClass: "width-perc-10" }, [
                        _vm._v("Action"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "list-item",
                  fn: function (ref) {
                    var listItem = ref.listItem
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "list-check width-perc-25 center-text-y",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ib" },
                            [
                              _c(VCheckbox, {
                                attrs: { value: listItem.id, multiple: "" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                                model: {
                                  value: _vm.selectedItems,
                                  callback: function ($$v) {
                                    _vm.selectedItems = $$v
                                  },
                                  expression: "selectedItems",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ib v-a-t pl-2" }, [
                            listItem.businessProfile != null
                              ? _c(
                                  "div",
                                  { staticClass: "fw-6 d-flex align-center" },
                                  [
                                    _c("Avatar", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        initials:
                                          listItem.businessProfile.title,
                                        width: "28px",
                                        height: "28px",
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(listItem.businessProfile.title) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", { staticClass: "fw-6" }, [
                                  _vm._v(" N/A "),
                                ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "width-perc-16 d-flex align-center" },
                        [_c("div", [_vm._v(_vm._s(listItem.email))])]
                      ),
                      _c(
                        "div",
                        { staticClass: "width-perc-16 d-flex align-center" },
                        [
                          _c("div", [
                            _vm._v(_vm._s(listItem.primaryContactNumber)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "width-perc-33 pr-14 d-flex align-center",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getBusinessAddress(listItem.businessProfile)
                            )
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "width-perc-10 position-relative" },
                        [
                          _c(
                            "div",
                            { staticClass: "pt-1" },
                            [
                              _c(
                                VMenu,
                                {
                                  attrs: {
                                    bottom: "",
                                    "min-width": "120",
                                    left: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "triple-dot-menu",
                                                    attrs: { icon: "" },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(VIcon, [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    VList,
                                    [
                                      _c(
                                        VListItem,
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.confirmDeleteContractor(
                                                listItem.id,
                                                _vm.listAction.Archive
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            VListItemTitle,
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticClass: "icon-size pr-2",
                                                },
                                                [_vm._v("mdi-delete")]
                                              ),
                                              _vm._v("Delete "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "zero-state",
                  fn: function () {
                    return [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$stringConstants(
                              "contractorsListZeroStateTitle"
                            )
                          )
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$stringConstants(
                              "contractorsListZeroStateSubtitle"
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3418878983
            ),
          })
        : _vm.contractorFilterType == 1
        ? _c("List", {
            key: "pending-contractors",
            attrs: { listItems: _vm.pendingContractorList, containsAction: "" },
            on: {
              setFilterOptionsAndGetListData:
                _vm.setFilterOptionsAndGetContractors,
            },
            scopedSlots: _vm._u([
              {
                key: "list-heading",
                fn: function () {
                  return [
                    _c("div", { staticClass: "width-perc-20" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("inviteeName"))),
                    ]),
                    _c("div", { staticClass: "width-perc-20" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("inviteeEmail"))),
                    ]),
                    _c("div", { staticClass: "width-perc-20" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("inviteeOn"))),
                    ]),
                    _c("div", { staticClass: "width-perc-20" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("expiredOn"))),
                    ]),
                    _c("div", { staticClass: "width-perc-20 text-right" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("action"))),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "list-item",
                fn: function (ref) {
                  var listItem = ref.listItem
                  return [
                    _c(
                      "div",
                      { staticClass: "width-perc-20 fw-6" },
                      [
                        _c("Avatar", {
                          staticClass: "mr-2",
                          attrs: {
                            initials: listItem.inviteeFirstName,
                            width: "28px",
                            height: "28px",
                          },
                        }),
                        _vm._v(" " + _vm._s(listItem.inviteeFirstName) + " "),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "width-perc-20" }, [
                      _c("div", { staticClass: "tr-6p ct-pr" }, [
                        _vm._v(_vm._s(listItem.inviteeEmail)),
                      ]),
                    ]),
                    _c("div", { staticClass: "width-perc-20 tr-6p" }, [
                      _vm._v(
                        _vm._s(_vm.getTimeDateFormate(listItem.timestamp))
                      ),
                    ]),
                    _c("div", { staticClass: "width-perc-20 tr-6p" }, [
                      _vm._v(_vm._s(_vm.getTimeDateFormate(listItem.expiry))),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "width-perc-20 text-right position-relative",
                      },
                      [
                        _c(
                          VMenu,
                          {
                            attrs: { bottom: "", "min-width": "120", left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "triple-dot-menu",
                                              attrs: { icon: "" },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-dots-vertical"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              VList,
                              [
                                _c(
                                  VListItem,
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmDeleteInvitation(
                                          listItem.code,
                                          "sent"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      VListItemTitle,
                                      [
                                        _c(
                                          VIcon,
                                          { staticClass: "icon-size pr-2" },
                                          [_vm._v("mdi-delete")]
                                        ),
                                        _vm._v("Delete "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "zero-state",
                fn: function () {
                  return [
                    _c("p", { staticClass: "mb-0 font-weight-bold pb-3" }, [
                      _vm._v(
                        _vm._s(_vm.$stringConstants("emptyInvitaionList"))
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }