import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "seed-action-button py-3" },
    [
      _c(
        VBtn,
        {
          attrs: { disabled: _vm.isDisabled, color: "primary", outlined: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.textValue) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }