import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container invoice-list-bottom-offset" },
    [
      _c(
        VRow,
        {
          staticClass: "px-9 flex-grow-0",
          attrs: { "no-gutters": "", cols: "12" },
        },
        [
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "pl-0 pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        { staticClass: "ib" },
                        [
                          _c(VTextField, {
                            staticStyle: { width: "340px" },
                            attrs: {
                              label: "Search jobs",
                              outlined: "",
                              "prepend-inner-icon": "mdi-magnify",
                              dense: "",
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.applySearchJobsListFilter($event)
                              },
                            },
                            model: {
                              value: _vm.jobsForInvoiceSearchTextExternal,
                              callback: function ($$v) {
                                _vm.jobsForInvoiceSearchTextExternal = $$v
                              },
                              expression: "jobsForInvoiceSearchTextExternal",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "ib" },
                        [
                          _c(VAutocomplete, {
                            staticClass: "pt-0 pl-3",
                            staticStyle: {
                              width: "400px",
                              transform: "translateY(-4px)",
                            },
                            attrs: {
                              id: "customersListForInvoiceExternal",
                              items: _vm.customersListForInvoiceExternal,
                              chips: "",
                              "deletable-chips": "",
                              "search-input": _vm.customersSearchTextExternal,
                              "item-text": "displayName",
                              "item-value": "id",
                              "hide-no-data": "",
                              label: "Select customer",
                              outlined: "",
                              dense: "",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.customersSearchTextExternal = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.customersSearchTextExternal = $event
                              },
                              change: _vm.getJobsListFromCustomerId,
                            },
                            nativeOn: {
                              input: function ($event) {
                                return _vm.applySearchCustomersFilter($event)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.$stringConstants("showMiniLoadMoreButton")
                                  ? {
                                      key: "append-item",
                                      fn: function () {
                                        return [
                                          _c("LoadMoreItem", {
                                            attrs: {
                                              searchText:
                                                _vm.customersSearchTextExternal,
                                              loadedListLength:
                                                _vm
                                                  .customersListForInvoiceExternal
                                                  .length,
                                              moduleName: "customersDropdown",
                                            },
                                            on: {
                                              loadMoreItem:
                                                _vm.appendMoreCustomers,
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.selectedCustomerIdForInvoiceExternal,
                              callback: function ($$v) {
                                _vm.selectedCustomerIdForInvoiceExternal = $$v
                              },
                              expression:
                                "selectedCustomerIdForInvoiceExternal",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "ib" },
                        [
                          _c(VAutocomplete, {
                            staticClass: "pt-0 pl-3",
                            staticStyle: {
                              width: "200px",
                              transform: "translateY(-4px)",
                            },
                            attrs: {
                              label: "Tag",
                              "menu-props": {
                                maxWidth: "240px",
                                minWidth: "120px",
                              },
                              items: _vm.invoicesExternalListFilterTagsList,
                              clearable: "",
                              "hide-no-data": "",
                              outlined: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getJobsListFromCustomerId()
                              },
                            },
                            model: {
                              value: _vm.invoicesExternalListFilterTagValue,
                              callback: function ($$v) {
                                _vm.invoicesExternalListFilterTagValue = $$v
                              },
                              expression: "invoicesExternalListFilterTagValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("List", {
        attrs: { listItems: _vm.jobsListForInvoiceExternal },
        on: {
          setFilterOptionsAndGetListData:
            _vm.setFilterOptionsAndGetJobsListForInvoiceExternal,
        },
        scopedSlots: _vm._u([
          {
            key: "list-heading",
            fn: function () {
              return [
                _c("div", { staticClass: "ib width-perc-5 pr-4" }, [
                  _vm.selectedCustomerIdForInvoiceExternal
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.isAllJobsSelectedForExternalInvoice,
                            expression: "isAllJobsSelectedForExternalInvoice",
                          },
                        ],
                        staticClass: "selection-checkbox",
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: _vm.isAllJobsSelectedForExternalInvoice,
                          checked: Array.isArray(
                            _vm.isAllJobsSelectedForExternalInvoice
                          )
                            ? _vm._i(
                                _vm.isAllJobsSelectedForExternalInvoice,
                                _vm.isAllJobsSelectedForExternalInvoice
                              ) > -1
                            : _vm.isAllJobsSelectedForExternalInvoice,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.isAllJobsSelectedForExternalInvoice,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v =
                                    _vm.isAllJobsSelectedForExternalInvoice,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.isAllJobsSelectedForExternalInvoice =
                                      $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.isAllJobsSelectedForExternalInvoice =
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.isAllJobsSelectedForExternalInvoice = $$c
                              }
                            },
                            _vm.toggleSelectAllJobs,
                          ],
                        },
                      })
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "ib width-perc-8 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("jobNo")) + "."),
                ]),
                _c("div", { staticClass: "ib width-perc-15 pr-4 pl-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("operation"))),
                ]),
                _c("div", { staticClass: "ib width-perc-15 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("customer"))),
                ]),
                _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("completedOn"))),
                ]),
                _c("div", { staticClass: "ib width-perc-15 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("completedBy"))),
                ]),
                _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                  _vm._v(_vm._s("Billable Qty")),
                ]),
                _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("tags"))),
                ]),
                _c("div", { staticClass: "ib width-perc-12 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("contact"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "list-item",
            fn: function (ref) {
              var listItem = ref.listItem
              return [
                _c("div", { staticClass: "ib width-perc-5" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedJobsForInvoiceExternal,
                        expression: "selectedJobsForInvoiceExternal",
                      },
                    ],
                    staticClass:
                      "selection-checkbox selection-checkbox-row-item",
                    attrs: { type: "checkbox" },
                    domProps: {
                      value: listItem,
                      checked: Array.isArray(_vm.selectedJobsForInvoiceExternal)
                        ? _vm._i(_vm.selectedJobsForInvoiceExternal, listItem) >
                          -1
                        : _vm.selectedJobsForInvoiceExternal,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.selectedJobsForInvoiceExternal,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = listItem,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.selectedJobsForInvoiceExternal =
                                  $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectedJobsForInvoiceExternal = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectedJobsForInvoiceExternal = $$c
                          }
                        },
                        function ($event) {
                          return _vm.updateSelectedJobs(
                            _vm.selectedJobsForInvoiceExternal
                          )
                        },
                      ],
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "ib width-perc-8 pr-4 vertical-align-top" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.getJobNumberPrefix(listItem.number))
                    ),
                    _c("br"),
                    _vm._v(
                      _vm._s(_vm.getJobNumberSuffix(listItem.number)) + " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ib width-perc-15 pr-4 pl-4 vertical-align-top",
                  },
                  [_vm._v(_vm._s(listItem.operation.title))]
                ),
                _c(
                  "div",
                  { staticClass: "ib width-perc-15 pr-4 vertical-align-top" },
                  [_vm._v(" " + _vm._s(listItem.customer.businessName) + " ")]
                ),
                _c(
                  "div",
                  { staticClass: "ib width-perc-10 pr-4 vertical-align-top" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          listItem.workCompletedOn
                            ? _vm.getDateTimeFormat(listItem.workCompletedOn)
                            : "-"
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "ib width-perc-15 pr-4 vertical-align-top" },
                  [_vm._v(_vm._s(_vm.getAssignedUserName(listItem)))]
                ),
                _c(
                  "div",
                  { staticClass: "ib width-perc-10 pr-4 vertical-align-top" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          listItem.operation.billingQuantities &&
                            listItem.operation.billingQuantities.length
                            ? listItem.operation.billingQuantities[0]
                                .billingQuantity
                            : ""
                        ) +
                        " (" +
                        _vm._s(listItem.operation.billingUnit) +
                        ") "
                    ),
                  ]
                ),
                _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      attrs: { title: _vm.getTagsTitle(listItem.tags) },
                    },
                    [
                      _c("div", [
                        _vm.hasTags(listItem.tags)
                          ? _c("span", { staticClass: "job-tags-list" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getPrimaryTagDisplay(listItem.tags)
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("span", [_vm._v(" - ")]),
                      ]),
                      _vm.hasAdditionalTags(listItem.tags)
                        ? _c(
                            "div",
                            {
                              staticClass: "pt-1 pl-1",
                              style: { color: _vm.brandColor },
                            },
                            [
                              _vm._v(
                                " +" +
                                  _vm._s(
                                    _vm.getAdditionalTagsCount(listItem.tags)
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "ib width-perc-12 pr-4 vertical-align-top" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          listItem.customer.phoneNumber
                            ? listItem.customer.phoneNumber
                            : "N/A"
                        ) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          listItem.customer.email
                            ? listItem.customer.email
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "zero-state",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("invoiceExternalNoJobsZeroStateText")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }