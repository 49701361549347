import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.object.to-string.js";
import { TeamJobStatusTypeLabel, TeamJobStatusType } from '@/enum/teamJobStatusType';
import { jobColors } from '@/store/modules/jobs-calendar/config';
import { getDateTimeFormattedLocal } from '@/utils/helpers';
import { getCalculatedFieldArea } from '@/utils/helpers/jobs-helpers';
export default {
  name: 'CalendarInfoWindow',
  props: {
    selectedEvent: Object,
    jobInfoWindowPosition: Object
  },
  data: function data() {
    return {
      TeamJobStatusType: TeamJobStatusType
    };
  },
  methods: {
    getStatusText: function getStatusText(status) {
      return TeamJobStatusTypeLabel.get(status);
    },
    getBackgroundColor: function getBackgroundColor(eventStatus) {
      var _a, _b;
      var filteredColor = (_b = (_a = jobColors.filter(function (c) {
        return c.type === eventStatus;
      })) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.dark;
      return filteredColor || '#E9ECF0';
    },
    getOperatorNames: function getOperatorNames(operators) {
      return operators.map(function (operator) {
        return operator.name;
      }).join(', ');
    },
    getFieldNames: function getFieldNames(fields) {
      return fields.map(function (field) {
        return field.title;
      }).join(', ');
    },
    getTotalsFieldsArea: function getTotalsFieldsArea(fields) {
      return getCalculatedFieldArea(fields);
    },
    getDateTimeFormat: function getDateTimeFormat(dateTime) {
      return getDateTimeFormattedLocal(dateTime);
    }
  }
};