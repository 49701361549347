import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.object.to-string.js";
import $ from "jquery";
import { openOverlay, clearDateTimePicker } from "@/utils/helpers";
import { mapMutations } from "vuex";
import scrollerMixin from "@/mixins/scroller-mixin";
import usersComputedMixin from "./su-users-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { DeleteItemDialog } from "@/components/shared/dialogs/index";
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/search-timers';
import { getDateTimeFormat as _getDateTimeFormat, getDateTimeFormattedLocal as _getDateTimeFormattedLocal } from '@/utils/helpers';
import Avatar from '@/components/shared/avatars/avatar.vue';
import { apiAdminUsers } from '@/utils/endpoints';
import { ExcelExportModuleType } from '@/enum/excelExportModuleType';
import { excelParser } from "@/utils/helpers/excel-parser-helpers";
import { ViewPreferenceType } from '@/enum/viewPreferenceType';
export default {
  name: "UsersListView",
  mixins: [scrollerMixin, usersComputedMixin, Timers],
  data: function data() {
    return {
      showExportDialog: false,
      totalRowCountTemp: null,
      showDeleteDialog: false,
      userDeleteId: null,
      userDeleteName: null
    };
  },
  components: {
    Button: Button,
    List: List,
    Avatar: Avatar,
    DeleteItemDialog: DeleteItemDialog
  },
  beforeMount: function beforeMount() {
    this.resetPagingAndFilters();
  },
  beforeDestroy: function beforeDestroy() {
    this.resetPaging();
    this.clearUsersFilterOptions();
  },
  mounted: function mounted() {
    this.getSuUsersList();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearUsersFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging",
    resetUsersListFilter: "resetUsersListFilter"
  })), {}, {
    resetPagingAndFilters: function resetPagingAndFilters() {
      this.usersList = [];
      this.resetPaging();
      this.clearUsersFilterOptions();
      this.clearFilterStates('userRole');
      this.clearFilterStates('dateRange');
    },
    getDateTimeFormat: function getDateTimeFormat(dateTime) {
      return _getDateTimeFormat(dateTime);
    },
    getDateTimeFormattedLocal: function getDateTimeFormattedLocal(dateTime) {
      return _getDateTimeFormattedLocal(dateTime);
    },
    openUsersFilterForm: function openUsersFilterForm() {
      this.usersFiltersApplied = false;
      openOverlay("usersFilterFormOverlay");
    },
    clearFilterStates: function clearFilterStates(filterType) {
      switch (filterType) {
        case 'userRole':
          this.usersListRoles = null;
          break;
        case 'dateRange':
          this.usersListStartDate = this.usersListEndDate = null;
          $('.vdpr-datepicker input[type=text]').first().val('');
          $('.vdpr-datepicker input').first().css({
            width: "110px"
          });
          clearDateTimePicker(document.querySelector('.vdpr-datepicker'));
          this.usersListStartDate = this.usersListEndDate = null;
          break;
        default:
          return;
      }
      this.applyUsersFilter();
    },
    applyUsersFilter: function applyUsersFilter() {
      this.clearUsersFilterOptions();
      this.resetPaging();
      this.usersList = [];
      this.usersFiltersApplied = true;
      this.getSuUsersList();
    },
    clearAndResetUsersPagingStates: function clearAndResetUsersPagingStates() {
      $(window).unbind("scroll");
      this.$store.state.suUsersModule.usersList = [];
      this.clearUsersFilterOptions();
      this.resetPaging();
      this.usersFiltersApplied = false;
    },
    getSuUsersList: function getSuUsersList() {
      var _this = this;
      var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize];
      this.$store.dispatch('setSuUsersListFilter', filter).then(function () {
        _this.$store.dispatch('getSuUsersList');
      });
    },
    setFilterOptionsAndGetUsers: function setFilterOptionsAndGetUsers(emittedFilters) {
      if (!this.usersSearchText) {
        this.clearUsersFilterOptions();
        this.usersFiltersApplied = false;
      }
      this.getSuUsersList(emittedFilters);
    },
    initiateUsersExport: function initiateUsersExport() {
      this.totalRowCountTemp = this.totalRowCount;
      this.$store.commit('resetExportData');
      this.clearUsersFilterOptions();
      this.resetPaging();
      this.showExportDialog = true;
      this.getUsersDataForDownload();
    },
    getUsersDataForDownload: function getUsersDataForDownload() {
      var _this2 = this;
      var exportFilters = [];
      if (this.usersSearchText) {
        exportFilters.push({
          searchText: this.usersSearchText
        });
      }
      if (this.usersListRoles) {
        exportFilters.push({
          roles: this.usersListRoles.toLowerCase()
        });
      }
      if (this.usersListStartDate) {
        exportFilters.push({
          start: this.usersListStartDate
        });
      }
      if (this.usersListEndDate) {
        exportFilters.push({
          end: this.usersListEndDate
        });
      }
      exportFilters.push({
        preference: ViewPreferenceType.All
      });
      this.$store.dispatch("setExportDataParameterFilters", exportFilters).then(function () {
        _this2.$store.dispatch("getDataListForExport", {
          apiUrl: apiAdminUsers,
          moduleType: ExcelExportModuleType.Users
        }).then(function () {
          _this2.getMoreUsersForExport();
        });
      });
    },
    resetPagingAndList: function resetPagingAndList() {
      this.resetPaging();
      this.clearUsersFilterOptions();
      this.resetUsersListFilter();
      this.totalRowCount = this.totalRowCountTemp;
      this.usersList = [];
      this.getSuUsersList();
    },
    getMoreUsersForExport: function getMoreUsersForExport() {
      var _this3 = this;
      this.$store.dispatch("getDataListForExport", {
        apiUrl: apiAdminUsers,
        moduleType: ExcelExportModuleType.Users
      }).then(function () {
        if (_this3.exportDataLoaded < _this3.exportDataTotal) {
          _this3.getMoreUsersForExport();
        } else {
          if (_this3.exportData.length > 0) {
            _this3.showExportDialog = false;
            excelParser().saveDataToExcel();
            _this3.resetPagingAndList();
          }
        }
      });
    },
    prmoptUserDelete: function prmoptUserDelete(userId, userName) {
      this.userDeleteId = userId;
      this.userDeleteName = userName;
      this.showDeleteDialog = true;
    },
    proceedUserDelete: function proceedUserDelete() {
      var _this4 = this;
      this.showDeleteDialog = false;
      this.userDeleteName = null;
      this.$store.dispatch('deleteSuUser', this.userDeleteId).then(function (res) {
        if (res) {
          _this4.usersList = _this4.usersList.filter(function (u) {
            return u.id !== _this4.userDeleteId;
          });
        }
      });
    },
    cancelUserDelete: function cancelUserDelete() {
      this.userDeleteId = null;
      this.userDeleteName = null;
      this.showDeleteDialog = false;
    }
  })
};