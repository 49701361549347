import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.shouldComponentDisplay(_vm.componentsPermissions["operationRead"])
        ? _c(
            "div",
            [
              _c(
                "div",
                [
                  _c(
                    VRow,
                    {
                      staticClass: "px-9 pt-4",
                      attrs: { "no-gutters": "", cols: "12" },
                    },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                { staticClass: "pl-0", attrs: { cols: "6" } },
                                [
                                  _c(VTextField, {
                                    staticClass: "agd-txtfield mr-4",
                                    attrs: {
                                      placeholder: _vm.$stringConstants(
                                        "searchOperationPlaceholder"
                                      ),
                                      outlined: "",
                                      "prepend-inner-icon": "mdi-magnify",
                                      clearable: "",
                                      dense: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.applySearchTimer(
                                          _vm.applySearchOperationsFilter
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.operationSearchText,
                                      callback: function ($$v) {
                                        _vm.operationSearchText = $$v
                                      },
                                      expression: "operationSearchText",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(VCol, { attrs: { cols: "6" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.shouldComponentDisplay(
                        _vm.componentsPermissions["operationCreate"]
                      )
                        ? _c(
                            VCol,
                            { attrs: { cols: "6 text-right pt-3" } },
                            [
                              _c("Button", {
                                attrs: {
                                  variant: "secondary",
                                  color: "primary",
                                  outlined: "",
                                  outlineColor: "primary",
                                  textValue: "Manage categories",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleManageOperationsClick(
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.noData
                ? _c("div", { staticClass: "scroll-view" }, [
                    _c(
                      "div",
                      { staticClass: "pb-4 list-heading" },
                      [
                        _c(
                          VRow,
                          {
                            ref: "listHeader",
                            staticClass: "px-9",
                            attrs: { "no-gutters": "", cols: "12" },
                          },
                          [
                            _c(VCol, { attrs: { cols: "11" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$stringConstants(
                                    "operationsListColumnTitles"
                                  )["columnName"]
                                )
                              ),
                            ]),
                            _c(
                              VCol,
                              {
                                staticClass: "text-right",
                                attrs: { cols: "1" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$stringConstants(
                                      "operationsListColumnTitles"
                                    )["columnAction"]
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.noData && !_vm.operationsLoader
                      ? _c("div", { staticClass: "opr-list" }, [
                          _c(
                            "div",
                            { staticClass: "opr-list-cont px-0" },
                            [
                              _c(
                                VExpansionPanels,
                                {
                                  staticClass: "exp-otr-top pr-6",
                                  attrs: {
                                    accordion: "",
                                    hover: "",
                                    flat: "",
                                    focussable: "",
                                  },
                                },
                                _vm._l(
                                  _vm.operationTypesList,
                                  function (operationType, i) {
                                    return _c(
                                      VExpansionPanel,
                                      { key: i, staticClass: "exp-otr" },
                                      [
                                        _vm.shouldComponentDisplay(
                                          _vm.componentsPermissions[
                                            "operationUpdate"
                                          ]
                                        )
                                          ? _c(
                                              "div",
                                              { staticClass: "exp-otr-btn" },
                                              [
                                                _c("Button", {
                                                  attrs: {
                                                    variant: "list",
                                                    color: "primary",
                                                    text: "",
                                                    textValue: "Add operation",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleAddItemClick(
                                                        operationType
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          VExpansionPanelHeader,
                                          {
                                            staticClass:
                                              "pt-6 pb-6 pl-9 f-15 fw-5",
                                            attrs: {
                                              "expand-icon":
                                                operationType.operations
                                                  .length > 0
                                                  ? "mdi-menu-down"
                                                  : "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(operationType.title) +
                                                " "
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ib pl-1",
                                                style: { flex: "unset" },
                                              },
                                              [
                                                operationType.operations !=
                                                  null &&
                                                operationType.operations
                                                  .length > 0
                                                  ? _c("div", [
                                                      _c("div", [
                                                        _vm._v(
                                                          "(" +
                                                            _vm._s(
                                                              operationType
                                                                .operations
                                                                .length
                                                            ) +
                                                            ")"
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          VExpansionPanelContent,
                                          _vm._l(
                                            operationType.operations,
                                            function (operation, i) {
                                              return _c(
                                                "div",
                                                { key: i },
                                                [
                                                  _c(
                                                    VRow,
                                                    {
                                                      staticClass:
                                                        "mt-1 pt-4 pb-2 pl-9 pr-2 opr-sub-acc",
                                                    },
                                                    [
                                                      _c(
                                                        VCol,
                                                        {
                                                          staticClass:
                                                            "pt-0 pb-0 pl-0",
                                                          attrs: { cols: "9" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                operation.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        VCol,
                                                        {
                                                          staticClass:
                                                            "text-right pt-3 position-relative",
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pt-1",
                                                            },
                                                            [
                                                              _c(
                                                                VMenu,
                                                                {
                                                                  attrs: {
                                                                    bottom: "",
                                                                    "min-width":
                                                                      "120",
                                                                    left: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                VBtn,
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "triple-dot-menu",
                                                                                      attrs:
                                                                                        {
                                                                                          icon: "",
                                                                                        },
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    VIcon,
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-dots-vertical"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _c(
                                                                    VList,
                                                                    [
                                                                      _c(
                                                                        VListItem,
                                                                        {
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleEditItemClick(
                                                                                  operation
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VListItemTitle,
                                                                            [
                                                                              _vm.shouldComponentDisplay(
                                                                                _vm
                                                                                  .componentsPermissions[
                                                                                  "operationUpdate"
                                                                                ]
                                                                              )
                                                                                ? _c(
                                                                                    VIcon,
                                                                                    {
                                                                                      staticClass:
                                                                                        "icon-size pr-2",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-pencil"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                "Edit "
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        VListItem,
                                                                        {
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.promtDeleteOperation(
                                                                                  operation.id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VListItemTitle,
                                                                            [
                                                                              _vm.shouldComponentDisplay(
                                                                                _vm
                                                                                  .componentsPermissions[
                                                                                  "operationDelete"
                                                                                ]
                                                                              )
                                                                                ? _c(
                                                                                    VIcon,
                                                                                    {
                                                                                      staticClass:
                                                                                        "icon-size pr-2",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-delete"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                "Delete "
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    (_vm.operationsLoader &&
                      _vm.operationTypesList.length == 0 &&
                      _vm.columnWidths.length > 0) ||
                    _vm.isListRequestCancelled
                      ? _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("SkeletonLoader", {
                              attrs: {
                                columnWidths: _vm.columnWidths,
                                containsAction: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c(
                VRow,
                { staticClass: "px-9" },
                [
                  _c(VCol, { staticClass: "col-4 px-0" }, [
                    _vm.noData && !_vm.filtersApplied && !_vm.operationsLoader
                      ? _c("div", [
                          _c(
                            "p",
                            { staticClass: "mb-0 font-weight-bold pb-3" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants(
                                      "operationsListZeroStateTitle"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$stringConstants(
                                  "operationsListZeroStateSubtitle"
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm.noData && _vm.filtersApplied
                      ? _c("div", [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$stringConstants("errorForWrongSearch")
                              )
                            ),
                          ]),
                          _c("p"),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c("DeleteItemDialog", {
                attrs: { item: _vm.deleteDialog },
                on: {
                  cancel: _vm.cancelOperationDeletion,
                  proceed: function ($event) {
                    return _vm.proceedOperationDeletion()
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$stringConstants(
                                  "operationDeletePromtTitle"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$stringConstants(
                                  "operationDeletePromtSubtitle"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  49350146
                ),
              }),
            ],
            1
          )
        : _c("div", { staticClass: "pt-4" }, [
            _vm._v(
              " " + _vm._s(_vm.$stringConstants("operationListDisabled")) + " "
            ),
          ]),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.deleteDialog },
        on: {
          cancel: _vm.cancelSubOperationDeletion,
          proceed: function ($event) {
            return _vm.proceedSubOperationDeletion()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("subOperationManageDeletePromtTitle")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants(
                        "subOperationManageDeletePromtSubtitle"
                      )
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        VDialog,
        {
          staticStyle: { "z-index": "99999 !important" },
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.subOperationTaxWarningVisible,
            callback: function ($$v) {
              _vm.subOperationTaxWarningVisible = $$v
            },
            expression: "subOperationTaxWarningVisible",
          },
        },
        [
          _c(
            VCard,
            { staticClass: "dlt-diag px-3 pt-2 pb-3 d-flex flex-column" },
            [
              _c(
                VCardTitle,
                {
                  staticClass: "headline pt-6",
                  staticStyle: { "line-height": "30px" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants(
                          "subOperationTaxMissingWarningTitle"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(VCardText, [
                _vm.loginUserRole == _vm.UserRoleType.Manager
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants(
                              "subOperationTaxMissingWarningContentForManager"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants(
                              "subOperationTaxMissingWarningContent"
                            )
                          ) +
                          " "
                      ),
                    ]),
              ]),
              _c(
                VCardActions,
                { staticClass: "px-6 mt-auto" },
                [
                  _c(VSpacer),
                  _vm.loginUserRole == _vm.UserRoleType.Contractor ||
                  _vm.loginUserRole == _vm.UserRoleType.Farmer
                    ? _c("Button", {
                        attrs: {
                          variant: "secondary",
                          outlined: "",
                          outlineColor: "primary",
                          color: "primary",
                          textValue: "No",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.cancelTaxSetup($event)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.loginUserRole == _vm.UserRoleType.Contractor ||
                  _vm.loginUserRole == _vm.UserRoleType.Farmer
                    ? _c("Button", {
                        attrs: {
                          variant: "secondary",
                          color: "primary",
                          textValue: "Yes",
                          text: "",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.routeToUpdateTax($event)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.loginUserRole == _vm.UserRoleType.Manager
                    ? _c("Button", {
                        attrs: {
                          variant: "secondary",
                          color: "primary",
                          textValue: "Okay",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.cancelTaxSetup($event)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }