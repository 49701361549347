export var JobStatusType;
(function (JobStatusType) {
  JobStatusType[JobStatusType["New"] = 0] = "New";
  JobStatusType[JobStatusType["Scheduled"] = 1] = "Scheduled";
  JobStatusType[JobStatusType["Started"] = 2] = "Started";
  JobStatusType[JobStatusType["Paused"] = 3] = "Paused";
  JobStatusType[JobStatusType["AwaitingCompletion"] = 4] = "AwaitingCompletion";
  JobStatusType[JobStatusType["PartCompleted"] = 5] = "PartCompleted";
  JobStatusType[JobStatusType["Completed"] = 6] = "Completed";
  JobStatusType[JobStatusType["Cancelled"] = 7] = "Cancelled";
})(JobStatusType || (JobStatusType = {}));