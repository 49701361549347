import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "\n        field-list-map-container \n        ml-9 \n        " +
        (!_vm.showFieldsList
          ? "h-percent-100"
          : "default-field-list-map-height") +
        "\n        " +
        (!_vm.drawerOpen ? "mr-9" : "") +
        "\n        ",
    },
    [
      !_vm.drawerOpen
        ? _c("div", { staticClass: " position-absolute" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                {
                  staticClass: "fld-text-overlay",
                  staticStyle: { "min-width": "160px" },
                },
                [
                  _vm.fieldsListMapLoader
                    ? _c("span", [_vm._v("Loading ")])
                    : _vm._e(),
                  _vm.fieldsTotalCount > 0 || !_vm.fieldsListMapLoader
                    ? _c("span", [_vm._v(_vm._s(_vm.fieldsTotalCount))])
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.fieldsText) + " "),
                  _vm.fieldsListMapLoader
                    ? _c("span", [_vm._v("...")])
                    : _vm._e(),
                ]
              ),
              !_vm.fieldsListMapLoader
                ? _c(
                    "div",
                    {
                      staticClass: "fld-text-search-container fld-text-search",
                    },
                    [
                      _c("div", { staticClass: "border-radius-3 bg-white " }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pL-6 pR-24 mT-none mB-none md-size-100 field-search-container ac-map-box",
                          },
                          [
                            _c(
                              VIcon,
                              {
                                staticClass: "pt-3 pr-2",
                                staticStyle: {
                                  "padding-top": "6px !important",
                                },
                                attrs: { color: "black" },
                              },
                              [_vm._v("mdi-magnify")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.placesSearchQuery,
                                  expression: "placesSearchQuery",
                                },
                              ],
                              staticClass:
                                "input-search fm-light fc-grey fs-16 ac-map-input fld-text-search",
                              attrs: {
                                type: "text",
                                placeholder: "Search place",
                                id: "searchPlaces",
                                autocomplete: "off",
                              },
                              domProps: { value: _vm.placesSearchQuery },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.placesSearchQuery = $event.target.value
                                  },
                                  function ($event) {
                                    return _vm.applySearchTimer(
                                      _vm.requestPlacesSearch
                                    )
                                  },
                                ],
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.requestPlacesSearch(true)
                                },
                              },
                            }),
                            _vm.placesSearchQuery
                              ? _c(
                                  VIcon,
                                  {
                                    staticClass: "cursor-pointer pt-2",
                                    staticStyle: {
                                      right: "12px",
                                      top: "2px",
                                      position: "absolute",
                                    },
                                    on: { click: _vm.clearPlacesSearch },
                                  },
                                  [_vm._v("mdi-close")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.placesSearchResults &&
              _vm.placesSearchResults.length > 0 &&
              !_vm.fieldsListMapLoader
                ? _c(
                    "div",
                    {
                      staticClass: "hs-drp fld-text-results",
                      style: { left: _vm.drawerOpen ? "194px" : "198px" },
                    },
                    _vm._l(
                      _vm.placesSearchResults.slice(0, 4),
                      function (result, resultIndex) {
                        return _c(
                          "div",
                          { key: resultIndex, staticClass: "hs-drp-item" },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.navigateToSearchResult(
                                      resultIndex
                                    )
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(result.name) + " ")]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]),
          ])
        : _vm.showEditableFields && _vm.fieldDetails.id
        ? _c(
            VRow,
            {
              staticClass: "position-relative",
              staticStyle: { "z-index": "1" },
              attrs: { "no-gutters": "", cols: "12" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "position-absolute",
                  staticStyle: { right: "40px", margin: "10px 20px" },
                },
                [
                  !_vm.editingAreaOnMap && !_vm.fieldReadonly
                    ? _c("Button", {
                        attrs: {
                          variant: "secondary",
                          color: "pale_gray",
                          textValue: _vm.$stringConstants("listItemEdit"),
                        },
                        nativeOn: {
                          click: function ($event) {
                            _vm.editingAreaOnMap = true
                          },
                        },
                      })
                    : _vm.editingAreaOnMap
                    ? _c(
                        VRow,
                        { attrs: { cols: "12", "no-gutters": "" } },
                        [
                          _c("Button", {
                            staticClass: "mx-3",
                            attrs: {
                              variant: "secondary",
                              color: "pale_gray",
                              textValue: "Clear map",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.clearAreaOnMap($event)
                              },
                            },
                          }),
                          !(
                            _vm.drawingManager &&
                            _vm.drawingManager.getDrawingMode()
                          )
                            ? _c("Button", {
                                attrs: {
                                  variant: "secondary",
                                  color: "pale_gray",
                                  textValue: "Cancel",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    _vm.editingAreaOnMap = false
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        VRow,
        {
          staticClass:
            "position-absolute align-items-center justify-space-between",
          staticStyle: { "z-index": "1", width: "calc(100% - 260px)" },
          attrs: { cols: "12" },
        },
        [
          _vm.searchType === "Field" && _vm.drawerOpen
            ? _c("FieldsSearchReadonly", {
                staticClass: "ma-5",
                staticStyle: {
                  "pointer-events": "initial",
                  transform: "translateY(-2) !important",
                  "margin-bottom": "0 !important",
                },
              })
            : _vm._e(),
          !_vm.fieldsListMapLoader && _vm.drawerOpen
            ? _c(VSelect, {
                staticClass: "pb-2",
                staticStyle: {
                  position: "absolute",
                  left: "276px",
                  top: "21px",
                  "z-index": "10",
                  width: "100px",
                },
                attrs: {
                  id: "search-type-selection",
                  width: "200",
                  "max-width": "200",
                  items: ["Field", "Place"],
                  "item-value": "Field",
                  "item-text": "Field",
                  placeholder: "Select type",
                  dense: "",
                  attach: "",
                  outlined: "",
                },
                model: {
                  value: _vm.searchType,
                  callback: function ($$v) {
                    _vm.searchType = $$v
                  },
                  expression: "searchType",
                },
              })
            : _vm._e(),
          !_vm.fieldsListMapLoader &&
          _vm.searchType === "Place" &&
          _vm.drawerOpen
            ? _c(
                "div",
                {
                  staticClass: "fld-text-search-container fld-text-search",
                  staticStyle: { transform: "translate(14px, 9px) !important" },
                },
                [
                  _c("div", { staticClass: "border-radius-3 bg-white" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pL-6 pR-24 mT-none mB-none md-size-100 field-search-container ac-map-box",
                      },
                      [
                        _c(
                          VIcon,
                          {
                            staticClass: "pt-3 pr-2",
                            staticStyle: { "padding-top": "6px !important" },
                            attrs: { color: "black" },
                          },
                          [_vm._v("mdi-magnify")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.placesSearchQuery,
                              expression: "placesSearchQuery",
                            },
                          ],
                          staticClass:
                            "input-search fm-light fc-grey fs-16 ac-map-input fld-text-search",
                          attrs: {
                            type: "text",
                            placeholder: "Search place",
                            id: "searchPlaces",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.placesSearchQuery },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.placesSearchQuery = $event.target.value
                              },
                              function ($event) {
                                return _vm.applySearchTimer(
                                  _vm.requestPlacesSearch
                                )
                              },
                            ],
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.requestPlacesSearch(true)
                            },
                          },
                        }),
                        _vm.placesSearchQuery && !_vm.drawerOpen
                          ? _c(
                              VIcon,
                              {
                                staticClass: "cursor-pointer pt-2",
                                staticStyle: {
                                  right: "12px",
                                  top: "2px",
                                  position: "absolute",
                                },
                                on: { click: _vm.clearPlacesSearch },
                              },
                              [_vm._v("mdi-close")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.placesSearchResults &&
          _vm.placesSearchResults.length > 0 &&
          !_vm.fieldsListMapLoader &&
          _vm.drawerOpen
            ? _c(
                "div",
                { staticClass: "hs-drp fld-text-results-add" },
                _vm._l(
                  _vm.placesSearchResults.slice(0, 4),
                  function (result, resultIndex) {
                    return _c(
                      "div",
                      { key: resultIndex, staticClass: "hs-drp-item" },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.navigateToSearchResult(resultIndex)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(result.name) + " ")]
                        ),
                      ]
                    )
                  }
                ),
                0
              )
            : _vm._e(),
          _vm.drawingManager
            ? _c("Button", {
                style: { marginTop: "10px", pointerEvents: "initial" },
                attrs: {
                  variant: "secondary",
                  color: "pale_gray",
                  textValue: "Clear",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.clearAreaOnMap($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", {
        staticClass: "field-list-map",
        attrs: { id: "field-list-map" },
      }),
      _vm.fieldsListMapLoader
        ? _c(VSkeletonLoader, {
            staticClass: "jobs-fields-loader position-absolute",
            attrs: { type: "image", loading: _vm.fieldsListMapLoader },
          })
        : _vm._e(),
      _c(
        VAlert,
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.drawerOpen && _vm.mapAlertMessage,
              expression: "drawerOpen && mapAlertMessage",
            },
          ],
          staticClass: "alert-container",
          attrs: {
            dense: "",
            prominent: "",
            color: "#1d34ad",
            icon: "mdi-alert-outline",
            type: "warning",
          },
        },
        [
          _c(
            VRow,
            { attrs: { align: "center" } },
            [
              _c(VCol, { staticClass: "grow" }, [
                _vm._v(" " + _vm._s(_vm.mapAlertMessage) + " "),
              ]),
              _c(
                VCol,
                { staticClass: "shrink pa-0" },
                [
                  _c("Button", {
                    attrs: {
                      variant: "tertiary",
                      outlined: "",
                      color: "white",
                      textValue: "Select owner",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.focusOnOwnerSelection($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }