import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container", attrs: { id: "invoice-list-container" } },
    [
      _c(
        VRow,
        {
          staticClass: "list-filters flex-grow-0 px-9 pt-4",
          attrs: { "no-gutters": "", cols: "12" },
        },
        [
          _c(
            VCol,
            { attrs: { cols: "9", xl: "10" } },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    {
                      staticClass: "pl-0 pb-0 pt-1",
                      staticStyle: { "z-index": "2" },
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ib" },
                        [
                          _c(VTextField, {
                            staticClass: "pt-2 agd-txtfield mr-1",
                            staticStyle: { width: "300px" },
                            attrs: {
                              label: "Search invoices",
                              outlined: "",
                              "prepend-inner-icon": "mdi-magnify",
                              clearable: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.applySearchTimer(
                                  _vm.applySearchInvoicesFilter
                                )
                              },
                              "click:clear": _vm.clearAndResetSearchFilters,
                            },
                            model: {
                              value: _vm.invoiceSearchText,
                              callback: function ($$v) {
                                _vm.invoiceSearchText = $$v
                              },
                              expression: "invoiceSearchText",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "ib" },
                        [
                          _c(VAutocomplete, {
                            staticStyle: { transform: "translateY(-4px)" },
                            attrs: {
                              label: "Select customer",
                              items: _vm.invoicesListFilterCustomersList,
                              "search-input":
                                _vm.invoicesListFilterCustomerSearchText,
                              loading:
                                _vm.invoicesListFilterCustomersDropdownLoader,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              "hide-no-data": "",
                              clearable: "",
                              dense: "",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.invoicesListFilterCustomerSearchText =
                                  $event
                              },
                              "update:search-input": function ($event) {
                                _vm.invoicesListFilterCustomerSearchText =
                                  $event
                              },
                              "click:clear":
                                _vm.applyInvoicesListCustomersFilterSearchTimer,
                              focus:
                                _vm.applyInvoicesListCustomersFilterSearchTimer,
                              change: function ($event) {
                                return _vm.getInvoicesByCustomer()
                              },
                            },
                            nativeOn: {
                              input: function ($event) {
                                return _vm.applyInvoicesListCustomersFilterSearchTimer(
                                  $event
                                )
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append-item",
                                fn: function () {
                                  return [
                                    _c("LoadMoreItem", {
                                      attrs: {
                                        searchText:
                                          _vm.invoicesListFilterCustomerSearchText,
                                        loadedListLength:
                                          _vm.invoicesListFilterCustomersList
                                            .length,
                                        moduleNamespace: _vm.$stringConstants(
                                          "invoicesListFilterCustomersPaginationModule"
                                        ),
                                      },
                                      on: {
                                        loadMoreItems: _vm.appendMoreCustomers,
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.invoicesListFilterCustomerValue,
                              callback: function ($$v) {
                                _vm.invoicesListFilterCustomerValue = $$v
                              },
                              expression: "invoicesListFilterCustomerValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "ib",
                          staticStyle: {
                            width: "160px",
                            transform: "translateY(1px)",
                          },
                        },
                        [
                          _c(VSelect, {
                            staticClass: "jb-sts-pos agd-dropdown",
                            attrs: {
                              items: _vm.invoiceFilterStatus,
                              label: "Invoice status",
                              "item-value": "value",
                              "item-text": "title",
                              outlined: "",
                              dense: "",
                            },
                            on: { change: _vm.filterInvoicesByStatus },
                            model: {
                              value: _vm.invoiceFilterStatusSelected,
                              callback: function ($$v) {
                                _vm.invoiceFilterStatusSelected = $$v
                              },
                              expression: "invoiceFilterStatusSelected",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "ib pl-1",
                          staticStyle: { width: "160px" },
                        },
                        [
                          _c(VSelect, {
                            staticClass: "jb-sts-pos agd-dropdown",
                            attrs: {
                              items: _vm.invoiceFilterEmailStatusOptions,
                              label: _vm.$stringConstants("emailStatus"),
                              outlined: "",
                              dense: "",
                            },
                            on: { change: _vm.filterInvoicesByEmailStatus },
                            model: {
                              value: _vm.invoiceFilterEmailStatusSelected,
                              callback: function ($$v) {
                                _vm.invoiceFilterEmailStatusSelected = $$v
                              },
                              expression: "invoiceFilterEmailStatusSelected",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "d-inline-flex pl-2 align-center",
                          staticStyle: { transform: "translateY(-5px)" },
                        },
                        [
                          _c("datePicker", {
                            ref: "datePicker",
                            class: !!_vm.datesAppliedOnFilter.length
                              ? "active"
                              : "",
                            attrs: {
                              dateInput: _vm.dateInput,
                              format: _vm.format,
                              sameDateFormat: _vm.sameDateFormat,
                              "switch-button-initial": true,
                              "show-helper-buttons": false,
                              language: "en",
                            },
                            on: {
                              "date-applied": _vm.filterInvoicesByDateRange,
                            },
                          }),
                          Boolean(_vm.datesAppliedOnFilter.length)
                            ? _c(
                                "div",
                                {
                                  staticClass: "jb-clr",
                                  staticStyle: {
                                    transform: "translate(20px, -4px)",
                                  },
                                  on: { click: _vm.clearInvoicesDateFilter },
                                },
                                [
                                  _c(VIcon, { attrs: { small: "" } }, [
                                    _vm._v(" mdi-close-thick "),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "3", xl: "2" } },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "pt-2 px-0", attrs: { cols: "12" } },
                    [
                      (_vm.xeroAccess[_vm.xeroAccessState] ==
                        _vm.$constantValues("xeroAccessStruct")[
                          "noXeroAccess"
                        ] &&
                        _vm.quickBookAccess[_vm.quickbookAccessState] ==
                          _vm.$constantValues("quickbookAccessStruct")[
                            "noQuickbookAccess"
                          ] &&
                        _vm.sageAccess[_vm.sageAccessState] ==
                          _vm.$constantValues("sageAccessStruct")[
                            "noSageAccess"
                          ]) ||
                      _vm.loadingIntegration
                        ? _c("Button", {
                            staticClass: "float-right",
                            attrs: {
                              variant: "secondary",
                              color: "primary",
                              outlined: "",
                              outlineColor: "primary",
                              loading: _vm.loadingIntegration,
                              disabled: _vm.loadingIntegration,
                              textValue: "Add",
                              leftIcon: "mdi-plus",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openAddInvoice()
                              },
                            },
                          })
                        : _vm._e(),
                      _c("Button", {
                        staticClass: "float-right mr-2",
                        attrs: {
                          variant: "secondary",
                          outlined: "",
                          outlineColor: "gray",
                          disabled: _vm.invoicesList.length === 0,
                          textValue: _vm.$stringConstants("downloadText"),
                          leftIcon: "mdi-arrow-down",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.initiateInvoicesExport($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("List", {
        attrs: {
          listItems: _vm.invoicesList,
          containsAction: "",
          moduleNamespace: "invoicesModule",
          preserveListState: "",
        },
        on: {
          setFilterOptionsAndGetListData: _vm.setFilterOptionsAndGetInvoices,
          listItemClick: _vm.openInvoiceDetails,
        },
        scopedSlots: _vm._u([
          {
            key: "list-heading",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "ib width-perc-12 pr-4 item-no-res" },
                  [_vm._v(_vm._s(_vm.$stringConstants("numberText")))]
                ),
                _c("div", { staticClass: "ib width-perc-9 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("dateText"))),
                ]),
                _c("div", { staticClass: "ib width-perc-14 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("customer"))),
                ]),
                _c("div", { staticClass: "ib width-perc-15 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("itemsText"))),
                ]),
                _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("jobsText"))),
                ]),
                _c("div", { staticClass: "ib width-perc-8 pr-4 item-no-res" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("costText"))),
                ]),
                _c("div", { staticClass: "ib width-perc-9 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("dueDateText"))),
                ]),
                _c("div", { staticClass: "ib width-perc-9 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("status"))),
                ]),
                _c("div", { staticClass: "ib width-perc-9 pr-4" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("emailStatus"))),
                ]),
                _c("div", { staticClass: "ib width-perc-5 text-right" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("action"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "list-item",
            fn: function (ref) {
              var listItem = ref.listItem
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "ib fw-7 cursor-pointer width-perc-12 pr-4 item-no-res",
                    on: {
                      click: function ($event) {
                        return _vm.openInvoiceDetails(listItem)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(listItem.number) + " ")]
                ),
                _c("div", { staticClass: "ib width-perc-9 pr-4" }, [
                  _vm._v(" " + _vm._s(_vm.getLocalFormat(listItem.date)) + " "),
                ]),
                _c("div", { staticClass: "ib width-perc-14 pr-4" }, [
                  _vm._v(" " + _vm._s(listItem.customer.name) + " "),
                ]),
                _c("div", { staticClass: "ib width-perc-15" }, [
                  listItem.lineItems.length == 1
                    ? _c("div", { staticClass: "pr-6" }, [
                        _vm._v(" " + _vm._s(listItem.lineItems[0].title) + " "),
                      ])
                    : listItem.lineItems.length > 1
                    ? _c("div", { staticClass: "pr-8" }, [
                        _vm._v(
                          " " +
                            _vm._s(listItem.lineItems[0].title) +
                            " + " +
                            _vm._s(listItem.lineItems.length - 1) +
                            " others "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "ib width-perc-10 pr-4" }, [
                  _c("div", { staticClass: "pr-6" }, [
                    listItem.jobIds.length >= 1
                      ? _c(
                          "div",
                          {
                            staticClass: "cursor-pointer inv-jb-count-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openJobsPreview(listItem.jobIds)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(listItem.jobIds.length) +
                                " job" +
                                _vm._s(listItem.jobIds.length > 1 ? "s" : "") +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "ib width-perc-8 pr-4 item-no-res" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.currencySymbol) +
                      _vm._s(_vm.getInvoiceCost(listItem.total)) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "ib width-perc-9 pr-4" }, [
                  _vm._v(
                    " " + _vm._s(_vm.getLocalFormat(listItem.dueDate)) + " "
                  ),
                ]),
                _c("div", { staticClass: "ib width-perc-9 pr-4" }, [
                  listItem.status === _vm.invoiceStatusEnum.draft
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "inv-status inv-status-draft d-flex align-center",
                        },
                        [
                          _c("img", {
                            staticStyle: { "margin-right": "8px" },
                            attrs: {
                              src: require("@/assets/icons-status-draft.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticClass: "d-inline-block" }, [
                            _vm._v(
                              _vm._s(_vm.invoiceStatusTextEnum[listItem.status])
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  listItem.status === _vm.invoiceStatusEnum.open
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "inv-status inv-status-open d-flex align-center",
                        },
                        [
                          _c("img", {
                            staticStyle: { "margin-right": "8px" },
                            attrs: {
                              src: require("@/assets/icons-status-open.png"),
                              srcset:
                                require("@/assets/icons-status-open@2x.png") +
                                " 2x, " +
                                require("@/assets/icons-status-open@3x.png") +
                                " 3x",
                              alt: "",
                            },
                          }),
                          _c("span", { staticClass: "d-inline-block" }, [
                            _vm._v(
                              _vm._s(_vm.invoiceStatusTextEnum[listItem.status])
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  listItem.status === _vm.invoiceStatusEnum.paid
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "inv-status inv-status-paid d-flex align-center",
                        },
                        [
                          _c("img", {
                            staticStyle: { "margin-right": "8px" },
                            attrs: {
                              src: require("@/assets/icons-status-completed-2.png"),
                              srcset:
                                require("@/assets/icons-status-completed-2@2x.png") +
                                " 2x, " +
                                require("@/assets/icons-status-completed-2@3x.png") +
                                " 3x",
                              alt: "",
                            },
                          }),
                          _c("span", { staticClass: "d-inline-block" }, [
                            _vm._v(
                              _vm._s(_vm.invoiceStatusTextEnum[listItem.status])
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "ib width-perc-9 pr-4" }, [
                  !listItem.emailStatus
                    ? _c(
                        "div",
                        { staticClass: "inv-status d-flex align-center" },
                        [
                          _c("span", { staticClass: "d-inline-block" }, [
                            _vm._v("-"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  listItem.emailStatus === _vm.invoiceFilterEmailStatus.Pending
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "inv-status inv-status-draft d-flex align-center",
                        },
                        [
                          _c("span", { staticClass: "d-inline-block" }, [
                            _vm._v(
                              _vm._s(
                                _vm.invoiceFilterEmailStatus[
                                  listItem.emailStatus
                                ]
                              )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  listItem.emailStatus === _vm.invoiceFilterEmailStatus.Sent
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "inv-status inv-status-paid d-flex align-center",
                        },
                        [
                          _c("span", { staticClass: "d-inline-block" }, [
                            _vm._v(
                              _vm._s(
                                _vm.invoiceFilterEmailStatus[
                                  listItem.emailStatus
                                ]
                              )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "ib position-relative text-right width-perc-5",
                  },
                  [
                    _c(
                      VMenu,
                      {
                        attrs: { bottom: "", "min-width": "120", left: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "triple-dot-menu",
                                          attrs: { icon: "" },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-dots-vertical"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          VList,
                          [
                            _c(
                              VListItem,
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openInvoiceDetails(listItem)
                                  },
                                },
                              },
                              [
                                _c(
                                  VListItemTitle,
                                  [
                                    _c(
                                      VIcon,
                                      { staticClass: "icon-size pr-2" },
                                      [_vm._v("mdi-eye")]
                                    ),
                                    _vm._v("View "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              VListItem,
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.promtDeleteInvoice(listItem.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  VListItemTitle,
                                  [
                                    _c(
                                      VIcon,
                                      { staticClass: "icon-size pr-2" },
                                      [_vm._v("mdi-delete")]
                                    ),
                                    _vm._v("Delete "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "zero-state",
            fn: function () {
              return [
                _c("p", { staticClass: "mb-0 font-weight-bold pb-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants("invoiceZeroStateTitleText")
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$stringConstants("invoiceZeroStateSubtitleText"))
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.deleteDialog, "max-width": "460" },
        on: {
          cancel: _vm.cancelInvoiceDeletion,
          proceed: _vm.proceedInvoiceDeletion,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("invoiceDeleteTitle")) + " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("invoiceDeleteSubTitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "460" },
          model: {
            value: _vm.showExportDialog,
            callback: function ($$v) {
              _vm.showExportDialog = $$v
            },
            expression: "showExportDialog",
          },
        },
        [
          _c(
            VCard,
            { staticClass: "dlt-diag py-5" },
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("teamJobLoaderDialogTitle")) +
                    " "
                ),
              ]),
              _c(
                VCardText,
                [
                  _c(VProgressCircular, {
                    staticStyle: {
                      transform: "translateY(-2px) translateX(-2px)",
                    },
                    attrs: {
                      indeterminate: "",
                      width: "2",
                      size: "18",
                      color: "#b3b3b3",
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants("teamJobLoaderDialogContent")
                      ) +
                      " (" +
                      _vm._s(_vm.exportDataLoaded) +
                      " / " +
                      _vm._s(_vm.exportDataTotal) +
                      ") "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }