import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "seed-data-container pa-6", attrs: { elevation: "2" } },
    [
      _c(
        VCardTitle,
        { staticClass: "d-flex align-center justify-space-between pb-4" },
        [
          _c(
            "span",
            { staticClass: "text-h5 font-weight-bold" },
            [
              _c(VIcon, { attrs: { left: "", color: "primary" } }, [
                _vm._v("mdi-database-plus"),
              ]),
              _vm._v(" Seed Data Generator "),
            ],
            1
          ),
          _c(
            VTooltip,
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        VIcon,
                        _vm._g(
                          _vm._b(
                            { attrs: { color: "grey" } },
                            "v-icon",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v(" mdi-help-circle-outline ")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v("Configure and generate seed data for your application"),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(VDivider, { staticClass: "mb-4" }),
      _c(
        VForm,
        {
          ref: "seedForm",
          model: {
            value: _vm.isFormValid,
            callback: function ($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid",
          },
        },
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "pl-0 pr-2", attrs: { cols: "12", md: "4" } },
                [
                  _c(VTextField, {
                    attrs: {
                      type: "number",
                      label: "Seed Size",
                      placeholder: "Enter seed size",
                      "prepend-inner-icon": "mdi-numeric",
                      rules: [
                        function (v) {
                          return v > 0 || "Seed size must be positive"
                        },
                      ],
                      disabled: _vm.isLoading,
                      outlined: "",
                      dense: "",
                      required: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c(
                              VTooltip,
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          VIcon,
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { color: "grey" } },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" mdi-help-circle ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("span", [
                                  _vm._v("Number of records to generate"),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.seedSize,
                      callback: function ($$v) {
                        _vm.seedSize = _vm._n($$v)
                      },
                      expression: "seedSize",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "pr-2", attrs: { cols: "12", md: "4" } },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "Prefix ID",
                      placeholder: "Enter prefix ID",
                      "prepend-inner-icon": "mdi-tag-text",
                      rules: [
                        function (v) {
                          return !!v || "Prefix ID is required"
                        },
                      ],
                      disabled: _vm.isLoading,
                      outlined: "",
                      dense: "",
                      required: "",
                    },
                    model: {
                      value: _vm.prefixId,
                      callback: function ($$v) {
                        _vm.prefixId = $$v
                      },
                      expression: "prefixId",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "pr-2", attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: {
                        disabled: _vm.isDisabled || _vm.isLoading,
                        color: "primary",
                        outlined: "",
                      },
                      on: { click: _vm.generatePrefixId },
                    },
                    [_vm._v(" Generate Prefix ID ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VExpandTransition,
        [
          _vm.isLoading
            ? _c(
                VAlert,
                { staticClass: "mt-4", attrs: { type: "info", outlined: "" } },
                [
                  _c(
                    VRow,
                    { attrs: { align: "center" } },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12" } },
                        [
                          _c(VProgressLinear, {
                            attrs: {
                              value: _vm.progressPercentage,
                              color: "primary",
                            },
                          }),
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v(" " + _vm._s(_vm.loadingMessage) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "seed-actions mt-4" },
        _vm._l(_vm.seedActions, function (action, index) {
          return _c("seed-action-button", {
            key: index,
            attrs: {
              "text-value": "" + action.label,
              "is-disabled": !_vm.isFormValid || _vm.isLoading,
            },
            on: { click: action.method },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }