import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldComponentDisplay(_vm.componentsPermissions["vehicleRead"]) ||
    _vm.shouldComponentDisplay(_vm.componentsPermissions["implementRead"])
    ? _c(
        "div",
        { staticClass: "list-container" },
        [
          _c("snackbarDelete", {
            attrs: {
              deleteApiType: "vehicles",
              status: _vm.machinesFilterType,
              allItemsSelected: _vm.allMachinesSelected,
              someItemsSelected: _vm.someMachinesSelected,
              items: _vm.machineListFiltered,
            },
          }),
          _c("ActionListingDialog", {
            attrs: {
              itemName:
                _vm.machineType == _vm.MachineTypeInfo.Vehicle
                  ? "vehicles"
                  : "Implement",
              actionType: _vm.machineType,
              itemsPreference: this.machinesFilterType,
              onClickEvent: "proccedToActionOnMachines",
            },
          }),
          _c("ItemDialog", {
            attrs: { item: _vm.deleteDialog },
            on: {
              cancel: function ($event) {
                return _vm.cancelActionOnMachine(_vm.actionType)
              },
              proceed: function ($event) {
                return _vm.proceedActionOnMachine(_vm.actionType)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.fnGetHeaderString(_vm.actionType)) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.fnGetBodyString(_vm.actionType)) + " "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3378694121
            ),
          }),
          _c(
            VRow,
            {
              staticClass: "px-9 pt-4 flex-grow-0",
              attrs: { "no-gutters": "", cols: "12" },
            },
            [
              _c(
                VCol,
                { attrs: { cols: "6" } },
                [
                  _c(
                    VRow,
                    { staticStyle: { "max-width": "500px" } },
                    [
                      _c(
                        VCol,
                        { staticClass: "pl-0 pr-0", attrs: { cols: "9" } },
                        [
                          _c(VTextField, {
                            staticClass: "rd-rt-no agd-txtfield mr-4",
                            attrs: {
                              placeholder:
                                _vm.machineType == _vm.MachineTypeInfo.Vehicle
                                  ? _vm.$stringConstants(
                                      "selectVehiclePlaceholder"
                                    )
                                  : _vm.$stringConstants(
                                      "selectImplementPlaceholder"
                                    ),
                              outlined: "",
                              clearable: "",
                              "prepend-inner-icon": "mdi-magnify",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.applySearchTimer(
                                  _vm.applySearchMachineFilter
                                )
                              },
                            },
                            model: {
                              value: _vm.machineSearchText,
                              callback: function ($$v) {
                                _vm.machineSearchText = $$v
                              },
                              expression: "machineSearchText",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { staticClass: "px-0", attrs: { cols: "3" } },
                        [
                          _c(VSelect, {
                            staticClass: "rd-lf-no agd-dropdown",
                            attrs: {
                              items: _vm.enumActiveStatus,
                              "item-value": "value",
                              "item-text": "title",
                              placeholder:
                                _vm.$stringConstants("statusPlaceholder"),
                              dense: "",
                              outlined: "",
                              "append-icon": "mdi-chevron-down",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getMachineListByStatus()
                              },
                            },
                            model: {
                              value: _vm.machinesFilterType,
                              callback: function ($$v) {
                                _vm.machinesFilterType = $$v
                              },
                              expression: "machinesFilterType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.shouldComponentDisplay(
                _vm.componentsPermissions["vehicleCreate"]
              )
                ? _c(
                    VCol,
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        VRow,
                        { staticClass: "text-right" },
                        [
                          _c(
                            VCol,
                            { staticClass: "px-0", attrs: { cols: "12" } },
                            [
                              _c("Button", {
                                staticClass: "mx-3",
                                attrs: {
                                  variant: "secondary",
                                  outlined: "",
                                  outlineColor: "gray",
                                  textValue: _vm.$stringConstants("importText"),
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.openImportForm()
                                  },
                                },
                              }),
                              _c("Button", {
                                attrs: {
                                  textValue: _vm.$stringConstants("addNewText"),
                                  color: "primary",
                                  variant: "secondary",
                                  outlined: "",
                                  outlineColor: "primary",
                                  leftIcon: "mdi-plus",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.openAddMachineForm()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.machineType != _vm.MachineTypeInfo.None
            ? _c("List", {
                attrs: {
                  listItems: _vm.machineListFiltered,
                  containsAction: "",
                },
                on: {
                  setFilterOptionsAndGetListData:
                    _vm.setFilterOptionsAndGetMachines,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "list-heading",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "fw-6 list-check width-perc-25" },
                            [
                              _c(
                                "div",
                                { staticClass: "ib" },
                                [
                                  _c(VCheckbox, {
                                    attrs: {
                                      indeterminate: _vm.someMachinesSelected,
                                      "input-value": _vm.allMachinesSelected,
                                    },
                                    on: { change: _vm.selectAllMachines },
                                    model: {
                                      value: _vm.isChecked,
                                      callback: function ($$v) {
                                        _vm.isChecked = $$v
                                      },
                                      expression: "isChecked",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.machineType == _vm.MachineTypeInfo.Vehicle
                                ? _c("div", { staticClass: "ib v-a-t" }, [
                                    _vm._v(
                                      _vm._s(_vm.$stringConstants("vehicle"))
                                    ),
                                  ])
                                : _vm.machineType ==
                                  _vm.MachineTypeInfo.Implement
                                ? _c("div", { staticClass: "ib v-a-t" }, [
                                    _vm._v(
                                      _vm._s(_vm.$stringConstants("implement"))
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm.machineType == _vm.MachineTypeInfo.Vehicle
                            ? _c("div", { staticClass: "width-perc-16" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$stringConstants("registrationText")
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class:
                                _vm.machineType == _vm.MachineTypeInfo.Vehicle
                                  ? "width-perc-16"
                                  : "width-perc-20",
                            },
                            [_vm._v(_vm._s(_vm.$stringConstants("brandText")))]
                          ),
                          _c(
                            "div",
                            {
                              class:
                                _vm.machineType == _vm.MachineTypeInfo.Vehicle
                                  ? "width-perc-16"
                                  : "width-perc-20",
                            },
                            [_vm._v(_vm._s(_vm.$stringConstants("modelText")))]
                          ),
                          _c(
                            "div",
                            {
                              class:
                                _vm.machineType == _vm.MachineTypeInfo.Vehicle
                                  ? "width-perc-16"
                                  : "width-perc-20",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$stringConstants("ownershipText"))
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text-right",
                              class:
                                _vm.machineType == _vm.MachineTypeInfo.Vehicle
                                  ? "width-perc-9"
                                  : "width-perc-13",
                            },
                            [_vm._v(_vm._s(_vm.$stringConstants("action")))]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "list-item",
                      fn: function (ref) {
                        var listItem = ref.listItem
                        return [
                          _c(
                            "div",
                            { staticClass: "width-perc-25 list-check" },
                            [
                              _c(
                                "div",
                                { staticClass: "ib" },
                                [
                                  _c(VCheckbox, {
                                    attrs: { value: listItem.id, multiple: "" },
                                    model: {
                                      value: _vm.selectedItems,
                                      callback: function ($$v) {
                                        _vm.selectedItems = $$v
                                      },
                                      expression: "selectedItems",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "ib v-a-t" }, [
                                _vm._v(_vm._s(listItem.title)),
                              ]),
                            ]
                          ),
                          _vm.machineType == _vm.MachineTypeInfo.Vehicle
                            ? _c("div", { staticClass: "width-perc-16" }, [
                                _c("div", { staticClass: "f-br-wd" }, [
                                  _vm._v(
                                    " " + _vm._s(listItem.registration) + " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class:
                                _vm.machineType == _vm.MachineTypeInfo.Vehicle
                                  ? "width-perc-16"
                                  : "width-perc-20",
                            },
                            [
                              _c("div", { staticClass: "f-br-wd" }, [
                                _vm._v(" " + _vm._s(listItem.make) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              class:
                                _vm.machineType == _vm.MachineTypeInfo.Vehicle
                                  ? "width-perc-16"
                                  : "width-perc-20",
                            },
                            [
                              _c("div", { staticClass: "f-br-wd" }, [
                                _vm._v(" " + _vm._s(listItem.model) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              class:
                                _vm.machineType == _vm.MachineTypeInfo.Vehicle
                                  ? "width-perc-16"
                                  : "width-perc-20",
                            },
                            [
                              listItem.ownership != null
                                ? _c("div", { staticClass: "f-br-wd" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getMachineOwnership(
                                            listItem.ownership
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("div", { staticClass: "f-br-wd" }, [
                                    _vm._v("-"),
                                  ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text-right position-relative",
                              class:
                                _vm.machineType == _vm.MachineTypeInfo.Vehicle
                                  ? "width-perc-9"
                                  : "width-perc-13",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pt-1" },
                                [
                                  _c(
                                    VMenu,
                                    {
                                      attrs: {
                                        bottom: "",
                                        "min-width": "120",
                                        left: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "triple-dot-menu",
                                                        attrs: { icon: "" },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(VIcon, [
                                                      _vm._v(
                                                        "mdi-dots-vertical"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        VList,
                                        [
                                          _vm.machinesFilterType ==
                                          _vm.ViewPreferenceType.NotArchived
                                            ? _c(
                                                VListItem,
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openEditMachineForm(
                                                        listItem.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VListItemTitle,
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticClass:
                                                            "icon-size pr-2",
                                                        },
                                                        [_vm._v("mdi-pencil")]
                                                      ),
                                                      _vm._v("Edit "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.machinesFilterType ==
                                          _vm.ViewPreferenceType.NotArchived
                                            ? _c(
                                                VListItem,
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.promtActionOnMachine(
                                                        listItem.id,
                                                        _vm.listAction.Archive
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VListItemTitle,
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticClass:
                                                            "icon-size pr-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-archive-arrow-down"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v("Archive "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.machinesFilterType ==
                                          _vm.ViewPreferenceType.Archived
                                            ? _c(
                                                VListItem,
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.promtActionOnMachine(
                                                        listItem.id,
                                                        _vm.listAction.Restore
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VListItemTitle,
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticClass:
                                                            "icon-size pr-2",
                                                        },
                                                        [_vm._v("mdi-restore")]
                                                      ),
                                                      _vm._v("Restore "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.promtActionOnMachine(
                                                    listItem.id,
                                                    _vm.listAction.Delete
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [_vm._v("mdi-delete")]
                                                  ),
                                                  _vm._v("Delete "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "zero-state",
                      fn: function () {
                        return [
                          _vm.machineType == _vm.MachineTypeInfo.Vehicle
                            ? _c("div", [
                                _vm.machinesFilterType ==
                                _vm.TabFilterType.Inactive
                                  ? _c("p", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "vehicleZeroStateInactive"
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm.machinesFilterType !=
                                    _vm.TabFilterType.Inactive
                                  ? _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-0 font-weight-bold pb-3",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "vehiclesListZeroStateTitle"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$stringConstants(
                                              "vehiclesListZeroStateSubtitle"
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm.machineType == _vm.MachineTypeInfo.Implement
                            ? _c("div", [
                                _vm.machinesFilterType ==
                                _vm.TabFilterType.Inactive
                                  ? _c("p", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "implementZeroStateInactive"
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.machinesFilterType !=
                                _vm.TabFilterType.Inactive
                                  ? _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-0 font-weight-bold pb-3",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$stringConstants(
                                                  "implementsListZeroStateTitle"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$stringConstants(
                                              "implementsListZeroStateSubtitle"
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  556975274
                ),
              })
            : _vm._e(),
        ],
        1
      )
    : _c("div", { staticClass: "pt-4" }, [
        _vm.machineType == _vm.MachineTypeInfo.Vehicle
          ? _c("div", [
              _vm._v(
                " " + _vm._s(_vm.$stringConstants("vehicleListDisabled")) + " "
              ),
            ])
          : _vm._e(),
        _vm.machineType == _vm.MachineTypeInfo.Implement
          ? _c("div", [
              _vm._v(
                " " +
                  _vm._s(_vm.$stringConstants("implementListDisabled")) +
                  " "
              ),
            ])
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }