import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  data: function data() {
    return {
      columnWidths: []
    };
  },
  methods: {
    setColumnWidths: function setColumnWidths(columnWidths) {
      this.columnWidths = columnWidths;
    },
    calculateColumnWidths: function calculateColumnWidths(listHeader) {
      var columns = [];
      this.columnWidths = this.recursivelyFindColumns(listHeader, columns);
    },
    recursivelyFindColumns: function recursivelyFindColumns(nodeList, columns) {
      var _this = this;
      var _a;
      if ((nodeList === null || nodeList === void 0 ? void 0 : nodeList.childElementCount) > 0 && ((_a = nodeList === null || nodeList === void 0 ? void 0 : nodeList.childNodes) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        nodeList.childNodes.forEach(function (childNode) {
          _this.recursivelyFindColumns(childNode, columns);
        });
      } else {
        var targetNode = nodeList === null || nodeList === void 0 ? void 0 : nodeList.childNodes[0];
        if ((targetNode === null || targetNode === void 0 ? void 0 : targetNode.nodeName) == "#text") {
          var paddingLeftOnTargetNode = document.defaultView.getComputedStyle(nodeList).paddingLeft.replace('px', '');
          var distanceOfTargetNodeFromLeftWindow = nodeList.offsetLeft + parseInt(paddingLeftOnTargetNode);
          var widthOfTargetNodeWithoutPadding = nodeList.offsetWidth - parseInt(paddingLeftOnTargetNode);
          columns.push({
            'left': distanceOfTargetNodeFromLeftWindow + 'px',
            'width': widthOfTargetNodeWithoutPadding + 'px'
          });
        }
      }
      return columns;
    }
  }
};