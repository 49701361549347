import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import LoginRegistrationWrapper from "@/components/layouts/login-registration-wrapper.vue";
import loginComputedMixin from "@/components/login/login-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { ExternalIntegrationType } from "@/enum/externalIntegrationType";
import scriptsLoader from '@/mixins/scripts-loader';
import ConstantValues from '@/plugins/constantValues';
import StringConstants from "@/plugins/stringConstants";
import router from "@/router/index";
import { appVersion } from "@/utils/constants";
import { routeForgetPassword, routeSignUp } from "@/utils/endpoints";
import { notify } from "@/utils/helpers";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin, loginComputedMixin, scriptsLoader],
  validations: {
    userName: {
      required: required,
      email: email
    },
    passWord: {
      required: required
    }
  },
  components: {
    Button: Button,
    LoginRegistrationWrapper: LoginRegistrationWrapper
  },
  data: function data() {
    return {
      userName: "",
      passWord: "",
      routeSignUp: routeSignUp,
      routeForgetPassword: routeForgetPassword,
      regularSubmit: true,
      verificationSubmit: false,
      value: String,
      appVersion: appVersion
    };
  },
  watch: {
    userName: function userName(newVal) {
      this.userName = this.userName.toLowerCase();
    }
  },
  computed: {
    loginDataLoading: function loginDataLoading() {
      return this.loginLoader || this.usersLoader || this.subscriptionPlanLoader;
    },
    userNameErrors: function userNameErrors() {
      var errors = [];
      if (!this.$v.userName.$dirty) return errors;
      !this.$v.userName.required && errors.push("Email is required.");
      !this.$v.userName.email && errors.push("Please enter the correct email address");
      return errors;
    },
    passWordErrors: function passWordErrors() {
      var errors = [];
      if (!this.$v.passWord.$dirty) return errors;
      !this.$v.passWord.required && errors.push("Password is required.");
      return errors;
    }
  },
  mounted: function mounted() {
    this.loadScripts();
    var urlParams = new URLSearchParams(window.location.search);
    var invitationCode = urlParams.get("code");
    var invitationType = urlParams.get("type");
    var invitationEmail = urlParams.get("email");
    if (invitationCode != null && invitationType != null) {
      switch (invitationType) {
        case this.invitationType["invitation"]:
          this.regularSubmit = false;
          this.verificationSubmit = true;
          this.userInviteeAcceptanceCode = invitationCode;
          break;
        case this.invitationType["verification"]:
          this.regularSubmit = true;
          this.verificationSubmit = false;
          if (invitationEmail != null) {
            this.userName = decodeURIComponent(invitationEmail);
          }
          this.$store.dispatch("verifyEmailInvite", [invitationCode, invitationEmail]);
          break;
      }
    }
    var email = urlParams.get("email");
    if (email) {
      this.userName = email;
      notify(StringConstants.userWelcomeBackText, 'success');
    }
  },
  methods: {
    submit: function submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.proceedLogin();
    },
    submitVerification: function submitVerification() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.proceedLoginVerify();
    },
    proceedLogin: function proceedLogin() {
      var formData = new FormData();
      formData.append("username", this.userName);
      formData.append("password", this.passWord);
      formData.append("clientType", ConstantValues.clientType);
      this.$store.dispatch("login", formData);
    },
    signUpXero: function signUpXero() {
      this.$store.dispatch("initializeLoginWithExternalInvoicingService", ExternalIntegrationType.Xero);
    },
    proceedLoginVerify: function proceedLoginVerify() {
      var _this = this;
      var formData = new FormData();
      formData.append("username", this.userName);
      formData.append("password", this.passWord);
      formData.append("clientType", ConstantValues.clientType);
      this.$store.dispatch("loginAndAcceptInvite", formData).then(function () {
        router.push("/users/invitee/profile/" + _this.userInviteeAcceptanceCode);
      });
    }
  }
};