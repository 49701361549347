import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'integrationsModule': ['loadingIntegration', 'xeroAccessState', 'sageAccessState', 'sageAccess', 'xeroAccess', 'quickbookAccessState', 'quickBookAccess', 'integrationType'],
  'invoicesModule': ['selectedInvoiceTabTextExternal', 'invoiceTabTexts', 'invoicesList', 'invoiceSearchText', 'noListData', 'invoiceLoader', 'internalInvoiceLoader', 'invoiceSelectedTab', 'invoiceRefs', 'invoiceFilterStatus', 'invoiceFilterStatusSelected', 'invoiceFilterEmailStatusSelected', 'invoiceJobsPreviewLoader', 'invoiceJobsForPreview', 'invoiceDraftPreviewDetails', 'canOpenInvoice', 'draftedInvoiceIdToOpen', 'isInvoiceOpened', 'invoiceDueDate', 'invoiceDate', 'invoiceLineItemJobNumbersDistinct', 'invoiceLineItemsJobIds', 'supplierBusinessProfileForInvoice', 'supplierNameForInvoice', 'supplierEmailForInvoice', 'customersListForInvoice', 'customersSearchText', 'selectedCustomerIdForInvoice', 'selectedJobsForInvoice', 'jobsListForInvoice', 'jobsForInvoiceSearchText', 'supplierPhoneNumberForInvoice', 'customerNameForInvoice', 'customerEmailForInvoice', 'customerPhoneNumberForInvoice', 'customerBusinessProfileForInvoice', 'dueDateForInvoice', 'supplierPaymentInstructionsForInvoice', 'invoiceSend', 'invoiceShouldSendLater', 'customersListForInvoiceLoader', 'customersListForInvoiceSize', 'invoicesListCount', 'invoicesListFilterCustomerValue', 'invoicesListFilterCustomersList', 'invoicesListFilterCustomerSearchText', 'invoicesListFilterCustomersDropdownLoader', 'invoicesListFilterStartDate', 'invoicesListFilterStartDateLocal', 'invoicesListFilterEndDate', 'invoicesListFilterEndDateLocal', 'invoicesListFilterDateApplied', 'showAddLineitemsForInternalInvoice', 'invoiceInternalLineItemSearchText', 'productListForInternalInvoice', 'newLineItemsInitialsForInternal', 'productFeatchingLoader', 'newProductForInternalInvoice', 'isAllApprovedJobsSelected', 'totalApprovedJobsCount', 'invoicesListFilterTagValue', 'invoicesListFilterTagsList'],
  'invoicesExternalModule': ['invoiceStatusEnum', 'invoiceStatusTextEnum', 'enableExternalInvoicingExternal'],
  'jobsInvoicesModule': ['invoiceJobReportUrls', 'invoiceReportGenerationInProgress'],
  'excelExportModule': ['exportDataOffset', 'exportDataTotal', 'exportDataLoaded', 'exportData'],
  'root': ['noData', 'filtersApplied', 'listCount', 'totalRowCount', 'accountTab', 'isPageRelated']
};
export default {
  name: 'InvoicesComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    taxValuesListForInvoice: {
      get: function get() {
        return this.$store.getters.invoiceConfiguration.salesTaxRates;
      }
    },
    taxName: {
      get: function get() {
        var _a, _b, _c, _d;
        if ((_b = (_a = this.invoiceDraftPreviewDetails) === null || _a === void 0 ? void 0 : _a.taxAccounts[0]) === null || _b === void 0 ? void 0 : _b.taxName) {
          return (_d = (_c = this.invoiceDraftPreviewDetails) === null || _c === void 0 ? void 0 : _c.taxAccounts[0]) === null || _d === void 0 ? void 0 : _d.taxName;
        }
        return this.$store.getters.invoiceConfiguration.salesTaxName;
      }
    },
    businessInfoUserId: {
      get: function get() {
        return localStorage.getItem(ConstantValues.ownerId);
      }
    },
    getSenderId: function getSenderId() {
      return localStorage.getItem(ConstantValues.ownerId);
    },
    currencySymbol: function currencySymbol() {
      return localStorage.getItem("currencySymbol");
    }
  })
};