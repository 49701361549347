import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-2 pb-2" }, [
    !_vm.teamJobDetails ||
    (_vm.teamJobDetails &&
      _vm.teamJobDetails.status < _vm.TeamJobStatusType.InProgress)
      ? _c("div", { staticClass: "pb-9" }, [
          _c("div", { staticClass: "tj-cp-title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$stringConstants("teamJobGeneralInformationTitle")) +
                " "
            ),
          ]),
          _c("div", { staticClass: "tj-cp-subtitle" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$stringConstants("teamJobGeneralInformationSubTitle")
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      [
        _c(VRow, { attrs: { "no-gutters": "" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.teamJobViewPreference.status.isVisible,
                  expression: "!teamJobViewPreference.status.isVisible",
                },
              ],
              staticClass: "width-perc-48",
            },
            [_c("TeamJobsOwners")],
            1
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.teamJobViewPreference.status.isVisible,
                expression: "!teamJobViewPreference.status.isVisible",
              },
            ],
            staticClass: "width-perc-4",
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.teamJobViewPreference.status.isVisible,
                  expression: "!teamJobViewPreference.status.isVisible",
                },
              ],
              staticClass: "width-perc-48",
            },
            [_c("TeamJobsScheduledDate")],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.teamJobViewPreference.status.isVisible,
                  expression: "teamJobViewPreference.status.isVisible",
                },
              ],
              staticClass: "width-perc-35",
            },
            [_c("TeamJobsOwners")],
            1
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.teamJobViewPreference.status.isVisible,
                expression: "teamJobViewPreference.status.isVisible",
              },
            ],
            staticClass: "width-perc-5",
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.teamJobViewPreference.status.isVisible,
                  expression: "teamJobViewPreference.status.isVisible",
                },
              ],
              staticClass: "width-perc-20",
            },
            [_c("TeamJobsScheduledDate")],
            1
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.teamJobViewPreference.status.isVisible,
                expression: "teamJobViewPreference.status.isVisible",
              },
            ],
            staticClass: "width-perc-7",
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.teamJobViewPreference.status.isVisible,
                  expression: "teamJobViewPreference.status.isVisible",
                },
              ],
              staticClass: "width-perc-20",
            },
            [_c("TeamJobsStatus")],
            1
          ),
          !_vm.teamJobsLoader
            ? _c(
                "div",
                { staticClass: "width-perc-13 text-right pt-7" },
                [_c("TeamJobsCancelOrDelete")],
                1
              )
            : _vm._e(),
        ]),
        _c(VRow, { attrs: { "no-gutters": "" } }, [
          _c(
            "div",
            {
              class: {
                "width-perc-60": _vm.isDetailsView,
                "width-perc-48": !_vm.isDetailsView,
              },
            },
            [_c("TeamJobsOperation")],
            1
          ),
        ]),
        _c(VRow, { attrs: { "no-gutters": "" } }, [
          _c(
            "div",
            {
              class: {
                "width-perc-36": _vm.isDetailsView,
                "width-perc-48": !_vm.isDetailsView,
              },
            },
            [_c("TeamJobTags")],
            1
          ),
        ]),
        _c(
          VRow,
          { attrs: { "no-gutters": "" } },
          [
            _c(
              VCol,
              { attrs: { cols: "12" } },
              [_c("TeamJobsInstructions")],
              1
            ),
          ],
          1
        ),
        _c(
          VRow,
          { attrs: { "no-gutters": "" } },
          [
            _c(
              VCol,
              { attrs: { cols: "12" } },
              [_c("TeamJobsAttachments")],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }