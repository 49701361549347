import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.object.to-string.js";
export var state = {
  teamMemberDetails: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    ownerId: null,
    roles: []
  },
  teamMembersList: [],
  pendingTeamMemberList: [],
  teamMemberSearchText: '',
  teamMembersLoader: false,
  memberFilterType: 0,
  teamMembeOldRole: null
};
export var getters = {
  teamMembersLoader: function teamMembersLoader(state) {
    return state.teamMembersLoader;
  },
  teamMemberDetails: function teamMemberDetails(state) {
    return state.teamMemberDetails;
  },
  teamMembersList: function teamMembersList(state) {
    return state.teamMembersList;
  },
  teamMembersListColumnTitles: function teamMembersListColumnTitles(state) {
    return state.teamMembersListColumnTitles;
  },
  teamMemberSearchText: function teamMemberSearchText(state) {
    return state.teamMemberSearchText;
  },
  memberFilterType: function memberFilterType(state) {
    return state.memberFilterType;
  },
  pendingTeamMemberList: function pendingTeamMemberList(state) {
    return state.pendingTeamMemberList;
  },
  teamMembeOldRole: function teamMembeOldRole(state) {
    return state.teamMembeOldRole;
  }
};
export var mutations = {
  setTeamMemberDetails: function setTeamMemberDetails(state, data) {
    state.teamMemberDetails['id'] = data.id;
    state.teamMemberDetails['email'] = data.email;
    state.teamMemberDetails['firstName'] = data.firstName;
    state.teamMemberDetails['lastName'] = data.lastName;
    state.teamMemberDetails['ownerId'] = data.ownerId;
    state.teamMemberDetails['roles'] = data.roles[0];
    state.teamMembeOldRole = data.roles[0].type;
  },
  setTeamMembersList: function setTeamMembersList(state, data) {
    data.map(function (data) {
      return state.teamMembersList.push(data);
    });
  },
  setPendingTeamMembersList: function setPendingTeamMembersList(state, data) {
    data.map(function (x) {
      var member = {
        "code": x.code,
        "inviterName": x.inviterName,
        "inviteeFirstName": x.inviteeFirstName,
        "inviteeLastName": x.inviteeLastName,
        "inviteeEmail": x.inviteeEmail,
        "inviteeRole": x.inviteeRole,
        "timestamp": x.timestamp,
        "expiry": x.expiry
      };
      state.pendingTeamMemberList.push(member);
    });
  },
  resetTeamMemberDetails: function resetTeamMemberDetails(state) {
    state.teamMemberDetails['id'] = null;
    state.teamMemberDetails['email'] = null;
    state.teamMemberDetails['firstName'] = null;
    state.teamMemberDetails['lastName'] = null;
    state.teamMemberDetails['ownerId'] = null;
    state.teamMemberDetails['roles'] = [];
  },
  setClearTeamMembersList: function setClearTeamMembersList(state) {
    state.teamMembersList = [];
  }
};