import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.object.to-string.js";
import { openOverlay, getCapitalised as _getCapitalised } from "@/utils/helpers";
import permissionsMixin from "@/mixins/permissions-mixin";
import scrollerMixin from "@/mixins/scroller-mixin";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import vehiclesComputedMixin from "@/components/machines/machines-computed";
import snackbarDelete from "@/components/shared/snackbar/snackbar-listing.vue";
import { ActionListingDialog, DeleteItemDialog as ItemDialog } from "@/components/shared/dialogs/index";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { listAction } from '@/enum/listAction';
import { MachineType as MachineTypeInfo } from '@/enum/machineType';
import { ViewPreferenceType } from '@/enum/viewPreferenceType';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/timers';
import { TabFilterType } from "@/enum/tabFilterType";
import { mapMutations } from 'vuex';
export default {
  name: "VehiclesListView",
  created: function created() {
    this.TabFilterType = TabFilterType;
  },
  mixins: [permissionsMixin, scrollerMixin, filterParamsMixin, vehiclesComputedMixin, Timers],
  components: {
    ItemDialog: ItemDialog,
    snackbarDelete: snackbarDelete,
    ActionListingDialog: ActionListingDialog,
    Button: Button,
    List: List
  },
  computed: {
    someMachinesSelected: function someMachinesSelected() {
      return this.selectedItems.length > 0 && !this.allMachinesSelected;
    },
    allMachinesSelected: function allMachinesSelected() {
      if (this.machineSearchText) {
        return false;
      } else {
        return this.selectedItems.length == this.machineListFiltered.length;
      }
    },
    moduleTitle: function moduleTitle() {
      if (this.machineType == MachineTypeInfo.Vehicle) {
        return this.$stringConstants('vehiclesTitle');
      } else if (this.machineType == MachineTypeInfo.Implement) {
        return this.$stringConstants('implementsTitle');
      }
      return '';
    }
  },
  watch: {
    selectedItems: function selectedItems(val) {
      if (this.selectedItems.length > 0) {
        this.snackbarShow = true;
      } else {
        this.snackbarShow = false;
        this.isChecked = false;
      }
    },
    machinesFilterType: function machinesFilterType() {
      this.$store.commit('clearSnackbarDetails');
    }
  },
  data: function data() {
    return {
      deleteDialog: false,
      actionType: listAction.Delete,
      listAction: listAction,
      actionOnMachineId: "",
      isChecked: false,
      MachineTypeInfo: MachineTypeInfo,
      ViewPreferenceType: ViewPreferenceType,
      searchTimer: null
    };
  },
  beforeMount: function beforeMount() {
    this.clearAndResetMachinesPagingStates();
  },
  beforeDestroy: function beforeDestroy() {
    this.clearAndResetMachinesPagingStates();
    this.$store.commit('clearSnackbarDetails');
  },
  mounted: function mounted() {
    this.$store.state.dashboardTitle = this.moduleTitle;
    this.getUrlParamsMachineLocal();
    this.setFilterOptionsAndGetMachines([ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]);
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    resetImportForm: "resetImportDataDetails"
  })), {}, {
    fnGetHeaderString: function fnGetHeaderString(actionType) {
      switch (actionType) {
        case listAction.Delete:
          {
            if (this.machineType == MachineTypeInfo.Vehicle) return this.$stringConstants('vehicleDeletePromtTitle');else return this.$stringConstants('implementDeletePromtTitle');
          }
        case listAction.Archive:
          {
            if (this.machineType == MachineTypeInfo.Vehicle) return this.$stringConstants('vehicleArchivePromptTitle');else return this.$stringConstants('implementArchivePromptTitle');
          }
        case listAction.Restore:
          {
            if (this.machineType == MachineTypeInfo.Vehicle) return this.$stringConstants('vehicleRestorePromptTitle');else return this.$stringConstants('implementRestorePromptTitle');
          }
      }
    },
    fnGetBodyString: function fnGetBodyString(actionType) {
      switch (actionType) {
        case listAction.Delete:
          {
            if (this.machineType == MachineTypeInfo.Vehicle) return this.$stringConstants('vehicleDeletePromtSubtitle');else return this.$stringConstants('implementDeletePromtSubtitle');
          }
        case listAction.Archive:
          {
            if (this.machineType == MachineTypeInfo.Vehicle) return this.$stringConstants('vehicleArchivePromptSubtitle');else return this.$stringConstants('implementArchivePromptSubtitle');
          }
        case listAction.Restore:
          {
            if (this.machineType == MachineTypeInfo.Vehicle) return this.$stringConstants('vehicleRestorePromptSubtitle');else return this.$stringConstants('implementRestorePromptSubtitle');
          }
      }
    },
    setUrlParamsMachineLocal: function setUrlParamsMachineLocal() {
      this.setLocalParamsMx(this.machineSearchText, "machineSearchQuery");
      this.setLocalParamsMx(this.machinesFilterType, "machineFilterType");
    },
    getUrlParamsMachineLocal: function getUrlParamsMachineLocal() {
      this.machineSearchText = this.getLocalParamsMx("machineSearchQuery", "");
      this.machinesFilterType = parseInt(this.getLocalParamsMx("machineFilterType", 0));
    },
    getMachineOwnership: function getMachineOwnership(ownershipVal) {
      return this.enumOwnership[ownershipVal]["title"];
    },
    openAddMachineForm: function openAddMachineForm() {
      this.clearMachineDetailsState();
      openOverlay("editMachineFormOverlay");
      this.scrollToToForm();
    },
    openEditMachineForm: function openEditMachineForm(vehicleId) {
      this.clearMachineDetailsState();
      this.resetSnackbarDetails();
      this.$store.dispatch("getmachine", vehicleId);
      openOverlay("editMachineFormOverlay");
      this.scrollToToForm();
    },
    applySearchMachineFilter: function applySearchMachineFilter() {
      this.clearMachineFilterOptions();
      this.resetPaging();
      this.machineListFiltered = [];
      this.setUrlParamsMachineLocal();
      if (this.machineSearchText != "" && this.machineSearchText != null) {
        this.filtersApplied = true;
      }
      this.setFilterOptionsAndGetMachines([ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]);
    },
    promtActionOnMachine: function promtActionOnMachine(machineId, listAction) {
      this.deleteDialog = true;
      this.actionType = listAction;
      this.actionOnMachineId = machineId;
    },
    proceedActionOnMachine: function proceedActionOnMachine(actionType) {
      this.deleteDialog = false;
      this.resetSnackbarDetails();
      this.$store.dispatch("actionOnMachineList", [actionType, this.actionOnMachineId]);
    },
    cancelActionOnMachine: function cancelActionOnMachine() {
      this.deleteDialog = false;
      this.actionOnMachineId = "";
    },
    getMachineListByStatus: function getMachineListByStatus() {
      this.resetSnackbarDetails();
      this.applySearchMachineFilter();
    },
    selectAllMachines: function selectAllMachines() {
      var _this = this;
      this.selectedItems = [];
      this.allItemsDeleted = false;
      if (this.isChecked) {
        this.machineListFiltered.map(function (pro) {
          _this.selectedItems.push(pro.id);
        });
      }
    },
    getCapitalised: function getCapitalised(unformattedString) {
      return _getCapitalised(unformattedString);
    },
    seedVehicles: function seedVehicles() {
      for (var i = 0; i < 400; i++) {
        var vehicleObj = {
          title: "VEHICLE_" + i,
          healthSafetyList: [],
          isHealthSafetyListMandatory: false,
          length: i + 100,
          width: i,
          make: "MK" + i,
          model: "MD" + i,
          ownership: i % 2 == 0 ? 0 : 1,
          registration: "REG" + i + "999",
          weight: i * 10
        };
        this.$store.dispatch("addVehicle", vehicleObj);
      }
    },
    setFilterOptionsAndGetMachines: function setFilterOptionsAndGetMachines(filter) {
      var _this2 = this;
      if (!this.machineSearchText) {
        this.clearMachineFilterOptions();
        this.filtersApplied = false;
      }
      this.$store.dispatch("fnSetMachineFilter", filter).then(function (res) {
        _this2.$store.dispatch("getMachineListFiltered");
      });
    },
    openImportForm: function openImportForm() {
      this.resetImportForm();
      openOverlay("importMachinesFormOverlay");
    }
  })
};