import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c("Snackbar", {
        attrs: {
          deleteApiType: "fields",
          status: _vm.fieldStatus,
          allItemsSelected: _vm.allFieldsSelected,
          someItemsSelected: _vm.someFieldsSelected,
          items: _vm.fieldFilterList,
        },
      }),
      _c("ActionListingDialog", {
        attrs: {
          itemName: "fields",
          onClickEvent: "proccedToActionOnFields",
          itemsPreference: this.fieldStatus,
        },
      }),
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.dialogForSelectCustomer,
            callback: function ($$v) {
              _vm.dialogForSelectCustomer = $$v
            },
            expression: "dialogForSelectCustomer",
          },
        },
        [
          _c(
            VCard,
            { staticClass: "dlt-diag px-3 pt-2 pb-3 d-flex flex-column" },
            [
              _c(
                VCardTitle,
                {
                  staticClass: "headline pt-6",
                  staticStyle: { "line-height": "30px" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$stringConstants(
                          "fieldsListCheckboxMultiSelectAlert"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                VCardActions,
                { staticClass: "px-6 mt-auto" },
                [
                  _c(VSpacer),
                  _c("SecondaryButton", {
                    attrs: {
                      variant: "secondary",
                      color: "primary",
                      textValue: "Close",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeDialogForSelectCustomer($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogField },
        on: {
          cancel: function ($event) {
            _vm.dialogField = false
          },
          proceed: _vm.deleteField,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("fieldDeletePromtTitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("deleteFieldWaring")) + " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("ArchiveItemDialog", {
        attrs: { item: _vm.archiveDialog },
        on: {
          cancel: _vm.cancelFieldArchiving,
          proceed: _vm.proceedFieldArchiving,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("fieldArchivePromptTitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("fieldArchivePromptSubtitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("RestoreItemDialog", {
        attrs: { item: _vm.restoreDialog },
        on: {
          cancel: _vm.cancelFieldRestoring,
          proceed: _vm.proceedFieldRestoring,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("fieldRestorePromptTitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("fieldRestorePromptSubtitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass: "flex-grow-1",
          style: { overflowY: _vm.drawerIsOpen ? "hidden" : "scroll" },
        },
        [
          _c(
            VRow,
            {
              ref: "fieldFilters",
              class:
                "field-filters px-9 pt-4 flex-grow-0 " +
                (_vm.drawerIsOpen ? "d-none" : ""),
              attrs: { "no-gutters": "", cols: "12" },
            },
            [
              _c(
                VCol,
                { attrs: { cols: "7" } },
                [
                  _c(
                    VRow,
                    { staticStyle: { "max-width": "643px" } },
                    [
                      _c(
                        VCol,
                        { staticClass: "px-0 pb-0", attrs: { cols: "5" } },
                        [
                          _c(VTextField, {
                            staticClass: "agd-txtfield mr-4",
                            class: {
                              "rd-rt-no":
                                _vm.ownerRole !=
                                _vm.UserRoleType[_vm.UserRoleType.Farmer],
                            },
                            attrs: {
                              placeholder: _vm.$stringConstants(
                                "searchFieldPlaceholder"
                              ),
                              outlined: "",
                              "prepend-inner-icon": "mdi-magnify",
                              clearable: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.applySearchTimer(
                                  _vm.applySearchFieldFilter
                                )
                              },
                            },
                            model: {
                              value: _vm.fieldSearchText,
                              callback: function ($$v) {
                                _vm.fieldSearchText = $$v
                              },
                              expression: "fieldSearchText",
                            },
                          }),
                        ],
                        1
                      ),
                      (_vm.loginUserRole == _vm.UserRoleType.Contractor ||
                        _vm.loginUserRole == _vm.UserRoleType.Manager) &&
                      _vm.ownerRole != _vm.UserRoleType[_vm.UserRoleType.Farmer]
                        ? _c(
                            VCol,
                            { staticClass: "px-0 pb-0", attrs: { cols: "4" } },
                            [
                              _c(VAutocomplete, {
                                staticClass: "rd-lf-no agd-dropdown",
                                attrs: {
                                  id: "fieldListFarmer",
                                  items: _vm.fieldOwnerFilterList,
                                  "search-input": _vm.fieldFarmerSearchText,
                                  "item-text": "firstName",
                                  "item-value": "id",
                                  label: "Select customer",
                                  loading:
                                    _vm.fieldsCustomersFilterDropdownLoader,
                                  "hide-no-data": "",
                                  outlined: "",
                                  dense: "",
                                  "append-icon": "mdi-chevron-down",
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.fieldFarmerSearchText = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.fieldFarmerSearchText = $event
                                  },
                                  focus: function ($event) {
                                    return _vm.getOwnerListAction({
                                      queryString: "",
                                    })
                                  },
                                  change: _vm.filterFieldsList,
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    return _vm.applyCustomersSearchTimer($event)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append-item",
                                      fn: function () {
                                        return [
                                          _c("LoadMoreItem", {
                                            attrs: {
                                              searchText:
                                                _vm.fieldFarmerSearchText,
                                              loadedListLength:
                                                _vm.fieldOwnerFilterList.length,
                                              moduleNamespace:
                                                _vm.$stringConstants(
                                                  "fieldsFilterCustomersPaginationModule"
                                                ),
                                            },
                                            on: {
                                              loadMoreItems:
                                                _vm.appendMoreCustomers,
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3690301333
                                ),
                                model: {
                                  value: _vm.fieldListOwnerId,
                                  callback: function ($$v) {
                                    _vm.fieldListOwnerId = $$v
                                  },
                                  expression: "fieldListOwnerId",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        VCol,
                        { staticClass: "px-0 pb-0", attrs: { cols: "3" } },
                        [
                          _c(VSelect, {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isInternalSelectedOwner,
                                expression: "isInternalSelectedOwner",
                              },
                            ],
                            staticClass: "rd-lf-no agd-dropdown ml-4",
                            attrs: {
                              items: _vm.enumActiveStatus,
                              "item-value": "value",
                              "item-text": "title",
                              placeholder:
                                _vm.$stringConstants("statusPlaceholder"),
                              dense: "",
                              outlined: "",
                              "append-icon": "mdi-chevron-down",
                            },
                            on: { change: _vm.getFieldListByStatus },
                            model: {
                              value: _vm.fieldStatus,
                              callback: function ($$v) {
                                _vm.fieldStatus = $$v
                              },
                              expression: "fieldStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.shouldComponentDisplay(
                _vm.componentsPermissions["fieldCreate"]
              )
                ? _c(
                    VCol,
                    { attrs: { cols: "5" } },
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            {
                              staticClass:
                                "pr-0 text-right d-flex align-flex-start justify-end",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(VSwitch, {
                                staticStyle: { transform: "translateX(-21px)" },
                                attrs: {
                                  inset: "",
                                  label: _vm.showFieldsList
                                    ? "Fields list"
                                    : "Fields list",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function () {
                                        return undefined
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  157076738
                                ),
                                model: {
                                  value: _vm.showFieldsList,
                                  callback: function ($$v) {
                                    _vm.showFieldsList = $$v
                                  },
                                  expression: "showFieldsList",
                                },
                              }),
                              _c("Button", {
                                attrs: {
                                  variant: "secondary",
                                  color: "primary",
                                  outlined: "",
                                  outlineColor: "primary",
                                  textValue:
                                    _vm.$stringConstants("addNewField"),
                                  leftIcon: "mdi-plus",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.openDrawerForAddingField($event)
                                  },
                                },
                              }),
                              _vm.loginUserRole == _vm.UserRoleType.Farmer ||
                              (_vm.loginUserRole == _vm.UserRoleType.Manager &&
                                _vm.ownerRole == "Farmer") ||
                              _vm.loginUserRole ==
                                _vm.UserRoleType.Contractor ||
                              (_vm.loginUserRole == _vm.UserRoleType.Manager &&
                                _vm.ownerRole == "Contractor")
                                ? _c("Button", {
                                    staticClass: "ml-3",
                                    attrs: {
                                      variant: "secondary",
                                      color: "tertiary",
                                      textValue: "Import",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.openBulkFieldsUploadForm(
                                          $event
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("FieldsListMap"),
          _vm.shouldComponentDisplay(_vm.componentsPermissions["fieldRead"])
            ? _c("List", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.drawerIsOpen,
                    expression: "!drawerIsOpen",
                  },
                ],
                staticClass: "field-list",
                staticStyle: { overflow: "unset" },
                attrs: {
                  listItems: _vm.fieldFilterList,
                  containsAction: "",
                  listHeaderOffset: _vm.listHeaderOffset,
                },
                on: {
                  setFilterOptionsAndGetListData:
                    _vm.setFilterOptionsAndGetFields,
                  listItemClick: _vm.handleListItemClick,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "list-heading",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "ib width-perc-20" }, [
                            _vm.isInternalCustomer ||
                            _vm.userIsFarmerOrFarmerManager ||
                            _vm.fieldListOwnerId == "All"
                              ? _c(
                                  "div",
                                  { staticClass: "ib field-check" },
                                  [
                                    _c(VCheckbox, {
                                      staticClass: "mt-0 pt-0",
                                      attrs: {
                                        indeterminate: _vm.someFieldsSelected,
                                        "input-value": _vm.allFieldsSelected,
                                      },
                                      on: { change: _vm.selectAllFields },
                                      model: {
                                        value: _vm.isChecked,
                                        callback: function ($$v) {
                                          _vm.isChecked = $$v
                                        },
                                        expression: "isChecked",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "ib v-a-t" }, [
                              _vm._v(
                                _vm._s(_vm.$stringConstants("fieldNameText"))
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "ib width-perc-10" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("areaText"))),
                          ]),
                          _c("div", { staticClass: "ib width-perc-15 pr-2" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("farmText"))),
                          ]),
                          _c("div", { staticClass: "ib width-perc-15" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("ownerText"))),
                          ]),
                          _c("div", { staticClass: "ib width-perc-15" }, [
                            _vm._v(_vm._s(_vm.$stringConstants("cropText"))),
                          ]),
                          _c("div", { staticClass: "ib width-perc-20" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("ownershipText"))
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "ib width-perc-5 text-right" },
                            [_vm._v(_vm._s(_vm.$stringConstants("action")))]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "list-item",
                      fn: function (ref) {
                        var listItem = ref.listItem
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                    ib\n                    width-perc-20\n                    pr-xl-16 pr-md-5 pr-lg-8\n                    d-flex\n                    align-center\n                ",
                            },
                            [
                              _vm.isInternalCustomer ||
                              _vm.userIsFarmerOrFarmerManager
                                ? _c(
                                    "div",
                                    { staticClass: "ib field-check" },
                                    [
                                      _c(VCheckbox, {
                                        staticClass: "pt-0 mt-0",
                                        attrs: {
                                          disabled:
                                            (!listItem.owner.isInternal &&
                                              _vm.loginUser !=
                                                listItem.owner.id) ||
                                            (_vm.fieldListOwnerId == "All" &&
                                              !_vm.userIsFarmerOrFarmerManager),
                                          value: listItem.id,
                                          multiple: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                        model: {
                                          value: _vm.selectedItems,
                                          callback: function ($$v) {
                                            _vm.selectedItems = $$v
                                          },
                                          expression: "selectedItems",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "ib v-a-t" }, [
                                _c(
                                  "div",
                                  { staticClass: "lt-data lt-data-fields" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "lt-data-title fw-6 cursor-pointer",
                                        staticStyle: { "font-weight": "700" },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(listItem.title) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "ib width-perc-10" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getAreaFormatted(
                                    listItem.areaInSquareMeter
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          listItem.address != null
                            ? _c(
                                "div",
                                { staticClass: "ib width-perc-15 pr-2" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(listItem.address.addressLine1) +
                                      " "
                                  ),
                                ]
                              )
                            : _c("div", { staticClass: "ib width-perc-15" }, [
                                _vm._v("-"),
                              ]),
                          _c(
                            "div",
                            {
                              staticClass: "ib width-perc-15 pr-4",
                              attrs: {
                                title: _vm.getOwnerBusinessTitle(
                                  listItem.owner
                                ),
                              },
                            },
                            [
                              _c("div", { staticClass: "text-nowrap" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getOwnerBusinessTitle(listItem.owner)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          listItem.crop != null
                            ? _c("div", { staticClass: "ib width-perc-15" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(listItem.crop.name) +
                                    "(" +
                                    _vm._s(listItem.crop.year) +
                                    ") "
                                ),
                              ])
                            : _c("div", { staticClass: "ib width-perc-15" }, [
                                _vm._v("-"),
                              ]),
                          _c("div", { staticClass: "ib width-perc-20" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getOwnership(listItem.ownership)) +
                                " "
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "ib width-perc-5 text-right position-relative",
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    VMenu,
                                    {
                                      attrs: {
                                        bottom: "",
                                        "min-width": "120",
                                        left: "",
                                        attach: "",
                                        "content-class": "text-left",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "triple-dot-menu",
                                                        attrs: { icon: "" },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(VIcon, [
                                                      _vm._v(
                                                        "mdi-dots-vertical"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        VList,
                                        [
                                          _c(
                                            VListItem,
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.isFieldExternal(
                                                      listItem
                                                    ),
                                                  expression:
                                                    "isFieldExternal(listItem)",
                                                },
                                              ],
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.openDrawerForEditingField(
                                                    [
                                                      listItem.id,
                                                      listItem.owner.id,
                                                    ],
                                                    true,
                                                    listItem
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [_vm._v("mdi-eye")]
                                                  ),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$stringConstants(
                                                        "listItemView"
                                                      )
                                                    ) + " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.shouldComponentDisplay(
                                            _vm.componentsPermissions[
                                              "fieldUpdate"
                                            ]
                                          ) && _vm.isFieldInternal(listItem)
                                            ? _c(
                                                VListItem,
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.openDrawerForEditingField(
                                                        [
                                                          listItem.id,
                                                          listItem.owner.id,
                                                        ],
                                                        false,
                                                        listItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VListItemTitle,
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticClass:
                                                            "icon-size pr-2",
                                                        },
                                                        [_vm._v("mdi-pencil")]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$stringConstants(
                                                            "listItemEdit"
                                                          )
                                                        ) + " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            VListItem,
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.shouldComponentDisplay(
                                                      _vm.componentsPermissions[
                                                        "fieldArchive"
                                                      ]
                                                    ) &&
                                                    _vm.isFieldInternal(
                                                      listItem
                                                    ) &&
                                                    _vm.fieldStatus ==
                                                      _vm.TabFilterType.Active,
                                                  expression:
                                                    "shouldComponentDisplay(componentsPermissions['fieldArchive']) && isFieldInternal(listItem) && fieldStatus == TabFilterType.Active",
                                                },
                                              ],
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.confirmArchiveField(
                                                    listItem.id,
                                                    listItem.owner.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-archive-arrow-down"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v("Archive "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItem,
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.shouldComponentDisplay(
                                                      _vm.componentsPermissions[
                                                        "fieldRestore"
                                                      ]
                                                    ) &&
                                                    _vm.isFieldInternal(
                                                      listItem
                                                    ) &&
                                                    _vm.fieldStatus ==
                                                      _vm.TabFilterType
                                                        .Inactive,
                                                  expression:
                                                    "shouldComponentDisplay(componentsPermissions['fieldRestore']) && isFieldInternal(listItem) && fieldStatus == TabFilterType.Inactive",
                                                },
                                              ],
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.confirmRestoreField(
                                                    listItem.id,
                                                    listItem.owner.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [_vm._v("mdi-restore")]
                                                  ),
                                                  _vm._v("Restore "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.shouldComponentDisplay(
                                            _vm.componentsPermissions[
                                              "fieldDelete"
                                            ]
                                          ) && _vm.isFieldInternal(listItem)
                                            ? _c(
                                                VListItem,
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.confirmDeleteField(
                                                        [
                                                          listItem.id,
                                                          listItem.owner.id,
                                                        ]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VListItemTitle,
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticClass:
                                                            "icon-size pr-2",
                                                        },
                                                        [_vm._v("mdi-delete")]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$stringConstants(
                                                            "listItemDelete"
                                                          )
                                                        ) + " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "zero-state",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "pt-4" }, [
                            _vm.fieldStatus === _vm.TabFilterType.Active
                              ? _c("div", [
                                  _c("h4", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$stringConstants(
                                          "fieldZeroStateHeading"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("p", { staticClass: "pt-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$stringConstants("fieldZeroState")
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm.fieldStatus === _vm.TabFilterType.Inactive
                              ? _c("div", [
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$stringConstants(
                                          "fieldZeroStateInactive"
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  635467396
                ),
              })
            : _c("div", { staticClass: "pt-8" }, [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("fieldListDisabled")) + " "
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }