import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.number.is-integer.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { closeOverlay } from "@/utils/helpers";
import { formFieldPb, containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import productsComputedMixin from "@/components/products/products-computed";
import { HeaderPrimary, SubHeaderPrimary, DescriptionPrimary } from "@/components/shared/headers/index";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { JobExportTimeUnitType } from "@/enum/jobExportTimeUnitType";
export default {
  name: "TeamJobsExportFlyout",
  components: {
    ListLoader: ListLoader,
    SubHeaderPrimary: SubHeaderPrimary,
    DescriptionPrimary: DescriptionPrimary,
    Button: Button,
    HeaderPrimary: HeaderPrimary
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb,
      containerLeftPad: containerLeftPad,
      containerRightPad: containerRightPad,
      exportableColumnsLoading: false,
      JobExportTimeUnitType: JobExportTimeUnitType
    };
  },
  mixins: [productsComputedMixin],
  computed: {
    timeUnitTypes: {
      get: function get() {
        var timeUnitsEnum = JobExportTimeUnitType;
        return Object.keys(timeUnitsEnum).map(function (key) {
          return {
            value: timeUnitsEnum[key],
            title: key
          };
        }).filter(function (unit) {
          return Number.isInteger(unit.value);
        });
      }
    },
    exportableColumns: {
      get: function get() {
        return this.$store.getters.exportableColumns;
      },
      set: function set(val) {
        this.$store.state.teamJobsListModule.exportableColumns = val;
      }
    },
    jobExportLoading: {
      get: function get() {
        return this.$store.getters.jobExportLoading;
      },
      set: function set(val) {
        this.$store.state.teamJobsListModule.jobExportLoading = val;
      }
    },
    isAllExportableColumnsSelected: {
      get: function get() {
        return this.$store.getters.isAllExportableColumnsSelected;
      },
      set: function set(val) {
        this.$store.state.teamJobsListModule.isAllExportableColumnsSelected = val;
      }
    },
    exportableColumnTimeType: {
      get: function get() {
        return this.$store.getters.exportableColumnTimeType;
      },
      set: function set(val) {
        this.$store.state.teamJobsListModule.exportableColumnTimeType = val;
      }
    },
    jobExportConfigTitle: {
      get: function get() {
        return this.$store.getters.jobExportConfigTitle;
      },
      set: function set(val) {
        this.$store.state.teamJobsListModule.jobExportConfigTitle = val;
      }
    },
    usersBusinessConfig: {
      get: function get() {
        return this.$store.getters.usersBusinessConfig;
      },
      set: function set(val) {
        this.$store.state.teamJobsListModule.usersBusinessConfig = val;
      }
    },
    jobExportSelectedProfileIndex: {
      get: function get() {
        return this.$store.getters.jobExportSelectedProfileIndex;
      },
      set: function set(val) {
        this.$store.state.teamJobsListModule.jobExportSelectedProfileIndex = val;
      }
    }
  },
  methods: {
    closeJobsExportForm: function closeJobsExportForm() {
      closeOverlay("exportJobsFormOverlay");
      this.isAllExportableColumnsSelected = false;
    },
    exportJobs: function exportJobs() {
      var _this = this;
      this.$store.dispatch("exportSelectedJobsWithColumns").then(function (res) {
        closeOverlay("exportJobsFormOverlay");
        _this.$store.commit('clearSnackbarDetails');
      });
    },
    handleSelectAll: function handleSelectAll() {
      if (this.isAllExportableColumnsSelected) {
        this.exportableColumns.forEach(function (column) {
          column.isSelected = true;
        });
      } else {
        this.exportableColumns.forEach(function (column) {
          column.isSelected = false;
        });
      }
    },
    createNewJobExportProfile: function createNewJobExportProfile() {
      this.$store.dispatch("updateJobExportConfigInBusinessConfig");
    },
    handleJobExportProfileSelection: function handleJobExportProfileSelection() {
      this.$store.dispatch("populateJobExportConfigFromUserConfig");
    }
  }
};