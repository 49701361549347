import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Vue from 'vue';
import FieldInfoWindow from '@/components/fields/field-info-window.vue';
var fieldInfoWindowConstructor = Vue.extend(FieldInfoWindow);
var DEFAULT_STROKE_COLOR = '#FFFFFF';
var DEFAULT_STROKE_WEIGHT = 3;
var STRONG_STROKE_WEIGHT = 4;
var DEFAULT_FILL_OPACITY = 0.5;
var SUPPORTED_LINE_STRINGS = ['LineString', 'MultiLineString'];
var featureLayerEventListeners = [];
export var colorizeFeature = function colorizeFeature(color, feature, featureLayer) {
  featureLayer.overrideStyle(feature, {
    fillColor: color,
    fillOpacity: DEFAULT_FILL_OPACITY,
    strokeColor: color,
    strokeWeight: DEFAULT_STROKE_WEIGHT
  });
};
export var createFeatureData = function createFeatureData(feature) {
  var featureData = {
    "type": feature.type,
    "id": feature.id,
    "properties": feature.properties,
    "geometry": feature.geometry
  };
  return featureData;
};
export var getFeatureById = function getFeatureById(featureLayer, id) {
  return featureLayer.getFeatureById(id);
};
export var addGeoJsonFeatureOnMap = function addGeoJsonFeatureOnMap(feature, featureLayer, field) {
  var features = feature;
  if (field) {
    feature.properties = _objectSpread(_objectSpread({}, feature.properties), field);
  }
  var featureData = createFeatureData(feature);
  features = featureLayer.addGeoJson(featureData);
  if (features) colorizeFeature(feature.properties.colour, features === null || features === void 0 ? void 0 : features[0], featureLayer);
  return features;
};
export var addFunctionToMouseOverFeature = function addFunctionToMouseOverFeature(featureLayer, mouseEvent, callback) {
  var eventName = mouseEvent !== null && mouseEvent !== void 0 ? mouseEvent : 'click';
  var listener = null;
  if (featureLayer) {
    listener = featureLayer.addListener(eventName, function (e) {
      callback(e);
    });
  }
};
export var createInfoWindowHTML = function createInfoWindowHTML(feature) {
  var fieldInfo = {
    fieldId: feature.id,
    fieldColor: feature.getProperty('fieldColor'),
    fieldName: feature.getProperty('fieldName'),
    fieldArea: feature.getProperty('fieldArea'),
    fieldCrop: feature.getProperty('fieldCrop'),
    fieldCustomer: feature.getProperty('fieldCustomer'),
    fieldEditable: feature.getProperty('fieldEditable'),
    fieldAddress: feature.getProperty('fieldAddress')
  };
  var fieldInfoWindowComponent = new fieldInfoWindowConstructor({
    propsData: {
      fieldInfo: fieldInfo
    }
  });
  fieldInfoWindowComponent.$mount();
  var fieldInfoWindowString = new XMLSerializer().serializeToString(fieldInfoWindowComponent.$el);
  return fieldInfoWindowString;
};
export var attachInfoWindowToDataLayerFeatures = function attachInfoWindowToDataLayerFeatures(featureLayer, mapRef, infoWindow) {
  var lastFeature = null;
  var mouseOverListener = featureLayer.addListener('mouseover', function (event) {
    var feature = event.feature;
    if (lastFeature !== feature) {
      if (lastFeature) {
        infoWindow.close();
      }
      lastFeature = feature;
      var contentString = createInfoWindowHTML(feature);
      infoWindow.setContent(contentString);
      infoWindow.setPosition(getCenterOfFeature(feature));
      infoWindow.open(mapRef);
    }
  });
  var mouseOutListener = featureLayer.addListener('mouseout', function (event) {
    infoWindow.close();
    lastFeature = null;
  });
  featureLayerEventListeners.push(mouseOverListener);
  featureLayerEventListeners.push(mouseOutListener);
};
export var removeAllInfoWindowsFromDataLayerFeatures = function removeAllInfoWindowsFromDataLayerFeatures() {
  featureLayerEventListeners.forEach(function (listener) {
    google.maps.event.removeListener(listener);
  });
  featureLayerEventListeners = [];
};
export var attachClickListnerToDataLayerFeatures = function attachClickListnerToDataLayerFeatures(featureLayer, callback) {
  var listener = featureLayer.addListener('click', function (event) {
    var feature = event.feature;
    var featureId = feature.getProperty('id');
    callback(featureId, listener);
  });
  listener && featureLayerEventListeners.push(listener);
};
export var removeAllFeaturesFromDataLayer = function removeAllFeaturesFromDataLayer(featureLayer) {
  featureLayer.forEach(function (feature) {
    featureLayer.remove(feature);
  });
};
export var removeFeatureByIdFromDataLayer = function removeFeatureByIdFromDataLayer(featureLayer, id) {
  var feature = featureLayer.getFeatureById(id);
  if (feature) featureLayer.remove(feature);
};
export var removeFeatureByIdsFromDataLayer = function removeFeatureByIdsFromDataLayer(featureLayer, ids) {
  ids.forEach(function (id) {
    removeFeatureByIdFromDataLayer(featureLayer, id);
  });
};
export var removeAllClickListenersFromDataLayerFeatures = function removeAllClickListenersFromDataLayerFeatures() {
  featureLayerEventListeners.forEach(function (listener) {
    google.maps.event.removeListener(listener);
  });
  featureLayerEventListeners = [];
};
export var getFeaturesByIds = function getFeaturesByIds(featureLayer, ids) {
  var features = [];
  ids.forEach(function (id) {
    var feature = featureLayer.getFeatureById(id);
    features.push(feature);
  });
  return features;
};
export var getBoundsOfFeature = function getBoundsOfFeature(feature) {
  var bounds = new google.maps.LatLngBounds();
  feature.getGeometry().forEachLatLng(function (latLng) {
    bounds.extend(latLng);
  });
  return bounds;
};
export var getBoundsOfFeatures = function getBoundsOfFeatures(featureLayer) {
  var bounds = new google.maps.LatLngBounds();
  featureLayer.forEach(function (feature) {
    feature.getGeometry().forEachLatLng(function (latLng) {
      bounds.extend(latLng);
    });
  });
  return bounds;
};
export var getCenterOfFeature = function getCenterOfFeature(feature) {
  var bounds = new google.maps.LatLngBounds();
  feature.getGeometry().forEachLatLng(function (latLng) {
    bounds.extend(latLng);
  });
  return bounds.getCenter();
};
export var getCenterOfFeatureById = function getCenterOfFeatureById(featureLayer, id) {
  var feature = featureLayer.getFeatureById(id);
  return getCenterOfFeature(feature);
};
export var getBoundsByFeatureIds = function getBoundsByFeatureIds(featureLayer, ids) {
  var bounds = new google.maps.LatLngBounds();
  var features = getFeaturesByIds(featureLayer, ids);
  features.forEach(function (feature) {
    feature.getGeometry().forEachLatLng(function (latLng) {
      bounds.extend(latLng);
    });
  });
  return bounds;
};
export var boundMapByAllFeatures = function boundMapByAllFeatures(featureLayer, mapRef) {
  var bounds = getBoundsOfFeatures(featureLayer);
  mapRef.fitBounds(bounds);
};
export var convertCoordsToGeoJsonCoords = function convertCoordsToGeoJsonCoords(coords) {
  var geoJsonCoords = coords.map(function (coord) {
    return [coord.longitude, coord.latitude];
  });
  if (geoJsonCoords.length > 0) {
    geoJsonCoords.push(geoJsonCoords[0]);
  }
  return [geoJsonCoords];
};
export var highlightFeaturesByIds = function highlightFeaturesByIds(featureLayer, ids) {
  var features = getFeaturesByIds(featureLayer, ids);
  features.forEach(function (feature) {
    featureLayer.overrideStyle(feature, {
      strokeColor: DEFAULT_STROKE_COLOR,
      strokeWeight: STRONG_STROKE_WEIGHT
    });
  });
};
export var revertFeatureStylesByIds = function revertFeatureStylesByIds(featureLayer, ids, field) {
  var features = getFeaturesByIds(featureLayer, ids);
  features.forEach(function (feature) {
    var featureColor = feature.getProperty('colour');
    featureLayer.overrideStyle(feature, {
      fillColor: featureColor,
      fillOpacity: DEFAULT_FILL_OPACITY,
      strokeColor: featureColor,
      strokeWeight: DEFAULT_STROKE_WEIGHT
    });
  });
};
export var getCoordinatesFromFeatureProperty = function getCoordinatesFromFeatureProperty(feature) {
  if (!feature.geometry) return [];
  var coordinates = feature.geometry.coordinates[0].map(function (c) {
    return {
      latitude: c[1],
      longitude: c[0]
    };
  });
  return coordinates;
};
export var drawLineStringFeatureOnFeatureLayer = function drawLineStringFeatureOnFeatureLayer(featureLayer, feature) {
  if (!featureLayer || !feature) return;
  var features = feature;
  var featureData = createFeatureData(feature);
  features = featureLayer.addGeoJson(featureData);
  if (features) {
    featureLayer.overrideStyle(features[0], {
      strokeColor: DEFAULT_STROKE_COLOR,
      strokeWeight: STRONG_STROKE_WEIGHT
    });
  }
  return features;
};
export var removeAllLineStringFeaturesFromFeatureLayer = function removeAllLineStringFeaturesFromFeatureLayer(featureLayer) {
  if (!featureLayer) return;
  featureLayer.forEach(function (feature) {
    if (SUPPORTED_LINE_STRINGS.includes(feature.getGeometry().getType())) {
      featureLayer.remove(feature);
    }
  });
};
export var setMapBoundsByAllLineStringFeatures = function setMapBoundsByAllLineStringFeatures(featureLayer, mapRef) {
  if (!featureLayer) return;
  var bounds = new google.maps.LatLngBounds();
  featureLayer.forEach(function (feature) {
    if (SUPPORTED_LINE_STRINGS.includes(feature.getGeometry().getType())) {
      feature.getGeometry().forEachLatLng(function (latLng) {
        bounds.extend(latLng);
      });
    }
  });
  mapRef.fitBounds(bounds);
};