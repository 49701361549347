import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.some.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import accountComputedMixin from "@/components/account/account-computed";
import AccountProfileForm from "./account-profile.vue";
import AccountBusinessForm from "./account-business.vue";
import FarmsAddressForm from "./account-farms-address.vue";
import { UserRoleType } from "@/enum/userRoleType";
import { AccountTabType } from "@/enum/accountTabType";
import requestsCancellationMixin from '@/mixins/requests-cancellation';
export default {
  mixins: [accountComputedMixin, requestsCancellationMixin],
  data: function data() {
    return {
      UserRoleType: UserRoleType
    };
  },
  components: {
    AccountProfileForm: AccountProfileForm,
    AccountBusinessForm: AccountBusinessForm,
    FarmsAddressForm: FarmsAddressForm
  },
  mounted: function mounted() {
    this.$store.state.dashboardTitle = this.$stringConstants('accountTitle');
    this.$store.dispatch("getGlobalCountriesList");
    var urlSearchParams = new URLSearchParams(window.location.search);
    var target = urlSearchParams.get('target');
    if (target == 'tags') this.accountTab = AccountTabType.Business;
    var email = urlSearchParams.get('email');
    var code = urlSearchParams.get('code');
    function currentUrlPathContains(keyword) {
      var pathSegments = window.location.href.split('/');
      return pathSegments.some(function (part) {
        return part.includes(keyword);
      });
    }
    if (currentUrlPathContains('change')) {
      if (email && code) {
        this.$store.dispatch("changeEmailAddress", {
          email: email,
          code: code
        });
      }
    }
    if (currentUrlPathContains('cancel')) {
      this.$store.dispatch("cancelEmailChange");
    }
  },
  methods: {
    handleTabChangeBusiness: function handleTabChangeBusiness(accountTab) {
      if (this.accountTab == AccountTabType.Business) {
        this.$store.dispatch("getGoogleMapToShowDefaultMarker");
      }
    }
  }
};