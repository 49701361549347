import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column h-percent-100" }, [
    _c("div", { attrs: { id: "overlay" } }),
    _c("div", { staticClass: "pl-9 pr-8 pb-6" }, [
      _c(
        "div",
        [
          _c("div", { staticClass: "py-1 text--black f-22 font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.invoiceDraftPreviewDetailsExternal &&
                    _vm.invoiceDraftPreviewDetailsExternal.number != null
                    ? _vm.invoiceDraftPreviewDetailsExternal.number
                    : ""
                ) +
                " "
            ),
          ]),
          _c(VRow, { staticClass: "pb-4 pt-7" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.invoiceDraftPreviewDetailsExternal,
                    expression: "invoiceDraftPreviewDetailsExternal",
                  },
                ],
                staticClass: "ib pt-0 pl-0 mr-4",
                staticStyle: { width: "410px !important" },
              },
              [
                _c("div", { staticClass: "pb-2" }, [
                  _c(
                    "div",
                    { staticClass: "fw-6 inv-title-fn color-dgray py-6" },
                    [_vm._v(_vm._s(_vm.$stringConstants("customer")))]
                  ),
                  _vm.invoiceDraftPreviewDetailsExternal
                    ? _c(
                        "div",
                        {
                          staticClass: "inv-int-crd inv-text-sm d-flex",
                          staticStyle: { "min-height": "unset" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "py-1 position-relative d-flex",
                              attrs: { align: "center" },
                            },
                            [
                              _c("div", { staticClass: "ib" }, [
                                _c("img", {
                                  staticClass:
                                    "inv-crd-icn pr-2 inv-id-icon-pos",
                                  attrs: {
                                    src: require("../../../assets/icon-customers.svg"),
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "ib inv-text-sm fw-5",
                                  attrs: {
                                    title:
                                      _vm.invoiceDraftPreviewDetailsExternal
                                        .customerName,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.invoiceDraftPreviewDetailsExternal.customerName.substring(
                                          0,
                                          _vm.maxCompanyNameLength
                                        )
                                      ) +
                                      " "
                                  ),
                                  _vm.invoiceDraftPreviewDetailsExternal
                                    .customerName.length >
                                  _vm.maxCompanyNameLength
                                    ? _c("span", [_vm._v(" ... ")])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm.invoiceDraftPreviewDetailsExternal &&
                _vm.invoiceDraftPreviewDetailsExternal.emailAddress
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "pl-6 py-1 inv-text-xsm",
                          attrs: {
                            title:
                              _vm.invoiceDraftPreviewDetailsExternal
                                .emailAddress,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.invoiceDraftPreviewDetailsExternal
                                  .emailAddress
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.invoiceDraftPreviewDetailsExternal &&
                _vm.invoiceDraftPreviewDetailsExternal.phoneNumber
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "pl-6 py-1 inv-text-xsm",
                          attrs: {
                            title:
                              _vm.invoiceDraftPreviewDetailsExternal
                                .phoneNumber,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.invoiceDraftPreviewDetailsExternal
                                  .phoneNumber
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _c("div", { staticClass: "ib py-0 ml-2 mr-6 inv-sp-cont" }, [
              _c("div", { staticClass: "inv-sp-cont-line" }),
            ]),
            !_vm.invoiceLoaderExternal
              ? _c(
                  "div",
                  {
                    staticClass: "ib pt-0 pl-0 mr-4",
                    staticStyle: { width: "410px" },
                  },
                  [
                    _vm.linkedContactToExternalService
                      ? _c("div", { staticClass: "pb-2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "fw-6 inv-title-fn color-dgray py-6",
                            },
                            [
                              _vm._v(
                                " Linked " +
                                  _vm._s(_vm.getExternalInvoiceTitle()) +
                                  " " +
                                  _vm._s(_vm.$stringConstants("contact")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "inv-int-crd inv-text-sm d-flex",
                              staticStyle: { "min-height": "unset" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "py-1 position-relative d-flex",
                                  staticStyle: {
                                    "align-self": "center",
                                    "min-width": "78%",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "ib" }, [
                                    _c("img", {
                                      staticClass:
                                        "inv-crd-icn pr-2 inv-id-icon-pos",
                                      attrs: {
                                        src: require("../../../assets/" +
                                          _vm.integrationType +
                                          "-logo.png"),
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ib inv-text-sm fw-5 text-start",
                                      attrs: {
                                        title: _vm.invoiceContactCompanyName,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.invoiceContactCompanyName.substring(
                                              0,
                                              _vm.maxCompanyNameLength
                                            )
                                          ) +
                                          " "
                                      ),
                                      _vm.invoiceContactCompanyName.length >
                                      _vm.maxCompanyNameLength
                                        ? _c("span", [_vm._v(" ... ")])
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "ib py-1" }, [
                                _c(
                                  "div",
                                  [
                                    _c("Button", {
                                      staticClass: "mt-0 ml-4",
                                      staticStyle: {
                                        height: "30px !important",
                                      },
                                      attrs: {
                                        variant: "tertiary",
                                        color: "primary",
                                        textValue: "Unlink",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.unlinkContact()
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _c("div", { staticClass: "pb-2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "fw-6 inv-title-fn color-dgray py-6",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getExternalInvoiceTitle()) +
                                  " " +
                                  _vm._s(_vm.$stringConstants("contact")) +
                                  " "
                              ),
                            ]
                          ),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$stringConstants(
                                    "invoiceContactNotLinkedWarningText"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "pt-2" },
                              [
                                _c("Button", {
                                  staticStyle: { height: "30px !important" },
                                  attrs: {
                                    variant: "tertiary",
                                    color: "primary",
                                    textValue: "Link",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.openContactsFlyout()
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                  ]
                )
              : _c("div", { staticClass: "pt-10" }, [
                  _c("div", { staticClass: "pt-8" }, [
                    _c(
                      "div",
                      { staticClass: "ib" },
                      [
                        _c(VProgressCircular, {
                          attrs: {
                            width: 2,
                            size: 25,
                            color: _vm.brandColor,
                            indeterminate: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "ib pl-4 py-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants(
                              "invoiceContactStatusCheckText"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
            _c("div", { staticClass: "ib py-0 mx-2 inv-sp-cont" }, [
              _c("div", { staticClass: "inv-sp-cont-line" }),
            ]),
          ]),
          _c(
            VRow,
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoiceDraftPreviewDetailsExternal,
                  expression: "invoiceDraftPreviewDetailsExternal",
                },
              ],
            },
            [
              _c(
                VCol,
                {
                  staticClass: "pl-0",
                  staticStyle: { "max-width": "370px" },
                  attrs: { cols: "6" },
                },
                [
                  _c(
                    VMenu,
                    {
                      ref: "invoiceMenuDate",
                      attrs: {
                        "close-on-content-click": false,
                        "return-value": _vm.invoiceDateExternal,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "auto",
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          _vm.invoiceDateExternal = $event
                        },
                        "update:return-value": function ($event) {
                          _vm.invoiceDateExternal = $event
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        value: _vm.computedInvoiceDateFormatted,
                                        label: _vm.$stringConstants(
                                          "invoiceExternalDraftDateLabel"
                                        ),
                                        "append-icon": "mdi-calendar",
                                        readonly: "",
                                        clearable: "",
                                        outlined: "",
                                        dense: "",
                                      },
                                      on: {
                                        "click:append": on.click,
                                        "click:clear": function ($event) {
                                          _vm.invoiceDateExternal = null
                                        },
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.invoiceMenuDate,
                        callback: function ($$v) {
                          _vm.invoiceMenuDate = $$v
                        },
                        expression: "invoiceMenuDate",
                      },
                    },
                    [
                      _c(
                        VDatePicker,
                        {
                          attrs: {
                            "no-title": "",
                            color: "#182879",
                            scrollable: "",
                          },
                          model: {
                            value: _vm.invoiceDateExternal,
                            callback: function ($$v) {
                              _vm.invoiceDateExternal = $$v
                            },
                            expression: "invoiceDateExternal",
                          },
                        },
                        [
                          _c(VSpacer),
                          _c(
                            VBtn,
                            {
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.invoiceMenuDate = false
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            VBtn,
                            {
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.invoiceMenuDate.save(
                                    _vm.invoiceDateExternal
                                  )
                                },
                              },
                            },
                            [_vm._v(" OK ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.invoiceDraftPreviewDetailsExternal
            ? _c("div", { staticClass: "py-4 f-14" }, [
                _vm.invoiceDraftPreviewDetailsExternalLineItems.length > 0
                  ? _c(
                      "div",
                      [
                        _c(
                          VRow,
                          { staticClass: "px-0 inv-items-header color-dgray" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pl-6 pr-0 py-2 width-perc-7 inv-jb-no-res",
                              },
                              [_vm._v("Job No")]
                            ),
                            _c("div", { staticClass: "d-flex width-perc-90" }, [
                              _c(
                                "div",
                                { staticClass: "py-2 width-perc-9 pl-4" },
                                [_vm._v("Date")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "py-2 width-perc-16 pl-4 d-flex justify-space-between position-relative",
                                },
                                [
                                  _c("div", { staticClass: "ib" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$stringConstants(
                                          "productAndServicesText"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "ib" },
                                    [
                                      _c("div", {
                                        staticStyle: {
                                          width: "40px !important",
                                          height: "1px !important",
                                        },
                                      }),
                                      _c(
                                        VBtn,
                                        {
                                          staticClass:
                                            "f-nn fw-7 mt-0 px-2 ps-refresh-btn",
                                          attrs: {
                                            small: "",
                                            disabled:
                                              _vm.invoiceLoaderExternal ||
                                              _vm.fetchingLineItems,
                                            outlined: "",
                                            loading:
                                              _vm.fetchingLineItems &&
                                              _vm.isAllAddLineItemViewClosed,
                                            title:
                                              "Refresh products and services",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.fetchLineItems()
                                            },
                                          },
                                        },
                                        [_c(VIcon, [_vm._v("mdi-reload")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "py-2 width-perc-18 pl-4" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$stringConstants("descriptionText")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "py-2 width-perc-9 pl-4" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$stringConstants("unitPriceText")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "py-2 width-perc-11 pl-4" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$stringConstants("quantityText"))
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "py-2 width-perc-9 pl-4" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$stringConstants("amountText"))
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "py-2 width-perc-11 pl-4" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$stringConstants("taxText"))
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "py-2 width-perc-13 pl-4" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$stringConstants("accountText"))
                                  ),
                                ]
                              ),
                              _c("div", {
                                staticClass: "py-2 width-perc-5 pl-4",
                              }),
                            ]),
                          ]
                        ),
                        _vm._l(
                          _vm.invoiceLineItemJobNumbersDistinctExternal,
                          function (distinctJobNumber, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "inv-br-btm d-flex pt-7",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "width-perc-7 pl-6 inv-jb-color inv-jb-no-res cursor-pointer",
                                    staticStyle: { "max-height": "50px" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.routeToJobDetails(
                                          _vm.invoiceLineItemsJobIdsExternal[
                                            index
                                          ]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getJobNumberPrefix(
                                            distinctJobNumber
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        _vm.getJobNumberSuffix(
                                          distinctJobNumber
                                        )
                                      ) + " "
                                    ),
                                  ]
                                ),
                                _vm.invoiceDraftPreviewDetailsExternalLineItems
                                  .length > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "inv-mn-items inv-text-sm width-perc-90",
                                      },
                                      [
                                        _vm._l(
                                          _vm.invoiceDraftPreviewDetailsExternalLineItems,
                                          function (lineItem, lineItemIndex) {
                                            return _c(
                                              "div",
                                              { key: lineItemIndex },
                                              [
                                                lineItem.reference ==
                                                distinctJobNumber
                                                  ? _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex px-0 align-center pb-7",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "width-perc-9 fw-5 py-0 pl-4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getDateFormatted(
                                                                      lineItem.date
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "width-perc-16 fw-5 py-0 pl-4",
                                                            },
                                                            [
                                                              _c(
                                                                VAutocomplete,
                                                                {
                                                                  attrs: {
                                                                    items:
                                                                      _vm.invoiceExternalLineItems,
                                                                    "search-input":
                                                                      _vm
                                                                        .invoiceExternalLineItemSearchText[
                                                                        lineItemIndex
                                                                      ],
                                                                    "item-text":
                                                                      "title",
                                                                    loading:
                                                                      _vm.fetchingLineItems &&
                                                                      _vm.isAllAddLineItemViewClosed,
                                                                    disabled:
                                                                      _vm.fetchingLineItems ||
                                                                      _vm.invoiceLoaderExternal,
                                                                    placeholder:
                                                                      _vm.$stringConstants(
                                                                        "invoiceExternalProductSearchTitle"
                                                                      ),
                                                                    title:
                                                                      _vm.$stringConstants(
                                                                        "invoiceExternalProductSearchTitle"
                                                                      ),
                                                                    dense: "",
                                                                    "append-icon":
                                                                      "",
                                                                    "hide-no-data": true,
                                                                    flat: "",
                                                                    solo: "",
                                                                    "return-object":
                                                                      "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    "update:searchInput":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.invoiceExternalLineItemSearchText,
                                                                          lineItemIndex,
                                                                          $event
                                                                        )
                                                                      },
                                                                    "update:search-input":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm.invoiceExternalLineItemSearchText,
                                                                          lineItemIndex,
                                                                          $event
                                                                        )
                                                                      },
                                                                    keyup:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          !$event.type.indexOf(
                                                                            "key"
                                                                          ) &&
                                                                          _vm._k(
                                                                            $event.keyCode,
                                                                            "enter",
                                                                            13,
                                                                            $event.key,
                                                                            "Enter"
                                                                          )
                                                                        ) {
                                                                          return null
                                                                        }
                                                                        return _vm.searchExternalLineItems(
                                                                          lineItemIndex
                                                                        )
                                                                      },
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.updateLineItem(
                                                                          lineItem
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      lineItem.item,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          lineItem,
                                                                          "item",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "lineItem.item",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "width-perc-18 fw-5 py-0 pl-4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    lineItem.description
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "width-perc-9 py-0 pl-4",
                                                            },
                                                            [
                                                              _c(
                                                                VTextField,
                                                                {
                                                                  staticClass:
                                                                    "rmv-prfx-pd",
                                                                  attrs: {
                                                                    min: "0",
                                                                    prefix:
                                                                      _vm.invoiceExternalCurrencySymbol,
                                                                    type: "number",
                                                                    disabled:
                                                                      _vm.fetchingLineItems ||
                                                                      _vm.invoiceLoaderExternal,
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      lineItem
                                                                        .item
                                                                        .unitPrice,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          lineItem.item,
                                                                          "unitPrice",
                                                                          _vm._n(
                                                                            $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "lineItem.item.unitPrice",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "width-perc-11 py-0 pl-4",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "ib",
                                                                },
                                                                [
                                                                  _c(
                                                                    VTextField,
                                                                    {
                                                                      staticClass:
                                                                        "input-with-unit px-0",
                                                                      attrs: {
                                                                        min: "0",
                                                                        type: "number",
                                                                        disabled:
                                                                          _vm.fetchingLineItems ||
                                                                          _vm.invoiceLoaderExternal,
                                                                        outlined:
                                                                          "",
                                                                        dense:
                                                                          "",
                                                                        "hide-details":
                                                                          "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "append",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      class:
                                                                                        "text--disabled align-self-center text-center f-12",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              title:
                                                                                                lineItem.unit
                                                                                                  ? lineItem.unit
                                                                                                  : "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.reduceTextLength(
                                                                                                  lineItem.unit,
                                                                                                  8
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                      model: {
                                                                        value:
                                                                          lineItem.quantity,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              lineItem,
                                                                              "quantity",
                                                                              _vm._n(
                                                                                $$v
                                                                              )
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "lineItem.quantity",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "width-perc-9 py-0 pl-4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.invoiceExternalCurrencySymbol
                                                                  ) +
                                                                  _vm._s(
                                                                    parseFloat(
                                                                      (
                                                                        lineItem
                                                                          .item
                                                                          .unitPrice *
                                                                        lineItem.quantity
                                                                      ).toString()
                                                                    ).toFixed(2)
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "width-perc-11 py-0 pl-4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    lineItem
                                                                      .item
                                                                      .taxRate &&
                                                                      lineItem
                                                                        .item
                                                                        .taxRate
                                                                        .title
                                                                      ? lineItem
                                                                          .item
                                                                          .taxRate
                                                                          .title
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              ),
                                                              lineItem.item
                                                                .title !=
                                                                null &&
                                                              !_vm.isPropertyValid(
                                                                lineItem.item
                                                                  .taxRate
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "position-relative",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "taxMissingInfoIcon cursor-pointer",
                                                                          on: {
                                                                            mouseover:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showMissingInfoWarningTaxRate(
                                                                                  lineItemIndex
                                                                                )
                                                                              },
                                                                            mouseleave:
                                                                              _vm.hideMissingInfoWarningTaxRate,
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "ib",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                VIcon,
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "cursor-pointer",
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "#ff0000 !important",
                                                                                          "vertical-align":
                                                                                            "baseline",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    "v-icon",
                                                                                    _vm.attrs,
                                                                                    false
                                                                                  ),
                                                                                  _vm.on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-information"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "ib",
                                                                              staticStyle:
                                                                                {
                                                                                  "font-size":
                                                                                    "14px",
                                                                                  transform:
                                                                                    "translateY(-2px)",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$stringConstants(
                                                                                      "invoiceExternalDraftMissingInfo"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm.showMissingInfoWarningTaxRateActiveIndex ==
                                                                      lineItemIndex
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "taxMissingInfo",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$stringConstants(
                                                                                      "invoiceExternalDraftTaxRateNotSet"
                                                                                    )
                                                                                  ) +
                                                                                  " " +
                                                                                  _vm._s(
                                                                                    lineItem
                                                                                      .item
                                                                                      .title
                                                                                  ) +
                                                                                  ". " +
                                                                                  _vm._s(
                                                                                    _vm.getMissingInfoWarning()
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "width-perc-13 py-0 pl-4",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    lineItem
                                                                      .item
                                                                      .chartOfAccount &&
                                                                      lineItem
                                                                        .item
                                                                        .chartOfAccount
                                                                        .title
                                                                      ? lineItem
                                                                          .item
                                                                          .chartOfAccount
                                                                          .title
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              ),
                                                              lineItem.item
                                                                .title !=
                                                                null &&
                                                              !_vm.isPropertyValid(
                                                                lineItem.item
                                                                  .chartOfAccount
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "position-relative",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "taxMissingInfoIcon cursor-pointer",
                                                                          on: {
                                                                            mouseover:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.showMissingInfoWarningChartOfAccount = true
                                                                              },
                                                                            mouseleave:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.showMissingInfoWarningChartOfAccount = false
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "ib",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "🛈"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "ib",
                                                                              staticStyle:
                                                                                {
                                                                                  "font-size":
                                                                                    "14px",
                                                                                  transform:
                                                                                    "translateY(-2px)",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$stringConstants(
                                                                                      "invoiceExternalDraftMissingInfo"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm.showMissingInfoWarningChartOfAccount
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "taxMissingInfo",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$stringConstants(
                                                                                      "invoiceExternalDraftChartAccountNotSet"
                                                                                    )
                                                                                  ) +
                                                                                  " " +
                                                                                  _vm._s(
                                                                                    lineItem
                                                                                      .item
                                                                                      .title
                                                                                  ) +
                                                                                  ". " +
                                                                                  _vm._s(
                                                                                    _vm.getMissingInfoWarning()
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "width-perc-5 py-0 pl-4",
                                                            },
                                                            [
                                                              _vm.shouldShowLineItemRemoveButton(
                                                                distinctJobNumber
                                                              )
                                                                ? _c("div", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "cursor-pointer lineitem-remove-btn",
                                                                        attrs: {
                                                                          title:
                                                                            "Remove product",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeExternalLineItem(
                                                                                lineItemIndex
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "CloseButton"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm.invoicesExternalShowAddLineItemView[
                                          index
                                        ]
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "inv-lineitem-add mb-6 ml-4",
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ib width-perc-20 pr-4 vertical-align-top",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "pb-2" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$stringConstants(
                                                                  "productAndServicesText"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(VAutocomplete, {
                                                            attrs: {
                                                              items:
                                                                _vm.invoiceExternalLineItems,
                                                              "search-input":
                                                                _vm
                                                                  .invoiceExternalLineItemSearchText[
                                                                  -1
                                                                ],
                                                              "item-text":
                                                                "title",
                                                              placeholder:
                                                                _vm.$stringConstants(
                                                                  "invoiceExternalProductSearchTitle"
                                                                ),
                                                              title:
                                                                _vm.$stringConstants(
                                                                  "invoiceExternalProductSearchTitle"
                                                                ),
                                                              dense: "",
                                                              "append-icon": "",
                                                              loading:
                                                                _vm.fetchingLineItems,
                                                              disabled:
                                                                _vm.fetchingLineItems ||
                                                                _vm.invoiceLoaderExternal,
                                                              "hide-no-data": true,
                                                              flat: "",
                                                              solo: "",
                                                              "return-object":
                                                                "",
                                                              outlined: "",
                                                              "hide-details":
                                                                "",
                                                            },
                                                            on: {
                                                              "update:searchInput":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.invoiceExternalLineItemSearchText,
                                                                    -1,
                                                                    $event
                                                                  )
                                                                },
                                                              "update:search-input":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.invoiceExternalLineItemSearchText,
                                                                    -1,
                                                                    $event
                                                                  )
                                                                },
                                                              keyup: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                return _vm.searchExternalLineItems(
                                                                  -1
                                                                )
                                                              },
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.updateNewLineItem(
                                                                  _vm.invoicesExternalInitialLineItem
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .invoicesExternalInitialLineItem
                                                                  .item,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.invoicesExternalInitialLineItem,
                                                                    "item",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "invoicesExternalInitialLineItem.item",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ib width-perc-20 pr-4 vertical-align-top",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "pb-2" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$stringConstants(
                                                                  "descriptionText"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "pt-2",
                                                          staticStyle: {
                                                            "min-height":
                                                              "40px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .invoicesExternalInitialLineItem
                                                                  .description
                                                                  ? _vm
                                                                      .invoicesExternalInitialLineItem
                                                                      .description
                                                                  : "None"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ib width-perc-15 pr-4 vertical-align-top",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "pb-2" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$stringConstants(
                                                                  "unitPriceText"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(VTextField, {
                                                        attrs: {
                                                          min: "0",
                                                          prefix:
                                                            _vm.invoiceExternalCurrencySymbol,
                                                          type: "number",
                                                          outlined: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .invoicesExternalInitialLineItem
                                                              .item.unitPrice,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .invoicesExternalInitialLineItem
                                                                .item,
                                                              "unitPrice",
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "invoicesExternalInitialLineItem.item.unitPrice",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ib width-perc-15 pr-4 vertical-align-top",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pb-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$stringConstants(
                                                                      "quantityText"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(VTextField, {
                                                            staticClass:
                                                              "input-with-unit px-0",
                                                            attrs: {
                                                              min: "0",
                                                              type: "number",
                                                              outlined: "",
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "append",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          class:
                                                                            "text--disabled align-self-center text-center f-12",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  title:
                                                                                    _vm
                                                                                      .invoicesExternalInitialLineItem
                                                                                      .unit
                                                                                      ? _vm
                                                                                          .invoicesExternalInitialLineItem
                                                                                          .unit
                                                                                      : "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.reduceTextLength(
                                                                                      _vm
                                                                                        .invoicesExternalInitialLineItem
                                                                                        .unit,
                                                                                      10
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .invoicesExternalInitialLineItem
                                                                  .quantity,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.invoicesExternalInitialLineItem,
                                                                    "quantity",
                                                                    _vm._n($$v)
                                                                  )
                                                                },
                                                              expression:
                                                                "invoicesExternalInitialLineItem.quantity",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ib width-perc-15 pr-4 vertical-align-top",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "pb-2" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$stringConstants(
                                                                  "unit"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(VTextField, {
                                                        attrs: {
                                                          placeholder:
                                                            _vm.$stringConstants(
                                                              "unitPlaceholder"
                                                            ),
                                                          outlined: "",
                                                          dense: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .invoicesExternalInitialLineItem
                                                              .unit,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.invoicesExternalInitialLineItem,
                                                              "unit",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "invoicesExternalInitialLineItem.unit",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ib width-perc-15 pr-4 pl-4 vertical-align-top",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "pb-2" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$stringConstants(
                                                                  "amountText"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "fw-6 pt-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.invoiceExternalCurrencySymbol
                                                              ) +
                                                              _vm._s(
                                                                parseFloat(
                                                                  (
                                                                    _vm
                                                                      .invoicesExternalInitialLineItem
                                                                      .item
                                                                      .unitPrice *
                                                                    _vm
                                                                      .invoicesExternalInitialLineItem
                                                                      .quantity
                                                                  ).toString()
                                                                ).toFixed(2)
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  [
                                                    _c("Button", {
                                                      attrs: {
                                                        variant: "list",
                                                        color: "primary",
                                                        text: "",
                                                        disabled:
                                                          !_vm.canAddNewLineItem,
                                                        textValue: "Add",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addNewLineItem(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      VBtn,
                                                      {
                                                        staticClass:
                                                          "btn-gray-light-bl-text f-nn ml-2",
                                                        attrs: {
                                                          elevation: "0",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.cancelNewLineItemAdd()
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Cancel ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm
                                          .invoicesExternalShowAddLineItemView[
                                          index
                                        ]
                                          ? _c(
                                              "div",
                                              { staticClass: "mb-6 ml-4" },
                                              [
                                                _vm.invoicesExternalShowAddLineItemButton
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("Button", {
                                                          attrs: {
                                                            variant:
                                                              "secondary",
                                                            outlined: "",
                                                            outlineColor:
                                                              "gray",
                                                            textValue: "+ Add",
                                                          },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showAddLineItemView(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        _c(
                          VRow,
                          [
                            _c(
                              VCol,
                              {
                                staticClass: "px-0 pt-8",
                                attrs: { cols: "6" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "fw-7 pb-10 inv-text-xsm color-dgray ltr-spc text-uppercase",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "invoiceExternalDraftDueDate"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  VRow,
                                  [
                                    _c(
                                      VCol,
                                      {
                                        staticClass: "pl-0 pr-0",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          VMenu,
                                          {
                                            ref: "menuDate",
                                            attrs: {
                                              "close-on-content-click": false,
                                              "return-value":
                                                _vm.invoiceDueDateExternal,
                                              transition: "scale-transition",
                                              "offset-y": "",
                                              "min-width": "auto",
                                            },
                                            on: {
                                              "update:returnValue": function (
                                                $event
                                              ) {
                                                _vm.invoiceDueDateExternal =
                                                  $event
                                              },
                                              "update:return-value": function (
                                                $event
                                              ) {
                                                _vm.invoiceDueDateExternal =
                                                  $event
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        VTextField,
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.computedDateFormatted,
                                                                label:
                                                                  _vm.$stringConstants(
                                                                    "invoiceExternalDraftDueDateLabel"
                                                                  ),
                                                                "append-icon":
                                                                  "mdi-calendar",
                                                                readonly: "",
                                                                clearable: "",
                                                                outlined: "",
                                                                dense: "",
                                                              },
                                                              on: {
                                                                "click:append":
                                                                  on.click,
                                                                "click:clear":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.invoiceDueDateExternal =
                                                                      null
                                                                  },
                                                              },
                                                            },
                                                            "v-text-field",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3873091556
                                            ),
                                            model: {
                                              value: _vm.menuDate,
                                              callback: function ($$v) {
                                                _vm.menuDate = $$v
                                              },
                                              expression: "menuDate",
                                            },
                                          },
                                          [
                                            _c(
                                              VDatePicker,
                                              {
                                                attrs: {
                                                  "no-title": "",
                                                  color: "#182879",
                                                  scrollable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.invoiceDueDateExternal,
                                                  callback: function ($$v) {
                                                    _vm.invoiceDueDateExternal =
                                                      $$v
                                                  },
                                                  expression:
                                                    "invoiceDueDateExternal",
                                                },
                                              },
                                              [
                                                _c(VSpacer),
                                                _c(
                                                  VBtn,
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.menuDate = false
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Cancel ")]
                                                ),
                                                _c(
                                                  VBtn,
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$refs.menuDate.save(
                                                          _vm.invoiceDueDateExternal
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" OK ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(VTextarea, {
                                  attrs: {
                                    label: "Instructions",
                                    dense: "",
                                    required: "",
                                    "auto-grow": "",
                                    outlined: "",
                                    rows: "5",
                                    "row-height": "25",
                                  },
                                  model: {
                                    value:
                                      _vm.invoiceDraftPreviewDetailsExternal
                                        .instructions,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invoiceDraftPreviewDetailsExternal,
                                        "instructions",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "invoiceDraftPreviewDetailsExternal.instructions",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(VCol, { attrs: { cols: "1" } }),
                            _c(
                              VCol,
                              {
                                staticClass: "pl-0 pr-0 pt-8",
                                attrs: { cols: "5" },
                              },
                              [
                                _vm.invoiceJobReportUrls.length > 0
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pb-4 inv-text-xsm fw-6 color-dgray ltr-spc text-uppercase",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$stringConstants(
                                                    "invoiceExternalDraftAttachments"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          VRow,
                                          { staticClass: "pb-12 py-0" },
                                          [
                                            _c(
                                              VCol,
                                              {
                                                staticClass: "px-0 pt-1",
                                                attrs: { cols: "12" },
                                              },
                                              _vm._l(
                                                _vm.invoiceJobReportUrls,
                                                function (reportUrl, r) {
                                                  return _c(
                                                    VRow,
                                                    {
                                                      key: r,
                                                      staticClass: "mx-0 px-0",
                                                    },
                                                    [
                                                      _c(
                                                        VCol,
                                                        {
                                                          staticClass:
                                                            "pb-0 px-0",
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "ib fw-7 cursor-pointer attach-crd",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.viewReport(
                                                                      reportUrl.url
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "ib",
                                                                },
                                                                [
                                                                  _c(
                                                                    VIcon,
                                                                    {
                                                                      staticClass:
                                                                        "icon-size",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-file-pdf "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "ib pl-2 attach-crd-ref-txt",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Jobs summary "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "\n                            ib\n                            fw-7\n                            pl-2\n                            cursor-pointer\n                            attach-crd-crs-wd\n                          ",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeAttachment(
                                                                      r
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                VIcon,
                                                                {
                                                                  staticClass:
                                                                    "icon-size",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " mdi-close-circle "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "sidebar-overlay position-relative contactsLinkFormOverlay",
          attrs: { id: "contactsLinkFormOverlay" },
        },
        [
          _vm.linkingContact
            ? _c("InvoicesExternalContactsFlyout", {
                on: {
                  linkingContactDone: function () {
                    return (_vm.linkingContact = false)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }