import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsOwners from '@/components/team-jobs/common/team-jobs-owners.vue';
import TeamJobsScheduledDate from '@/components/team-jobs/common/team-jobs-generic-information/team-jobs-scheduled-date.vue';
import TeamJobsOperation from '@/components/team-jobs/common/team-jobs-operation.vue';
import TeamJobsInstructions from '@/components/team-jobs/common/team-jobs-instructions.vue';
import TeamJobsAttachments from '@/components/team-jobs/common/team-jobs-attachments.vue';
import TeamJobsStatus from '@/components/team-jobs/common/team-jobs-generic-information/team-jobs-status.vue';
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import TeamJobsCancelOrDelete from "@/components/team-jobs/team-jobs-details/team-jobs-delete-cancel.vue";
import TeamJobTags from '@/components/team-jobs/common/team-job-tags.vue';
export default {
  name: "TeamJobsGenericInformation",
  mixins: [TeamJobsComputed],
  components: {
    TeamJobsOwners: TeamJobsOwners,
    TeamJobsScheduledDate: TeamJobsScheduledDate,
    TeamJobsOperation: TeamJobsOperation,
    TeamJobsInstructions: TeamJobsInstructions,
    TeamJobsAttachments: TeamJobsAttachments,
    TeamJobsStatus: TeamJobsStatus,
    TeamJobsCancelOrDelete: TeamJobsCancelOrDelete,
    TeamJobTags: TeamJobTags
  },
  data: function data() {
    return {
      TeamJobStatusType: TeamJobStatusType
    };
  }
};