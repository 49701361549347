import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import $ from "jquery";
import { mapMutations } from "vuex";
import { defaultScrollViewHeight } from '@/utils/uiconstants';
import { ButtonLoadMore } from "@/components/shared/buttons";
import { SkeletonLoader, EllipsisLoader } from "@/components/shared/loaders";
import skeletonLoaderMixin from "@/mixins/skeleton-loader-mixin";
import Observer from '@/mixins/observer';
export default {
  name: "List",
  mixins: [skeletonLoaderMixin, Observer],
  components: {
    ButtonLoadMore: ButtonLoadMore,
    SkeletonLoader: SkeletonLoader,
    EllipsisLoader: EllipsisLoader
  },
  props: {
    containsAction: Boolean,
    listItems: Array,
    externalList: Boolean,
    moduleNamespace: String,
    listHeaderOffset: Number,
    preserveListState: Boolean,
    noHeader: Boolean,
    type: String
  },
  computed: {
    noData: function noData() {
      if (this.moduleNamespace) return this.$store.state[this.moduleNamespace].noData;
      return this.$store.getters.noData;
    },
    listCount: function listCount() {
      if (this.moduleNamespace) return this.$store.state[this.moduleNamespace].listCount || this.$store.state[this.moduleNamespace].responseRowCount;
      return this.$store.getters.listCount;
    },
    listLoading: function listLoading() {
      return this.$store.getters.listLoading;
    },
    isListRequestCancelled: function isListRequestCancelled() {
      return this.$store.getters.isListRequestCancelled;
    },
    offset: function offset() {
      if (this.moduleNamespace) return this.$store.state[this.moduleNamespace].offset;
      return this.$store.getters.offset;
    },
    limit: function limit() {
      if (this.moduleNamespace) return this.$store.state[this.moduleNamespace].limit;
      return this.$store.getters.limit;
    },
    totalRowCount: {
      get: function get() {
        if (this.moduleNamespace) return this.$store.state[this.moduleNamespace].totalRowCount;
        return this.$store.getters.totalRowCount;
      },
      set: function set(newVal) {
        if (this.moduleNamespace) return this.$store.state[this.moduleNamespace].totalRowCount;
        this.$store.state.totalRowCount = newVal;
      }
    },
    loadedListLength: function loadedListLength() {
      return this.listItems.length;
    },
    filtersApplied: function filtersApplied() {
      return this.$store.getters.filtersApplied;
    },
    hasListItemListener: function hasListItemListener() {
      return this.$listeners && 'listItemClick' in this.$listeners;
    },
    isSkeletonLoaderVisible: function isSkeletonLoaderVisible() {
      return this.listLoading && this.listCount == 0 && this.columnWidths.length > 0;
    },
    isLoadMoreVisible: function isLoadMoreVisible() {
      return !this.listLoading && this.listCount !== 0 && this.loadedListLength < this.totalRowCount;
    },
    isEllipsisLoaderVisible: function isEllipsisLoaderVisible() {
      if (this.moduleNamespace) {
        return this.$store.state[this.moduleNamespace].responseRowCount && this.listCount !== 0 && this.loadedListLength < this.totalRowCount;
      }
      return this.listLoading && this.listCount !== 0 && this.loadedListLength < this.totalRowCount;
    },
    shouldListLoadMoreItems: function shouldListLoadMoreItems() {
      if (this.externalList) {
        return this.listCount !== 0 && !(this.listCount < this.limit);
      }
      return this.loadedListLength < this.totalRowCount && this.listCount <= this.limit;
    }
  },
  watch: {
    loadedListLength: function loadedListLength() {
      var _a, _b, _c, _d;
      var target = this.$refs.loadMoreRef;
      if (this.loadedListLength == 0) {
        return (_a = this.observer) === null || _a === void 0 ? void 0 : _a.unobserve(target);
      }
      if (this.externalList) {
        return (_b = this.observer) === null || _b === void 0 ? void 0 : _b.observe(target);
      }
      if (this.loadedListLength === this.totalRowCount) {
        (_c = this.observer) === null || _c === void 0 ? void 0 : _c.unobserve(target);
      } else if (this.loadedListLength < this.totalRowCount) {
        (_d = this.observer) === null || _d === void 0 ? void 0 : _d.observe(target);
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    handleScroll: function handleScroll() {
      if (!this.listLoading && parseInt($(".list-content").innerHeight().toString()) - $(".scroll-view").scrollTop() <= defaultScrollViewHeight) {
        this.$emit('clearListFilterOptions');
        if (this.listCount > 0 && this.loadedListLength < this.totalRowCount) {
          this.loadMoreItems();
        }
      }
    },
    loadMoreItems: function loadMoreItems() {
      var $state;
      if (this.moduleNamespace) {
        $state = this.$store.state[this.moduleNamespace];
      } else {
        $state = this.$store.state;
      }
      if (this.loadedListLength < this.totalRowCount) {
        this.clearFilterOptions();
        $state.offset = $state.offset + $state.listCount || $state.responseRowCount;
        this.$emit('setFilterOptionsAndGetListData', [$state.offset, $state.limit]);
      }
    },
    initializeLoadMoreObserver: function initializeLoadMoreObserver(callback) {
      var target = this.$refs.loadMoreRef;
      this.initializeIntersectionObserver(target, callback);
    },
    scrollToSavedListItem: function scrollToSavedListItem() {
      if (this.preserveListState && this.$store.state.scrollToItem) {
        var listContainer = this.$refs.listScrollView;
        var listHeader = this.$refs.listHeader;
        var listFilters = document.querySelector('.list-filters');
        var listItem = document.querySelector("[data-id=\"".concat(this.$store.state.scrollToItem, "\"]"));
        if (listItem) {
          var scrollBy = listItem.offsetTop - (listHeader.clientHeight + listFilters.clientHeight + listItem.clientHeight - 48);
          listContainer.scrollTo({
            top: scrollBy,
            behavior: 'smooth'
          });
        }
      }
    }
  }),
  mounted: function mounted() {
    // this.totalRowCount = ConstantValues.defaultPageTotalRowCount;
    this.calculateColumnWidths(this.$refs.listHeader);
    this.initializeLoadMoreObserver(this.loadMoreItems);
    this.scrollToSavedListItem();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.preserveListState) {
      this.resetPaging();
    }
  }
};