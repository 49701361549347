import _toConsumableArray from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { Address } from '@/data/models/Address';
import ConstantValues from '@/plugins/constantValues';
import moment from 'moment';
export var state = {
  customersListForInvoiceExternal: [],
  customersSearchTextExternal: '',
  jobsForInvoiceSearchTextExternal: '',
  invoiceLoaderExternal: false,
  selectedCustomerIdForInvoiceExternal: null,
  jobsListForInvoiceExternal: [],
  selectedJobsForInvoiceExternal: [],
  dueDateForInvoiceExternal: null,
  invoiceDraftPreviewDetailsExternal: null,
  invoicesListExternal: [],
  invoiceSearchTextExternal: '',
  draftedInvoiceIdToOpenExternal: null,
  invoiceSelectedTabExternal: 1,
  invoiceDueDateExternal: null,
  invoiceDateExternal: null,
  invoiceLineItemJobNumbersDistinctExternal: [],
  enableExternalInvoicingExternal: false,
  invoiceExternalLineItems: [],
  invoiceLineItemsJobIdsExternal: [],
  linkedContactToExternalService: false,
  invoiceContactCompanyName: '',
  anyLineItemInvalid: true,
  invoiceGenerationInProgressExternal: false,
  invoiceSendExternal: false,
  invoiceExternalLineItemSearchText: [],
  invoiceStatusEnum: {
    draft: 0,
    open: 1,
    paid: 2,
    void: 3
  },
  invoiceStatusTextEnum: {
    0: "Draft",
    1: "Open",
    2: "Paid",
    3: "Void"
  },
  totalExternalInvoicesCount: ConstantValues.defaultPageTotalRowCount,
  invoicesListExternalOffset: ConstantValues.defaultPageOffsetSize,
  invoicesListExternalLimit: ConstantValues.defaultPageLimitSize,
  noExternalListData: false,
  invoicesExternalListCount: ConstantValues.defaultPageListCount,
  invoiceDraftPreviewDetailsExternalLineItems: [],
  invoicesExternalShowAddLineItemView: [],
  invoicesExternalShowAddLineItemButton: true,
  invoicesExternalInitialLineItem: {
    "item": {
      "id": null,
      "title": null,
      "description": null,
      "unitPrice": 0,
      "taxRate": {
        "id": null,
        "title": null,
        "rate": 0
      },
      "chartOfAccount": {
        "id": null,
        "title": null
      }
    },
    "quantity": 0,
    "unit": null,
    "description": "",
    "reference": "",
    "date": ""
  },
  fetchingLineItems: false,
  externalContactRadioGroup: 1,
  invoiceDateFilterApplied: false,
  tempJobId: null,
  customerForInvoicesList: null,
  customersFilterListForInvoicesList: [],
  customersFilterSearchText: null,
  customersListForInvoiceExternalSize: 0,
  customersListForExternalInvoiceLoader: false,
  noData: false,
  listCount: ConstantValues.defaultPageListCount,
  totalRowCount: ConstantValues.defaultPageTotalRowCount,
  offset: ConstantValues.defaultPageOffsetSize,
  limit: ConstantValues.defaultPageLimitSize,
  supplierDetailsForExternalInvoice: {
    name: null,
    emailAddress: null,
    phoneNumber: null,
    address: new Address()
  },
  customerDetailsForExternalInvoice: {
    name: null,
    emailAddress: null,
    phoneNumber: null,
    address: new Address()
  },
  isAllApprovedJobsSelectedExternal: false,
  totalApprovedJobsCountExternal: 0,
  invoicesExternalListFilterTagValue: '',
  invoicesExternalListFilterTagsList: []
};
export var getters = {
  customerBusinessProfileForInvoiceExternal: function customerBusinessProfileForInvoiceExternal(state) {
    return state.customerBusinessProfileForInvoiceExternal;
  },
  customersListForInvoiceExternal: function customersListForInvoiceExternal(state) {
    return state.customersListForInvoiceExternal;
  },
  customersSearchTextExternal: function customersSearchTextExternal(state) {
    return state.customersSearchTextExternal;
  },
  invoiceLoaderExternal: function invoiceLoaderExternal(state) {
    return state.invoiceLoaderExternal;
  },
  selectedCustomerIdForInvoiceExternal: function selectedCustomerIdForInvoiceExternal(state) {
    return state.selectedCustomerIdForInvoiceExternal;
  },
  jobsListForInvoiceExternal: function jobsListForInvoiceExternal(state) {
    return state.jobsListForInvoiceExternal;
  },
  selectedJobsForInvoiceExternal: function selectedJobsForInvoiceExternal(state) {
    return state.selectedJobsForInvoiceExternal;
  },
  dueDateForInvoiceExternal: function dueDateForInvoiceExternal(state) {
    return state.dueDateForInvoiceExternal;
  },
  invoiceDraftPreviewDetailsExternal: function invoiceDraftPreviewDetailsExternal(state) {
    return state.invoiceDraftPreviewDetailsExternal;
  },
  invoicesListExternal: function invoicesListExternal(state) {
    return state.invoicesListExternal;
  },
  invoiceSearchTextExternal: function invoiceSearchTextExternal(state) {
    return state.invoiceSearchTextExternal;
  },
  jobsForInvoiceSearchTextExternal: function jobsForInvoiceSearchTextExternal(state) {
    return state.jobsForInvoiceSearchTextExternal;
  },
  draftedInvoiceIdToOpenExternal: function draftedInvoiceIdToOpenExternal(state) {
    return state.draftedInvoiceIdToOpenExternal;
  },
  invoiceSelectedTabExternal: function invoiceSelectedTabExternal(state) {
    return state.invoiceSelectedTabExternal;
  },
  invoiceDueDateExternal: function invoiceDueDateExternal(state) {
    return state.invoiceDueDateExternal;
  },
  invoiceDateExternal: function invoiceDateExternal(state) {
    return state.invoiceDateExternal;
  },
  invoiceLineItemJobNumbersDistinctExternal: function invoiceLineItemJobNumbersDistinctExternal(state) {
    return state.invoiceLineItemJobNumbersDistinctExternal;
  },
  enableExternalInvoicingExternal: function enableExternalInvoicingExternal(state) {
    return state.enableExternalInvoicingExternal;
  },
  invoiceExternalLineItems: function invoiceExternalLineItems(state) {
    return state.invoiceExternalLineItems;
  },
  invoiceLineItemsJobIdsExternal: function invoiceLineItemsJobIdsExternal(state) {
    return state.invoiceLineItemsJobIdsExternal;
  },
  linkedContactToExternalService: function linkedContactToExternalService(state) {
    return state.linkedContactToExternalService;
  },
  invoiceContactCompanyName: function invoiceContactCompanyName(state) {
    return state.invoiceContactCompanyName;
  },
  anyLineItemInvalid: function anyLineItemInvalid(state) {
    return state.anyLineItemInvalid;
  },
  invoiceGenerationInProgressExternal: function invoiceGenerationInProgressExternal(state) {
    return state.invoiceGenerationInProgressExternal;
  },
  invoiceSendExternal: function invoiceSendExternal(state) {
    return state.invoiceSendExternal;
  },
  invoiceExternalLineItemSearchText: function invoiceExternalLineItemSearchText(state) {
    return state.invoiceExternalLineItemSearchText;
  },
  invoiceStatusEnum: function invoiceStatusEnum(state) {
    return state.invoiceStatusEnum;
  },
  invoiceStatusTextEnum: function invoiceStatusTextEnum(state) {
    return state.invoiceStatusTextEnum;
  },
  totalExternalInvoicesCount: function totalExternalInvoicesCount(state) {
    return state.totalExternalInvoicesCount;
  },
  invoicesListExternalOffset: function invoicesListExternalOffset(state) {
    return state.invoicesListExternalOffset;
  },
  invoicesListExternalLimit: function invoicesListExternalLimit(state) {
    return state.invoicesListExternalLimit;
  },
  noExternalListData: function noExternalListData(state) {
    return state.noExternalListData;
  },
  invoicesExternalListCount: function invoicesExternalListCount(state) {
    return state.invoicesExternalListCount;
  },
  invoicesExternalShowAddLineItemView: function invoicesExternalShowAddLineItemView(state) {
    return state.invoicesExternalShowAddLineItemView;
  },
  invoicesExternalShowAddLineItemButton: function invoicesExternalShowAddLineItemButton(state) {
    return state.invoicesExternalShowAddLineItemButton;
  },
  invoicesExternalInitialLineItem: function invoicesExternalInitialLineItem(state) {
    return state.invoicesExternalInitialLineItem;
  },
  invoiceDraftPreviewDetailsExternalLineItems: function invoiceDraftPreviewDetailsExternalLineItems(state) {
    return state.invoiceDraftPreviewDetailsExternalLineItems;
  },
  fetchingLineItems: function fetchingLineItems(state) {
    return state.fetchingLineItems;
  },
  externalContactRadioGroup: function externalContactRadioGroup(state) {
    return state.externalContactRadioGroup;
  },
  invoiceDateFilterApplied: function invoiceDateFilterApplied(state) {
    return state.invoiceDateFilterApplied;
  },
  tempJobId: function tempJobId(state) {
    return state.tempJobId;
  },
  customerForInvoicesList: function customerForInvoicesList(state) {
    return state.customerForInvoicesList;
  },
  customersFilterListForInvoicesList: function customersFilterListForInvoicesList(state) {
    return state.customersFilterListForInvoicesList;
  },
  customersFilterSearchText: function customersFilterSearchText(state) {
    return state.customersFilterSearchText;
  },
  customersListForInvoiceExternalSize: function customersListForInvoiceExternalSize(state) {
    return state.customersListForInvoiceExternalSize;
  },
  customersListForExternalInvoiceLoader: function customersListForExternalInvoiceLoader(state) {
    return state.customersListForExternalInvoiceLoader;
  },
  supplierDetailsForExternalInvoice: function supplierDetailsForExternalInvoice(state) {
    return state.supplierDetailsForExternalInvoice;
  },
  customerDetailsForExternalInvoice: function customerDetailsForExternalInvoice(state) {
    return state.customerDetailsForExternalInvoice;
  },
  isAllApprovedJobsSelectedExternal: function isAllApprovedJobsSelectedExternal(state) {
    return state.isAllApprovedJobsSelectedExternal;
  },
  totalApprovedJobsCountExternal: function totalApprovedJobsCountExternal(state) {
    return state.totalApprovedJobsCountExternal;
  },
  invoicesExternalListFilterTagValue: function invoicesExternalListFilterTagValue(state) {
    return state.invoicesExternalListFilterTagValue;
  },
  invoicesExternalListFilterTagsList: function invoicesExternalListFilterTagsList(state) {
    return state.invoicesExternalListFilterTagsList;
  }
};
export var mutations = {
  setCustomersListForInvoiceExternal: function setCustomersListForInvoiceExternal(state, data) {
    data.map(function (customer) {
      return state.customersListForInvoiceExternal.push(customer);
    });
    state.customersListForInvoiceExternal.map(function (customer) {
      if (customer.businessProfile && customer.businessProfile.title) {
        customer['displayName'] = customer.businessProfile.title;
      } else {
        customer['displayName'] = customer.firstName;
      }
    });
  },
  setJobsListForInvoiceExternal: function setJobsListForInvoiceExternal(state, data) {
    data.map(function (job) {
      return state.jobsListForInvoiceExternal.push(job);
    });
  },
  setInvoicesListExternal: function setInvoicesListExternal(state, data) {
    data.map(function (invoice) {
      state.invoicesListExternal.push(invoice);
    });
  },
  setInvoicePreviewDetailsExternal: function setInvoicePreviewDetailsExternal(state, data) {
    state.invoiceDraftPreviewDetailsExternal = data;
    state.invoiceDraftPreviewDetailsExternalLineItems = data.lineItems;
    state.invoiceLineItemsJobIdsExternal = data.jobIds;
    if (state.invoiceDraftPreviewDetailsExternalLineItems.length > 0) {
      for (var i = 0; i < state.invoiceDraftPreviewDetailsExternalLineItems.length; i++) {
        if (state.invoiceDraftPreviewDetailsExternalLineItems[i].item == null) {
          state.invoiceDraftPreviewDetailsExternalLineItems[i].item = {
            id: null,
            title: null,
            description: null,
            unitPrice: 0,
            taxRate: {
              id: null,
              title: null,
              rate: 0
            },
            chartOfAccount: {
              id: null,
              title: null
            }
          };
        }
      }
      state.invoiceLineItemJobNumbersDistinctExternal = _toConsumableArray(new Set(state.invoiceDraftPreviewDetailsExternalLineItems.map(function (item) {
        return item.reference;
      })));
      state.invoicesExternalShowAddLineItemView = [];
      state.invoiceLineItemJobNumbersDistinctExternal.forEach(function () {
        state.invoicesExternalShowAddLineItemView.push(false);
      });
    }
    if (data.dueDate != null) {
      state.invoiceDueDateExternal = moment.utc(data.dueDate).local().format('YYYY-MM-DD');
    }
    if (data.date != null) {
      state.invoiceDateExternal = moment.utc(data.date).local().format('YYYY-MM-DD');
    }
    state.draftedInvoiceIdToOpenExternal = state.invoiceDraftPreviewDetailsExternal.id;
  },
  setInvoiceExternalLineItems: function setInvoiceExternalLineItems(state, data) {
    var externalLineItems = data.value;
    if (Array.isArray(externalLineItems) && externalLineItems.length) {
      externalLineItems.forEach(function (lineItem) {
        state.invoiceExternalLineItems.push(lineItem);
      });
    }
  },
  setLinkedContactInfo: function setLinkedContactInfo(state, data) {
    if (data) {
      if (state.invoiceDraftPreviewDetailsExternal) {
        state.invoiceDraftPreviewDetailsExternal.contactId = data.id;
        state.invoiceDraftPreviewDetailsExternal.contactName = data.name;
      }
      state.invoiceContactCompanyName = data.companyName ? data.companyName : data.name ? data.name : '';
    }
  },
  setClearInvoiceDataExternal: function setClearInvoiceDataExternal(state) {
    state.customersListForInvoiceExternal = [];
    state.customersSearchTextExternal = '';
    state.jobsForInvoiceSearchTextExternal = '';
    state.invoiceLoaderExternal = false;
    state.selectedCustomerIdForInvoiceExternal = null;
    state.jobsListForInvoiceExternal = [];
    state.selectedJobsForInvoiceExternal = [];
    state.dueDateForInvoiceExternal = null;
    state.invoiceDraftPreviewDetailsExternal = null;
    state.invoiceSearchTextExternal = '';
    state.draftedInvoiceIdToOpenExternal = null;
    state.invoiceSelectedTabExternal = 1;
    state.selectedCustomerIdForInvoiceSendXeroExternal = null;
    state.selectedCustomerIdForInvoiceSendQuickBooksExternal = null;
    state.invoiceLineItemJobNumbersDistinctExternal = [];
    state.invoiceLineItemsJobIdsExternal = [];
    state.invoiceSendExternal = false;
  },
  setNoExternalListDataStatus: function setNoExternalListDataStatus(state, data) {
    state.noExternalListData = data != null && data.length == 0;
    state.noData = (data === null || data === void 0 ? void 0 : data.length) == 0;
  },
  setCustomersListFilterForInvoices: function setCustomersListFilterForInvoices(state, data) {
    function pushAllOptionToCustomersList() {
      state.customersFilterListForInvoicesList.push({
        businessName: 'All',
        id: null
      });
    }
    function pushCustomerToCustomersList(customer) {
      state.customersFilterListForInvoicesList.push({
        id: customer.id,
        isInternal: customer.isInternal,
        firstName: customer.firstName,
        businessName: customer.businessProfile ? customer.businessProfile.title : '',
        email: customer.email
      });
    }
    if (state.customersFilterListForInvoicesList.length == 0 || state.customersFilterListForInvoicesList && state.customersFilterListForInvoicesList.filter(function (cus) {
      return cus.id == null;
    }).length == 0) {
      pushAllOptionToCustomersList();
    }
    var customers = data;
    customers.forEach(function (customer) {
      pushCustomerToCustomersList(customer);
    });
  },
  setSupplierDetailsForExternalInvoice: function setSupplierDetailsForExternalInvoice(state, data) {
    state.supplierDetailsForExternalInvoice.name = data.businessProfile.title ? data.businessProfile.title : '';
    state.supplierDetailsForExternalInvoice.emailAddress = data.email;
    state.supplierDetailsForExternalInvoice.phoneNumber = data.primaryContactNumber;
    if (data.businessProfile != null) {
      state.supplierDetailsForExternalInvoice.address = new Address().setResponseAddress(data.businessProfile.address);
    }
  },
  setCustomerDetailsForExternalInvoice: function setCustomerDetailsForExternalInvoice(state, data) {
    state.customerDetailsForExternalInvoice.name = data.businessProfile.title ? data.businessProfile.title : '';
    state.customerDetailsForExternalInvoice.emailAddress = data.email;
    state.customerDetailsForExternalInvoice.phoneNumber = data.primaryContactNumber;
    if (data.businessProfile != null) {
      state.customerDetailsForExternalInvoice.address = new Address().setResponseAddress(data.businessProfile.address);
    }
  }
};