import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.some.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.dom-collections.for-each.js";
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { TeamJobMetaData } from '@/data/models/TeamJobMetaData';
import { mapMutations } from 'vuex';
import { TeamJobOperatorChecklistResourceType } from '@/enum/teamJobOperatorChecklistResourceType';
import { getChecklistItemFromResource, getPageParam } from '@/utils/helpers/jobs-helpers';
import { TeamJobProduct } from '@/data/models/TeamJobProduct';
import { htmlToText, listContainsItem, notify } from '@/utils/helpers';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import { MachineType } from '@/enum/machineType';
import LoadMoreDropdown from "@/components/shared/load-more-jobs-dropdown.vue";
import ConstantValues from '@/plugins/constantValues';
export default {
  name: "TeamJobsOperation",
  mixins: [TeamJobsComputed, TeamJobsCommon],
  components: {
    LoadMoreDropdown: LoadMoreDropdown
  },
  props: {
    minimal: Boolean
  },
  data: function data() {
    return {
      searchTimer: null
    };
  },
  watch: {
    teamJobOperation: function teamJobOperation(newOperation, prevOperation) {
      var _a, _b, _c;
      if (this.teamJobOperation && newOperation && prevOperation && ((_a = newOperation.billingQuantities) === null || _a === void 0 ? void 0 : _a.length) > 0 && ((_b = prevOperation.billingQuantities) === null || _b === void 0 ? void 0 : _b.length) > 0 && JSON.stringify(newOperation.billingQuantities) !== JSON.stringify(prevOperation.billingQuantities) && prevOperation.billingQuantities[0].billingQuantity > 0) {
        if (this.teamJobOperation && ((_c = this.teamJobOperation.billingQuantities) === null || _c === void 0 ? void 0 : _c.length) > 0) {
          this.teamJobOperation.billingQuantities[0].billingQuantity = prevOperation.billingQuantities[0].billingQuantity;
        }
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFilterOptions: "setClearFilterOptions"
  })), {}, {
    applySearchJobOperationTimeout: function applySearchJobOperationTimeout(e) {
      var _this = this;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(function () {
        _this.searchTeamJobOperations();
      }, ConstantValues.defaultSearchTimeout);
    },
    searchTeamJobOperations: function searchTeamJobOperations() {
      var _this2 = this;
      this.clearFilterOptions();
      this.teamJobOperationsList = [];
      this.$store.dispatch('setTeamJobOperationsFilters').then(function (res) {
        if (res != false) {
          _this2.$store.dispatch('getTeamJobOperations');
          if (_this2.teamJobOperationsSearchText == '') {
            _this2.clearFilterOptions();
          }
        }
      });
    },
    productExistsInProductsList: function productExistsInProductsList(productId) {
      return listContainsItem(this.teamJobProducts, [ConstantValues.defaultObjectIdentifier], productId);
    },
    handleOperationInstructionsChange: function handleOperationInstructionsChange(newOperationData) {
      var newOperationDescText = ((newOperationData === null || newOperationData === void 0 ? void 0 : newOperationData.description) || '').trim();
      var previousOperationDescText = (this.previousOperationDescription || '').trim();
      var currentInstructionsText = htmlToText(this.teamJobInstructions || '');
      if (!newOperationDescText) {
        this.previousOperationDescription = newOperationData === null || newOperationData === void 0 ? void 0 : newOperationData.description;
        this.isExternalInstructionsUpdate = false;
        return;
      }
      var isInitialState = !this.teamJobInstructions || currentInstructionsText === '';
      var matchesPrevious = currentInstructionsText === previousOperationDescText;
      var shouldUpdateInstructions = isInitialState || matchesPrevious;
      if (shouldUpdateInstructions) {
        var processedInstructions = this.autoLinkUrls(newOperationDescText);
        if (processedInstructions !== this.teamJobInstructions) {
          this.isExternalInstructionsUpdate = true;
          this.teamJobInstructions = processedInstructions;
          this.tempTeamJobInstructions = processedInstructions;
        } else {
          this.isExternalInstructionsUpdate = false;
        }
      } else {
        this.isExternalInstructionsUpdate = false;
      }
      this.previousOperationDescription = newOperationData === null || newOperationData === void 0 ? void 0 : newOperationData.description;
    },
    handleMetaDataChange: function handleMetaDataChange() {
      var _this3 = this;
      this.teamJobMetaDataList = this.teamJobMetaDataList.filter(function (x) {
        return x.value != null;
      });
      this.teamJobOperation.loadInformationList.forEach(function (loadInfo) {
        if (!_this3.teamJobMetaDataList.some(function (x) {
          return x.title == loadInfo.title;
        })) {
          _this3.teamJobMetaDataList.push(new TeamJobMetaData().getTeamJobMetaData(loadInfo));
        }
      });
    },
    handleProductChange: function handleProductChange(data) {
      var _this4 = this;
      var _a;
      this.teamJobProducts = this.teamJobProducts.filter(function (p) {
        var _a;
        return (_a = p.usages[0]) === null || _a === void 0 ? void 0 : _a.usage;
      });
      if ((_a = data === null || data === void 0 ? void 0 : data.products) === null || _a === void 0 ? void 0 : _a.length) {
        data.products.forEach(function (product) {
          var newProduct = new TeamJobProduct().getTeamJobProduct(product);
          if (!_this4.teamJobProducts.some(function (p) {
            return p.title == product.title;
          })) {
            _this4.teamJobProducts.push(newProduct);
          }
        });
      }
    },
    filterResourcesByOperation: function filterResourcesByOperation() {
      var _this5 = this;
      this.teamJobOperationsSearchText = null;
      if (this.teamJobOperation) {
        this.handleMetaDataChange();
        this.$store.dispatch('getTeamJobOperationResource', this.teamJobOperation.id).then(function (res) {
          var _a, _b, _c, _d, _e, _f, _g, _h, _j;
          if (((_a = res === null || res === void 0 ? void 0 : res.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
            notify(_this5.$stringConstants('teamJobOperationNotFoundWarning'), 'fail');
          } else {
            var data = res.data;
            _this5.handleOperationInstructionsChange(data);
            _this5.handleProductChange(data);
            if (((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.vehicles) && ((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.vehicles) === null || _d === void 0 ? void 0 : _d.length)) {
              _this5.$store.commit('setTeamJobOperatorMachinesDropdown', {
                machineType: MachineType.Vehicle,
                machinesList: ((_e = res === null || res === void 0 ? void 0 : res.data) === null || _e === void 0 ? void 0 : _e.vehicles) || []
              });
            }
            if (((_f = res === null || res === void 0 ? void 0 : res.data) === null || _f === void 0 ? void 0 : _f.implements) && ((_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.implements) === null || _h === void 0 ? void 0 : _h.length)) {
              _this5.$store.commit('setTeamJobOperatorMachinesDropdown', {
                machineType: MachineType.Implement,
                machinesList: ((_j = res === null || res === void 0 ? void 0 : res.data) === null || _j === void 0 ? void 0 : _j.implements) || []
              });
            }
          }
        });
        if (getPageParam() != 'add') {
          this.updateTeamJobOperatorsChecklist();
        }
        this.enableTeamJobDiscard();
      } else {
        this.applySearchJobOperationTimeout();
      }
    },
    updateTeamJobOperatorsChecklist: function updateTeamJobOperatorsChecklist() {
      var _this6 = this;
      if (this.teamJobOperators) {
        this.teamJobOperators.forEach(function (operator) {
          _this6.replaceOperatorChecklistByOperation(operator.operatorDetails, TeamJobOperatorChecklistResourceType.Operation);
        });
        var tempOperatorsList = this.teamJobOperators;
        this.teamJobOperators = [];
        this.teamJobOperators = tempOperatorsList;
      }
    },
    replaceOperatorChecklistByOperation: function replaceOperatorChecklistByOperation(operator, resourceTypeOperation) {
      if (this.teamJobDetails && this.teamJobDetails.status == TeamJobStatusType.Completed) {
        if (operator && operator.checklists) {
          operator.checklists = [];
        }
      } else {
        if (operator.checklists) {
          var existingOperationChecklists = operator.checklists.find(function (cl) {
            return cl.resourceType == resourceTypeOperation;
          });
          if (existingOperationChecklists) {
            operator.checklists = operator.checklists.filter(function (cl) {
              return cl.resourceType != resourceTypeOperation;
            });
          }
        }
        var newOperationChecklistItem = getChecklistItemFromResource(this.teamJobOperation, resourceTypeOperation);
        if (newOperationChecklistItem) {
          operator.checklists.push(newOperationChecklistItem);
        }
      }
    },
    refeatchOperationsList: function refeatchOperationsList() {
      this.hasMoreData = true;
      if (this.teamJobOperationsSearchText != null && (this.teamJobOperation == null || this.teamJobOperation.id == null)) {
        this.teamJobOperationsSearchText = null;
        this.searchTeamJobOperations();
      }
    },
    appendMoreOperations: function appendMoreOperations() {
      var _this7 = this;
      this.clearFilterOptions();
      this.$store.dispatch('setTeamJobOperationsFilters').then(function () {
        _this7.$store.dispatch('getTeamJobOperations');
      });
    }
  })
};