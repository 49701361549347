import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.object.to-string.js";
import $ from "jquery";
import { openOverlay } from "@/utils/helpers";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissions-mixin";
import scrollerMixin from "@/mixins/scroller-mixin";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import Snackbar from "@/components/shared/snackbar/snackbar-listing.vue";
import productsComputedMixin from "@/components/products/products-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { listAction } from '@/enum/listAction';
import { DeleteItemDialog as ItemDialog, ActionListingDialog } from "@/components/shared/dialogs/index";
import { ViewPreferenceType } from '@/enum/viewPreferenceType';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/timers';
import { TabFilterType } from "@/enum/tabFilterType";
export default {
  name: "ProductsListView",
  mixins: [permissionsMixin, scrollerMixin, filterParamsMixin, productsComputedMixin, Timers],
  components: {
    Snackbar: Snackbar,
    ActionListingDialog: ActionListingDialog,
    Button: Button,
    ItemDialog: ItemDialog,
    List: List
  },
  watch: {
    selectedItems: function selectedItems(val) {
      if (this.selectedItems.length > 0) {
        this.snackbarShow = true;
      } else {
        this.snackbarShow = false;
        this.isChecked = false;
      }
    },
    productStatus: function productStatus() {
      this.$store.commit('clearSnackbarDetails');
    }
  },
  data: function data() {
    return {
      dialogProduct: false,
      deleteProductId: null,
      isChecked: false,
      actionType: listAction.Delete,
      listAction: listAction,
      ViewPreferenceType: ViewPreferenceType,
      searchTimer: null
    };
  },
  created: function created() {
    this.TabFilterType = TabFilterType;
  },
  beforeMount: function beforeMount() {
    this.clearAndResetProductsPagingStates();
  },
  beforeDestroy: function beforeDestroy() {
    this.clearAndResetProductsPagingStates();
    this.$store.commit('clearSnackbarDetails');
  },
  mounted: function mounted() {
    if (!this.$store.state.isPageRelated) {
      localStorage.removeItem('productSearchQuery');
      localStorage.removeItem('productFilterType');
    }
    this.getUrlParamsProductLocal();
    this.setFilterOptionsAndGetProducts();
    this.$store.dispatch("getProductUnits");
  },
  computed: {
    someProductsSelected: function someProductsSelected() {
      return this.selectedItems.length > 0 && !this.allProductsSelected;
    },
    allProductsSelected: function allProductsSelected() {
      if (this.productSearchText) {
        return false;
      } else {
        return this.selectedItems.length == this.productsList.length;
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearProductDetailsState: "resetProductDetails",
    clearProductFilterOption: "setClearFilterOptions",
    resetPaging: "resetPaging",
    resetSnackbarDetails: "clearSnackbarDetails",
    resetImportForm: "resetImportDataDetails"
  })), {}, {
    openAddProductForm: function openAddProductForm() {
      this.clearProductDetailsState();
      openOverlay("editProductFormOverlay");
    },
    openProductEditForm: function openProductEditForm(id) {
      this.clearProductDetailsState();
      var $this = this.$store;
      this.resetSnackbarDetails();
      $this.dispatch("getProductDetails", id);
      openOverlay("editProductFormOverlay");
    },
    fnGetHeaderString: function fnGetHeaderString(actionType) {
      switch (actionType) {
        case listAction.Delete:
          return this.$stringConstants('productDeletePromptTitle');
        case listAction.Archive:
          return this.$stringConstants('productArchivePromptTitle');
        case listAction.Restore:
          return this.$stringConstants('productRestorePromptTitle');
      }
    },
    fnGetBodyString: function fnGetBodyString(actionType) {
      switch (actionType) {
        case listAction.Delete:
          return this.$stringConstants('productDeletePromptSubtitle');
        case listAction.Archive:
          return this.$stringConstants('productArchivePromptSubtitle');
        case listAction.Restore:
          return this.$stringConstants('productRestorePromptSubtitle');
      }
    },
    confirmActionOnProduct: function confirmActionOnProduct(id, listAction) {
      this.deleteProductId = id;
      this.dialogProduct = true;
      this.actionType = listAction;
    },
    cancelActionOnMachine: function cancelActionOnMachine(actionType) {
      this.dialogProduct = false;
      this.deleteProductId = "";
    },
    proceedActionOnMachine: function proceedActionOnMachine(actionType) {
      this.dialogProduct = false;
      this.resetSnackbarDetails();
      this.$store.dispatch("actionOnProduct", [actionType, this.deleteProductId]);
    },
    setUrlParamsProductLocal: function setUrlParamsProductLocal() {
      this.setLocalParamsMx(this.productSearchText, "productSearchQuery");
      this.setLocalParamsMx(this.productStatus, "productFilterType");
    },
    getUrlParamsProductLocal: function getUrlParamsProductLocal() {
      this.productSearchText = this.getLocalParamsMx("productSearchQuery", "");
      this.productStatus = parseInt(this.getLocalParamsMx("productFilterType", 0));
    },
    removeLocalProductsParams: function removeLocalProductsParams() {
      this.productSearchText = this.removeLocalParamsMx("productSearchQuery", null);
    },
    applySearchProductFilter: function applySearchProductFilter() {
      this.clearProductFilterOption();
      this.resetSnackbarDetails();
      this.resetPaging();
      this.productsList = [];
      this.filtersApplied = true;
      this.setUrlParamsProductLocal();
      this.setFilterOptionsAndGetProducts();
    },
    getProductListByStatus: function getProductListByStatus() {
      this.applySearchProductFilter();
    },
    clearAndResetProductsPagingStates: function clearAndResetProductsPagingStates() {
      $(window).unbind("scroll");
      this.$store.state.productsModule.productsList = [];
      this.clearProductFilterOption();
      this.resetPaging();
      this.resetSnackbarDetails();
      this.filtersApplied = false;
    },
    seedProducts: function seedProducts() {
      for (var i = 1; i < 400; i++) {
        var productObj = {
          title: "PRODUCT_" + i,
          cost: i,
          unit: i % 2 == 0 ? "Metre" : "Kg"
        };
        this.$store.dispatch("addNewProduct", productObj);
      }
    },
    selectAllProducts: function selectAllProducts() {
      var _this = this;
      this.selectedItems = [];
      this.allItemsDeleted = false;
      if (this.isChecked) {
        this.productsList.map(function (pro) {
          _this.selectedItems.push(pro.id);
        });
      }
    },
    setFilterOptionsAndGetProducts: function setFilterOptionsAndGetProducts() {
      var _this2 = this;
      var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize];
      if (!this.productSearchText) {
        this.clearProductFilterOption();
        this.filtersApplied = false;
      }
      this.$store.dispatch('setProductsListFilter', filter).then(function (res) {
        _this2.$store.dispatch('getProductsListFiltered');
      });
    },
    openImportForm: function openImportForm() {
      this.resetImportForm();
      openOverlay("importProductsFormOverlay");
    }
  })
};