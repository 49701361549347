import _toConsumableArray from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { TeamJob } from '@/data/models/TeamJob';
export var state = {
  selectedProductsForCompleteJob: [],
  productsListForCompleteJob: [],
  productsForCompleteJobSearchText: '',
  isUpdatedCompleteJobStarted: false,
  jobDeclineReason: null,
  jobDetails: {
    id: null,
    createdOn: null,
    lastUpdatedOn: null,
    number: null,
    scheduledAt: null,
    instructions: null,
    notes: null,
    status: null,
    statusChangeHistory: [],
    customer: {
      id: null,
      imageUrl: null,
      name: null,
      businessName: null,
      isInternal: false,
      roles: [],
      ownerId: null
    },
    customerCancellationReason: null,
    contractor: {
      id: null,
      imageUrl: null,
      name: null,
      businessName: null,
      isInternal: false,
      roles: [],
      ownerId: null
    },
    contractorApprovalStatus: null,
    contractorDeclineReason: null,
    operation: null,
    operators: [],
    fields: [],
    products: [],
    timeRecords: [],
    addresses: [],
    attachments: [],
    metaData: [],
    invoiceNumber: null,
    invoiceUrl: null,
    isApprovedToInvoice: false
  },
  jobCurrentStatus: 0,
  isJobStarted: false,
  jobDetailsLoader: false,
  isMethodSubscribed: false,
  isAssigneeMethodSubscribed: false,
  isAssigneeUserSubscribed: false,
  polygonShapes: [],
  assigneeTravelHistory: [],
  updateJobLoader: false,
  updateJobLoaderStatus: 0,
  polylineCords: [],
  pathPolyLineDrawn: null,
  fullPathPolyLineDrawn: null,
  isManualWorkTimeValid: true,
  existingbreakTimeInSeconds: 0,
  existingDownTimeInSeconds: 0,
  jobOperatorLocations: [],
  jobOperatorsProofOfWork: [],
  invoiceReportUrlGenerationInProgress: false,
  invoiceReportGenerationInProgress: false,
  invoiceJobReportUrls: [],
  generatedFromJobDetails: false
};
export var getters = {
  jobDetails: function jobDetails(state) {
    return state.jobDetails;
  },
  jobCurrentStatus: function jobCurrentStatus(state) {
    return state.jobCurrentStatus;
  },
  isJobStarted: function isJobStarted(state) {
    return state.isJobStarted;
  },
  selectedProductsForCompleteJob: function selectedProductsForCompleteJob(state) {
    return state.selectedProductsForCompleteJob;
  },
  productsListForCompleteJob: function productsListForCompleteJob(state) {
    return state.productsListForCompleteJob;
  },
  productsForCompleteJobSearchText: function productsForCompleteJobSearchText(state) {
    return state.productsForCompleteJobSearchText;
  },
  isUpdatedCompleteJobStarted: function isUpdatedCompleteJobStarted(state) {
    return state.isUpdatedCompleteJobStarted;
  },
  jobDetailsLoader: function jobDetailsLoader(state) {
    return state.jobDetailsLoader;
  },
  jobDeclineReason: function jobDeclineReason(state) {
    return state.jobDeclineReason;
  },
  isMethodSubscribed: function isMethodSubscribed(state) {
    return state.isMethodSubscribed;
  },
  isAssigneeUserSubscribed: function isAssigneeUserSubscribed(state) {
    return state.isAssigneeUserSubscribed;
  },
  updateJobLoader: function updateJobLoader(state) {
    return state.updateJobLoader;
  },
  updateJobLoaderStatus: function updateJobLoaderStatus(state) {
    return state.updateJobLoaderStatus;
  },
  polylineCords: function polylineCords(state) {
    return state.polylineCords;
  },
  pathPolyLineDrawn: function pathPolyLineDrawn(state) {
    return state.pathPolyLineDrawn;
  },
  fullPathPolyLineDrawn: function fullPathPolyLineDrawn(state) {
    return state.fullPathPolyLineDrawn;
  },
  isManualWorkTimeValid: function isManualWorkTimeValid(state) {
    return state.isManualWorkTimeValid;
  },
  existingbreakTimeInSeconds: function existingbreakTimeInSeconds(state) {
    return state.existingbreakTimeInSeconds;
  },
  existingDownTimeInSeconds: function existingDownTimeInSeconds(state) {
    return state.existingDownTimeInSeconds;
  },
  jobOperatorLocations: function jobOperatorLocations(state) {
    return state.jobOperatorLocations;
  },
  jobOperatorIds: function jobOperatorIds(state) {
    return state.jobOperatorIds;
  },
  jobOperatorsProofOfWork: function jobOperatorsProofOfWork(state) {
    return state.jobOperatorsProofOfWork;
  },
  invoiceReportUrlGenerationInProgress: function invoiceReportUrlGenerationInProgress(state) {
    return state.invoiceReportUrlGenerationInProgress;
  },
  invoiceJobReportUrls: function invoiceJobReportUrls(state) {
    return state.invoiceJobReportUrls;
  },
  invoiceReportGenerationInProgress: function invoiceReportGenerationInProgress(state) {
    return state.invoiceReportGenerationInProgress;
  },
  generatedFromJobDetails: function generatedFromJobDetails(state) {
    return state.generatedFromJobDetails;
  }
};
export var mutations = {
  setJobDetails: function setJobDetails(state, data) {
    state.jobDetails = new TeamJob().getTeamJob(data);
    state.jobOperatorIds = [];
    if (state.jobDetails.operators && state.jobDetails.operators.length) {
      state.jobDetails.operators.forEach(function (operator) {
        state.jobOperatorIds.push(operator.id);
      });
    }
    state.jobOperatorIds = _toConsumableArray(new Set(state.jobOperatorIds));
  },
  setJobOperatorLocations: function setJobOperatorLocations(state, operatorLocations) {
    state.jobOperatorLocations = operatorLocations;
  },
  setJobsDetailsInvoice: function setJobsDetailsInvoice(state, data) {
    state.jobDetails = new TeamJob().getTeamJob(data);
  },
  setProductsListForCompleteJob: function setProductsListForCompleteJob(state, data) {
    data.map(function (data) {
      return state.productsListForCompleteJob.push(data);
    });
  },
  setInvoiceJobReportUrls: function setInvoiceJobReportUrls(state, data) {
    var obj = {
      reference: data[1],
      url: data[0]
    };
    state.invoiceJobReportUrls.push(obj);
  },
  setInvoiceJobReportSummaryUrls: function setInvoiceJobReportSummaryUrls(state, _ref) {
    var reference = _ref.reference,
      url = _ref.url;
    state.invoiceJobReportUrls = [];
    state.invoiceJobReportUrls.push({
      reference: reference,
      url: url
    });
  },
  resetJobDetails: function resetJobDetails(state) {
    state.jobDetails = new TeamJob();
    state.jobOperatorLocations = [];
    state.jobOperatorIds = [];
    state.jobOperatorsProofOfWork = [];
    state.invoiceReportUrlGenerationInProgress = false;
    state.invoiceReportGenerationInProgress = false;
    state.invoiceJobReportUrls = [];
    state.generatedFromJobDetails = false;
  }
};