import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import { listAction } from '@/enum/listAction';
import { ViewPreferenceType } from '@/enum/viewPreferenceType';
import { UserRoleType } from '@/enum/userRoleType';
export default {
  name: 'SnackBarList',
  props: ['items', 'deleteApiType', 'allItemsSelected', 'someItemsSelected', 'status', 'showOnlyDelete', 'showArchiveOrDelete', 'showRoleChange', 'change-role-callback', 'showUpdateButton', 'update-items-callback', 'integrationType', 'loaderState'],
  data: function data() {
    return {
      timeout: -1,
      listAction: listAction,
      ViewPreferenceType: ViewPreferenceType,
      UserRoleType: UserRoleType
    };
  },
  watch: {
    snackbarShow: function snackbarShow(snackBarToggleVisibility) {
      if (snackBarToggleVisibility === false) {
        this.clearAllSelected();
      }
    },
    selectedItems: function selectedItems(val) {
      var _this = this;
      if (this.someItemsSelected && this.isAllItemsSelected && this.items.length > 0) {
        this.unselectedItems = this.items.filter(function (item) {
          return !_this.selectedItems.includes(item.id);
        }).map(function (item) {
          return item.id;
        });
      } else {
        this.unselectedItems = [];
      }
    }
  },
  computed: {
    snackbarShow: {
      get: function get() {
        return this.$store.getters.snackbarShow;
      },
      set: function set(newVal) {
        this.$store.state.snackbarShow = newVal;
      }
    },
    allItemsDeleted: {
      get: function get() {
        return this.$store.getters.allItemsDeleted;
      },
      set: function set(newVal) {
        this.$store.state.allItemsDeleted = newVal;
      }
    },
    allItemsRestored: {
      get: function get() {
        return this.$store.getters.allItemsRestored;
      },
      set: function set(newVal) {
        this.$store.state.allItemsRestored = newVal;
      }
    },
    allItemsArchived: {
      get: function get() {
        return this.$store.getters.allItemsArchived;
      },
      set: function set(newVal) {
        this.$store.state.allItemsArchived = newVal;
      }
    },
    allItemsApproved: {
      get: function get() {
        return this.$store.getters.allItemsApproved;
      },
      set: function set(newVal) {
        this.$store.state.allItemsApproved = newVal;
      }
    },
    allItemsUnapproved: {
      get: function get() {
        return this.$store.getters.allItemsUnapproved;
      },
      set: function set(newVal) {
        this.$store.state.allItemsUnapproved = newVal;
      }
    },
    allItemsExported: {
      get: function get() {
        return this.$store.getters.allItemsExported;
      },
      set: function set(newVal) {
        this.$store.state.allItemsExported = newVal;
      }
    },
    allItemsMarkedForInvoice: {
      get: function get() {
        return this.$store.getters.allItemsMarkedForInvoice;
      },
      set: function set(newVal) {
        this.$store.state.allItemsMarkedForInvoice = newVal;
      }
    },
    allItemsCancelled: {
      get: function get() {
        return this.$store.getters.allItemsCancelled;
      },
      set: function set(newVal) {
        this.$store.state.allItemsCancelled = newVal;
      }
    },
    selectedItems: {
      get: function get() {
        return this.$store.getters.selectedItems;
      },
      set: function set(newVal) {
        this.$store.state.selectedItems = newVal;
      }
    },
    unselectedItems: {
      get: function get() {
        return this.$store.getters.unselectedItems;
      },
      set: function set(newVal) {
        this.$store.state.unselectedItems = newVal;
      }
    },
    totalRowCount: {
      get: function get() {
        return this.$store.getters.totalRowCount;
      },
      set: function set(newVal) {
        this.$store.state.totalRowCount = newVal;
      }
    },
    listActionType: {
      get: function get() {
        return this.$store.getters.listActionType;
      },
      set: function set(newVal) {
        this.$store.state.listActionType = newVal;
      }
    },
    dialogSelectedRows: {
      get: function get() {
        return this.$store.getters.dialogSelectedRows;
      },
      set: function set(val) {
        this.$store.state.dialogSelectedRows = val;
      }
    },
    archiveDialogSelectedRows: {
      get: function get() {
        return this.$store.getters.archiveDialogSelectedRows;
      },
      set: function set(val) {
        this.$store.state.archiveDialogSelectedRows = val;
      }
    },
    restoreDialogSelectedRows: {
      get: function get() {
        return this.$store.getters.restoreDialogSelectedRows;
      },
      set: function set(value) {
        this.$store.state.restoreDialogSelectedRows = value;
      }
    },
    bulkActionLoader: {
      get: function get() {
        return this.$store.getters.bulkActionLoader;
      },
      set: function set(value) {
        this.$store.state.bulkActionLoader = value;
      }
    },
    ownerRole: {
      get: function get() {
        return localStorage.getItem('ownerRole');
      }
    },
    loginUserRole: {
      get: function get() {
        return this.$store.getters.loginUserRole;
      }
    },
    isAllItemsSelected: {
      get: function get() {
        return this.$store.getters.isAllItemsSelected;
      }
    },
    isContractorOnlyView: {
      get: function get() {
        return this.loginUserRole == UserRoleType.Contractor || this.loginUserRole == UserRoleType.Manager && this.ownerRole == UserRoleType[UserRoleType.Contractor];
      }
    },
    selectedItemsCount: {
      get: function get() {
        if (this.isAllItemsSelected) {
          return this.totalRowCount - this.unselectedItems.length;
        } else {
          return this.totalRowCount;
        }
      }
    }
  },
  methods: {
    selectAllItemsData: function selectAllItemsData() {
      this.allItemsDeleted = true;
      this.allItemsArchived = true;
      this.allItemsRestored = true;
      this.allItemsApproved = true;
      this.allItemsUnapproved = true;
      this.allItemsExported = true;
      this.allItemsMarkedForInvoice = true;
      this.allItemsCancelled = true;
    },
    deleteSectedRows: function deleteSectedRows() {
      this.listActionType = listAction.Delete;
      this.dialogSelectedRows = true;
    },
    unSelectAllItems: function unSelectAllItems() {
      this.allItemsDeleted = false;
      this.allItemsArchived = false;
      this.allItemsRestored = false;
      this.allItemsApproved = false;
      this.allItemsUnapproved = false;
      this.allItemsExported = false;
      this.allItemsMarkedForInvoice = false;
      this.allItemsCancelled = false;
    },
    clearAllSelected: function clearAllSelected() {
      this.selectedItems = [];
      this.unSelectAllItems();
    },
    archiveSelectedRows: function archiveSelectedRows() {
      this.listActionType = listAction.Archive;
      this.dialogSelectedRows = true;
    },
    restoreSelectedRows: function restoreSelectedRows() {
      this.listActionType = listAction.Restore;
      this.dialogSelectedRows = true;
    },
    approveSelectedRows: function approveSelectedRows() {
      this.listActionType = listAction.Approve;
      this.dialogSelectedRows = true;
    },
    unapproveSelectedRows: function unapproveSelectedRows() {
      this.listActionType = listAction.Unapprove;
      this.dialogSelectedRows = true;
    },
    cancelSelectedRows: function cancelSelectedRows() {
      this.listActionType = listAction.Cancel;
      this.dialogSelectedRows = true;
    },
    exportSelectedRows: function exportSelectedRows() {
      this.listActionType = listAction.Export;
      this.dialogSelectedRows = true;
    },
    markAsInvoicedSelectedRows: function markAsInvoicedSelectedRows() {
      this.listActionType = listAction.MarkAsInvoiced;
      this.dialogSelectedRows = true;
    },
    changeRole: function changeRole() {
      this.$emit('change-role-callback');
    },
    updateItems: function updateItems() {
      this.$emit('update-items-callback');
    }
  }
};