import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { mapActions, mapMutations } from "vuex";
import fieldsComputedMixin from "./fields-computed";
import { getAreaUnit, getConvertedAreaUnit, getShortName, getDateTimeFormattedLocal } from "@/utils/helpers";
import { TeamJobStatusType } from "@/enum/teamJobStatusType";
import { routeTeamJobsDetails } from "@/utils/endpoints";
import CustomerAvatar from "@/components/shared/avatars/avatar.vue";
import { BaseButton as Button } from "@/components/shared/buttons";
import filtersParamsMixin from "@/mixins/filter-params-mixin";
import { DeleteItemDialog } from "@/components/shared/dialogs";
export default {
  name: "FieldDetails",
  mixins: [fieldsComputedMixin, filtersParamsMixin],
  components: {
    CustomerAvatar: CustomerAvatar,
    Button: Button,
    DeleteItemDialog: DeleteItemDialog
  },
  data: function data() {
    return {
      showAddCropHistoryDialog: false,
      files: null,
      areaUnit: getAreaUnit(),
      TeamJobStatusType: TeamJobStatusType,
      recalculateButtonVisible: false,
      routeTeamJobsDetails: routeTeamJobsDetails,
      showAllJobs: false
    };
  },
  computed: {
    fieldNote: function fieldNote() {
      return this.fieldDetails.note;
    },
    jobsList: function jobsList() {
      if (this.showAllJobs) return this.fieldInProgressJobs;
      return this.fieldInProgressJobs.slice(0, 4);
    }
  },
  mounted: function mounted() {
    this.getConvertedArea();
    this.getJobsByField({
      ownerId: this.fieldDetails.owner,
      resourceId: this.fieldDetails.id
    });
    this.$store.commit("setDashboardTitle", this.fieldDetails.title);
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapMutations({
    resetFieldDetails: "resetFieldDetails"
  })), mapActions({
    getJobsByField: "getJobsByField",
    calculateAndSetPolygonArea: "calculateAndSetPolygonArea"
  })), {}, {
    toggleFieldsEditable: function toggleFieldsEditable() {
      if (!this.fieldReadonly) {
        this.editingAreaOnMap = !this.showEditableFields;
      }
      this.showEditableFields = !this.showEditableFields;
    },
    getConvertedArea: function getConvertedArea() {
      if (this.fieldDetails.areaInSquareMeter != null && this.fieldDetails.areaInSquareMeter > 0) {
        this.convertedArea = parseFloat(getConvertedAreaUnit(this.fieldDetails.areaInSquareMeter));
      } else {
        this.convertedArea = parseFloat(getConvertedAreaUnit(this.areaInSquareMeter));
      }
    },
    getOwnerInfo: function getOwnerInfo() {
      var ownerInfo = "";
      if (this.fieldDetails && this.fieldDetails.ownerDetails && this.fieldDetails.ownerDetails.businessProfile && this.fieldDetails.ownerDetails.businessProfile.title) {
        ownerInfo += this.fieldDetails.ownerDetails.businessProfile.title;
      }
      var ownership = this.getOwnership();
      return ownerInfo + " (" + ownership + ")";
    },
    getOwnership: function getOwnership() {
      var _this = this;
      if (this.enumOwnership && this.enumOwnership.length > 0 && this.fieldDetails && this.fieldDetails.ownership != null) {
        return this.enumOwnership.filter(function (ownership) {
          return ownership.value == _this.fieldDetails.ownership;
        })[0].title;
      }
      return "";
    },
    getJobOwnerName: function getJobOwnerName(job) {
      if (job) {
        if (job.customer.businessName) return getShortName(job.customer.businessName, 20);
        if (job.customer.name) return getShortName(job.customer.name, 20);
      }
    },
    getLocalDate: function getLocalDate(dateTime) {
      return getDateTimeFormattedLocal(dateTime);
    },
    showJobsList: function showJobsList() {
      this.showAllJobs = true;
    },
    addNewCrop: function addNewCrop() {
      var _this2 = this;
      this.showAddCropHistoryDialog = false;
      this.$store.dispatch("addFieldCrop", {
        ownerId: this.fieldDetails.owner,
        resourceId: this.fieldDetails.id,
        cropObj: {
          name: this.fieldCropNameToAdd,
          year: this.fieldCropYearToAdd
        }
      }).then(function (res) {
        if (res) {
          _this2.fieldDetails.cropHistory.push({
            name: res.name,
            year: res.year
          });
          _this2.fieldCropNameToAdd = _this2.fieldCropYearToAdd = null;
        }
      });
    },
    getFieldAddress: function getFieldAddress() {
      if (this.fieldDetails.address) {
        var address = "";
        if (this.fieldDetails.address.addressLine1) address += this.fieldDetails.address.addressLine1;
        if (this.fieldDetails.address.addressLine2) address += ", " + this.fieldDetails.address.addressLine2;
        return address;
      }
      return "";
    },
    handleDelete: function handleDelete() {
      this.confirmDeleteField([this.fieldDetails.id, this.fieldDetails.owner]);
    },
    proceedToDelete: function proceedToDelete() {
      this.deleteField(this.cleanUpMapAndFieldFormAfterAction);
    }
  })
};