import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "px-9 pt-10", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  textValue1: _vm.$stringConstants("personalDetailsTitle1"),
                  textValue2: _vm.$stringConstants("personalDetailsTitle2"),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "px-3", attrs: { cols: "7" } },
            [
              _c("div", { staticClass: "field-input-label" }, [
                _vm._v(" " + _vm._s(_vm.$stringConstants("name")) + " * "),
              ]),
              _c(VTextField, {
                staticClass: "formFieldPb",
                attrs: { outlined: "", dense: "" },
                model: {
                  value: _vm.userProfile.firstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.userProfile, "firstName", $$v)
                  },
                  expression: "userProfile.firstName",
                },
              }),
              _c("div", { staticClass: "field-input-label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$stringConstants("emailAddress")) + " * "
                ),
              ]),
              _c(VTextField, {
                class: _vm.formFieldPb,
                attrs: {
                  "error-messages": _vm.generateValidationErrors(),
                  required: "",
                  outlined: "",
                  dense: "",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.userProfile.email.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.userProfile.email.$touch()
                  },
                },
                model: {
                  value: _vm.userProfile.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.userProfile, "email", $$v)
                  },
                  expression: "userProfile.email",
                },
              }),
              _vm.tempEmailChangeText
                ? _c("div", { staticClass: "my-2 email-change-text" }, [
                    _vm._v(" " + _vm._s(_vm.tempEmailChangeText) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }