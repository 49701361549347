import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c("snackbarDelete", {
        attrs: {
          deleteApiType: "timesheets",
          showOnlyDelete: "",
          allItemsSelected: _vm.allTimesheetsSelected,
          someItemsSelected: _vm.someTimesheetsSelected,
          items: _vm.timesheetsList,
        },
      }),
      _c("ActionListingDialog", {
        attrs: {
          itemName: "timesheets",
          onClickEvent: "proccedToActionOnTimesheets",
          itemsPreference: 0,
        },
      }),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogTimeSheet },
        on: {
          cancel: function ($event) {
            _vm.dialogTimeSheet = false
          },
          proceed: _vm.proceedTimeSheetDeletion,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("timeSheetDeletePromtTitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("timeSheetDeletePromtSubtitle")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        VRow,
        {
          staticClass: "flex-grow-0 px-9 pt-8",
          attrs: { "no-gutters": "", cols: "12" },
        },
        [
          _c(
            VCol,
            { attrs: { cols: "9" } },
            [
              _c(VRow, [
                _c(
                  "div",
                  { staticClass: "ib px-0" },
                  [
                    _c(VAutocomplete, {
                      staticClass: "rd-rt-no mr-4",
                      attrs: {
                        items: _vm.timesheetsMembersList,
                        "item-text": "name",
                        "item-value": "id",
                        placeholder: "Search member",
                        disabled: _vm.timesheetsFilterLoader,
                        outlined: "",
                        dense: "",
                        "background-color": "white",
                        "append-icon": "mdi-chevron-down",
                      },
                      on: {
                        focus: function ($event) {
                          return _vm.updateSearchMemberText($event)
                        },
                        change: function ($event) {
                          return _vm.getMemberTimesheet(
                            _vm.timesheetsSelectedMemberId
                          )
                        },
                      },
                      nativeOn: {
                        input: function ($event) {
                          return _vm.updateSearchMemberText($event)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function (ref) {
                            var item = ref.item
                            var selected = ref.selected
                            return [
                              _c("span", {
                                attrs: { "input-value": selected },
                                domProps: { textContent: _vm._s(item.name) },
                              }),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.timesheetsSelectedMemberId,
                        callback: function ($$v) {
                          _vm.timesheetsSelectedMemberId = $$v
                        },
                        expression: "timesheetsSelectedMemberId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ib px-0" },
                  [
                    _c(VSelect, {
                      staticClass: "rd-lf-no agd-dropdown mr-4",
                      attrs: {
                        items: _vm.timesheetsFilterTypeList,
                        "item-value": "value",
                        "item-text": "title",
                        placeholder: "Search by type",
                        disabled: _vm.timesheetsFilterLoader,
                        dense: "",
                        outlined: "",
                        "append-icon": "mdi-chevron-down",
                      },
                      on: { change: _vm.applySearchTimeSheetFilter },
                      model: {
                        value: _vm.timesheetListFilterType,
                        callback: function ($$v) {
                          _vm.timesheetListFilterType = $$v
                        },
                        expression: "timesheetListFilterType",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "ib px-0" }, [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "ib timeSheet_date" },
                      [
                        _c("datePicker", {
                          ref: "datePicker",
                          class: _vm.timesheetDateFilterApplied ? "active" : "",
                          attrs: {
                            dateInput: _vm.dateInput,
                            format: _vm.format,
                            sameDateFormat: _vm.sameDateFormat,
                            "switch-button-initial": true,
                            "show-helper-buttons": false,
                            language: "en",
                          },
                          on: {
                            "date-applied": _vm.filterTimesheetByDateRange,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.timesheetDateFilterApplied
                      ? _c("div", { staticClass: "ib" }, [
                          _c(
                            "div",
                            {
                              staticClass: "jb-clr",
                              staticStyle: {
                                transform: "translate(10px, -2px)",
                              },
                              on: { click: _vm.clearTimesheetDateFilter },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v(" mdi-close-thick "),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm.noData &&
              !_vm.filtersApplied &&
              _vm.timesheetsList.length == 0
                ? _c(
                    VRow,
                    [
                      _c(VCol, { staticClass: "col-6 pl-0" }, [
                        _c("div", [
                          _c("h4", { staticClass: "mb-0" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$stringConstants(
                                  "timeSheetZeroStateHeading"
                                )
                              )
                            ),
                          ]),
                          _c("p", { staticClass: "pt-2" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("timeSheetZeroState"))
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.noData && _vm.filtersApplied
                ? _c("div", [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$stringConstants("errorForWrongSearch"))
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c(
                VRow,
                { staticClass: "text-right" },
                [
                  _c(
                    VCol,
                    { staticClass: "pa-0", attrs: { cols: "12" } },
                    [
                      _c("Button", {
                        attrs: {
                          variant: "secondary",
                          color: "primary",
                          outlined: "",
                          outlineColor: "primary",
                          textValue: _vm.$stringConstants("addNewText"),
                          leftIcon: "mdi-plus",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.openAddTimeListForm()
                          },
                        },
                      }),
                      _c("Button", {
                        staticClass: "ml-2",
                        attrs: {
                          variant: "secondary",
                          color: "tertiary",
                          textValue: "Download",
                          disabled:
                            _vm.timesheetsLoader ||
                            _vm.timesheetsList.length == 0 ||
                            _vm.noData,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.initiateTimesheetExport($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.noData
        ? _c(
            VRow,
            { staticClass: "px-9 timesheet-card flex-grow-0" },
            [
              _c(VCol, { staticClass: "pa-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "timesheet-card-box color-dg position-relative",
                  },
                  [
                    _c("div", { staticClass: "py-4 count-sptr" }, [
                      _c("div", [
                        _c("div", { staticClass: "ib" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/total-time.svg"),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "ib v-a-t" }, [
                          _c("div", { staticClass: "count-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$stringConstants("totalTimesheetsText")
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "count-num pt-1" },
                        [
                          _vm.timesheetsList.length > 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.timesheetsStatistics.timesheetsCount
                                  )
                                ),
                              ])
                            : _c(VSkeletonLoader, {
                                attrs: {
                                  type: "list-item",
                                  height: "36",
                                  width: "72",
                                  "max-width": "100",
                                },
                              }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
              _c(VCol, { staticClass: "py-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "timesheet-card-box color-lg position-relative",
                  },
                  [
                    _c("div", { staticClass: "py-4 count-sptr" }, [
                      _c("div", [
                        _c("div", { staticClass: "ib" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/total-hours.svg"),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "ib v-a-t" }, [
                          _c("div", { staticClass: "count-title" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("totalHoursText"))
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "count-num pt-1" },
                        [
                          _vm.timesheetsList.length > 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getTotalAvgHours(
                                      _vm.timesheetsStatistics
                                        .totalTimeInMinutes
                                    )
                                  )
                                ),
                              ])
                            : _c(VSkeletonLoader, {
                                attrs: {
                                  type: "list-item",
                                  height: "36",
                                  width: "72",
                                  "max-width": "100",
                                },
                              }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
              _c(VCol, { staticClass: "py-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "timesheet-card-box color-lb position-relative",
                  },
                  [
                    _c("div", { staticClass: "py-4 count-sptr" }, [
                      _c("div", [
                        _c("div", { staticClass: "ib" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/wkn-hours.svg"),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "ib v-a-t" }, [
                          _c("div", { staticClass: "count-title" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("workingHoursText"))
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "count-num pt-1" },
                        [
                          _vm.timesheetsList.length > 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getTotalAvgHours(
                                      _vm.timesheetsStatistics
                                        .workingTimeInMinutes
                                    )
                                  )
                                ),
                              ])
                            : _c(VSkeletonLoader, {
                                attrs: {
                                  type: "list-item",
                                  height: "36",
                                  width: "72",
                                  "max-width": "100",
                                },
                              }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
              _c(VCol, { staticClass: "py-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "timesheet-card-box color-lr position-relative",
                  },
                  [
                    _c("div", { staticClass: "py-4 count-sptr" }, [
                      _c("div", [
                        _c("div", { staticClass: "ib" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/brk-time.svg"),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "ib v-a-t" }, [
                          _c("div", { staticClass: "count-title" }, [
                            _vm._v(
                              _vm._s(_vm.$stringConstants("breakTimeHoursText"))
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "count-num pt-1" },
                        [
                          _vm.timesheetsList.length > 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getTotalAvgHours(
                                      _vm.timesheetsStatistics
                                        .breakTimeInMinutes
                                    )
                                  )
                                ),
                              ])
                            : _c(VSkeletonLoader, {
                                attrs: {
                                  type: "list-item",
                                  height: "36",
                                  width: "72",
                                  "max-width": "100",
                                },
                              }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
              _c(VCol, { staticClass: "py-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "timesheet-card-box color-tt position-relative",
                  },
                  [
                    _c("div", { staticClass: "py-4" }, [
                      _c("div", [
                        _c("div", { staticClass: "ib" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/walk-time.svg"),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "ib v-a-t" }, [
                          _c("div", { staticClass: "count-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$stringConstants("travelTimeHoursText")
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "count-num pt-1" },
                        [
                          _vm.timesheetsList.length > 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getTotalAvgHours(
                                      _vm.timesheetsStatistics
                                        .travelTimeInMinutes
                                    )
                                  )
                                ),
                              ])
                            : _c(VSkeletonLoader, {
                                attrs: {
                                  type: "list-item",
                                  height: "36",
                                  width: "72",
                                  "max-width": "100",
                                },
                              }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("List", {
        attrs: { listItems: _vm.timesheetsList, containsAction: "" },
        on: {
          setFilterOptionsAndGetListData: _vm.setFilterOptionsAndGetTimesheets,
        },
        scopedSlots: _vm._u([
          {
            key: "list-heading",
            fn: function () {
              return [
                _c("div", { staticClass: "width-perc-15 list-check" }, [
                  _c(
                    "div",
                    { staticClass: "ib" },
                    [
                      _c(VCheckbox, {
                        attrs: {
                          indeterminate: _vm.someTimesheetsSelected,
                          "input-value": _vm.allTimesheetsSelected,
                        },
                        on: { change: _vm.selectAllTimesheets },
                        model: {
                          value: _vm.isChecked,
                          callback: function ($$v) {
                            _vm.isChecked = $$v
                          },
                          expression: "isChecked",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ib v-a-t" }, [
                    _vm._v(_vm._s(_vm.$stringConstants("memberText"))),
                  ]),
                ]),
                _c("div", { staticClass: "width-perc-10" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("dateText"))),
                ]),
                _c("div", { staticClass: "width-perc-12" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("workTime"))),
                ]),
                _c("div", { staticClass: "width-perc-12" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("workHours"))),
                ]),
                _c("div", { staticClass: "width-perc-10" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("breakTime"))),
                ]),
                _c("div", { staticClass: "width-perc-11" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("travelTime"))),
                ]),
                _c("div", { staticClass: "width-perc-12" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("noteText"))),
                ]),
                _c("div", { staticClass: "width-perc-7" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("jobsText"))),
                ]),
                _c("div", { staticClass: "text-right width-perc-10" }, [
                  _vm._v(_vm._s(_vm.$stringConstants("action"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "list-item",
            fn: function (ref) {
              var listItem = ref.listItem
              return [
                _c("div", { staticClass: "list-check width-perc-15" }, [
                  _c(
                    "div",
                    { staticClass: "ib" },
                    [
                      _c(VCheckbox, {
                        attrs: { value: listItem.id },
                        model: {
                          value: _vm.selectedItems,
                          callback: function ($$v) {
                            _vm.selectedItems = $$v
                          },
                          expression: "selectedItems",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ib v-a-t" }, [
                    _vm._v(_vm._s(listItem.name)),
                  ]),
                ]),
                _c("div", { staticClass: "width-perc-10" }, [
                  _vm._v(_vm._s(_vm.getDateFormatted(listItem.startTime))),
                ]),
                listItem.type == _vm.TimeSheetType.Working
                  ? _c("div", { staticClass: "width-perc-12 pr-4" }, [
                      _vm._v(
                        _vm._s(_vm.getTimeInitials(listItem.startTime)) +
                          " - " +
                          _vm._s(_vm.getTimeInitials(listItem.endTime))
                      ),
                    ])
                  : _vm._e(),
                listItem.type == _vm.TimeSheetType.Holiday
                  ? _c("div", { staticClass: "width-perc-12" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("holidayText"))),
                    ])
                  : _vm._e(),
                listItem.type == _vm.TimeSheetType.Sick
                  ? _c("div", { staticClass: "width-perc-12" }, [
                      _vm._v(_vm._s(_vm.$stringConstants("sickText"))),
                    ])
                  : _vm._e(),
                listItem.type == _vm.TimeSheetType.Working
                  ? _c("div", { staticClass: "width-perc-12" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getTimeInHoursAndMins(listItem.workTimeInMinutes)
                        )
                      ),
                    ])
                  : _c("div", { staticClass: "width-perc-12 grey--text" }, [
                      _vm._v("N/A"),
                    ]),
                _c("div", { staticClass: "lst-br-r width-perc-10" }, [
                  listItem.breakTimeInMinutes > 0
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.getTimeInHoursAndMins(
                              listItem.breakTimeInMinutes
                            )
                          )
                        ),
                      ])
                    : _c("span", { staticClass: "grey--text" }, [
                        _vm._v("N/A"),
                      ]),
                ]),
                _c("div", { staticClass: "lst-br-r width-perc-11" }, [
                  listItem.travelTimeInMinutes > 0
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.getTimeInHoursAndMins(
                              listItem.travelTimeInMinutes
                            )
                          )
                        ),
                      ])
                    : _c("span", { staticClass: "grey--text" }, [
                        _vm._v("N/A"),
                      ]),
                ]),
                _c("div", { staticClass: "width-perc-12" }, [
                  listItem.note != null && listItem.note.length != 0
                    ? _c(
                        "div",
                        [
                          _c(
                            VTooltip,
                            {
                              attrs: {
                                bottom: "",
                                color: "#000",
                                "max-width": "300px",
                                "content-class": "rounded-0",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b({}, "span", attrs, false),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getShortNote(
                                                    listItem.note
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", { staticClass: "tol-cont" }, [
                                _vm._v(" " + _vm._s(listItem.note) + " "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "grey--text" }, [_vm._v("N/A")]),
                ]),
                !(listItem.type == 0 || listItem.type == 1)
                  ? _c(
                      "div",
                      {
                        staticClass: "theme-color cursor-pointer width-perc-7",
                        on: {
                          click: function ($event) {
                            return _vm.openJobList(
                              listItem.startTime,
                              listItem.userId
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$stringConstants("showJobsText")))]
                    )
                  : _c("div", { staticClass: "width-perc-7" }, [
                      _c("span", { staticClass: "grey--text" }, [
                        _vm._v(" N/A "),
                      ]),
                    ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-right pr-4 position-relative width-perc-10",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "pt-1" },
                      [
                        _c(
                          VMenu,
                          {
                            attrs: { bottom: "", "min-width": "120", left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "triple-dot-menu",
                                              attrs: { icon: "" },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-dots-vertical"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              VList,
                              [
                                _c(
                                  VListItem,
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.openTimeSheetEditForm(
                                          listItem.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      VListItemTitle,
                                      [
                                        _c(
                                          VIcon,
                                          { staticClass: "icon-size pr-2" },
                                          [_vm._v("mdi-pencil")]
                                        ),
                                        _vm._v("Edit "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  VListItem,
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmDeleteTimeSheet(
                                          listItem.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      VListItemTitle,
                                      [
                                        _c(
                                          VIcon,
                                          { staticClass: "icon-size pr-2" },
                                          [_vm._v("mdi-delete")]
                                        ),
                                        _vm._v("Delete "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "zero-state",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "460" },
          model: {
            value: _vm.showTimesheetExportDialog,
            callback: function ($$v) {
              _vm.showTimesheetExportDialog = $$v
            },
            expression: "showTimesheetExportDialog",
          },
        },
        [
          _c(
            VCard,
            { staticClass: "dlt-diag py-5" },
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("timeSheetExportTitle")) +
                    " "
                ),
              ]),
              _c(
                VCardText,
                [
                  _c(VProgressCircular, {
                    staticStyle: {
                      transform: "translateY(-2px) translateX(-2px)",
                    },
                    attrs: {
                      indeterminate: "",
                      width: "2",
                      size: "18",
                      color: "#b3b3b3",
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$stringConstants("timeSheetExportMessage")) +
                      " (" +
                      _vm._s(_vm.timesheetDataListLoadedCount) +
                      " / " +
                      _vm._s(_vm.timesheetDataListTotalCount) +
                      ") "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }