import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "b-btm py-5 px-9" },
        [
          _c(
            VRow,
            { staticClass: "fn-20", attrs: { "no-gutters": "", cols: "12" } },
            [
              _c(
                VCol,
                { attrs: { cols: "8" } },
                [_c("HeaderPrimary", { attrs: { textValue: "Download" } })],
                1
              ),
              _c(
                VCol,
                { staticClass: "text-right", attrs: { cols: "4" } },
                [
                  _c(
                    VIcon,
                    {
                      staticClass: "oy-cl",
                      attrs: { small: "", id: "closeJobsExportForm" },
                      on: { click: _vm.closeJobsExportForm },
                    },
                    [_vm._v(" mdi-close-thick ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VContainer,
        {
          staticClass: "pb-0 pt-7",
          class: [_vm.containerLeftPad, _vm.containerRightPad],
          staticStyle: { "overflow-y": "scroll", "max-height": "90vh" },
        },
        [
          _c(
            "div",
            { staticClass: "pb-2" },
            [
              _c("SubHeaderPrimary", {
                attrs: { textValue: "Select columns to export" },
              }),
            ],
            1
          ),
          _vm.jobExportLoading
            ? _c("div", { staticClass: "text-center" }, [_c("ListLoader")], 1)
            : _c("div", [
                _vm.exportableColumns.length == 0
                  ? _c("div", [
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v("No columns available to export"),
                      ]),
                    ])
                  : _c(
                      "div",
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "pt-1 pb-3 position-relative" },
                              [
                                _c("DescriptionPrimary", {
                                  attrs: { textValue: "Title" },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "action-btn-pos",
                                    on: {
                                      click: function ($event) {
                                        return _vm.createNewJobExportProfile()
                                      },
                                    },
                                  },
                                  [_vm._v(" Save This Config ")]
                                ),
                              ],
                              1
                            ),
                            _c(VTextField, {
                              attrs: {
                                placeholder: "Enter the title",
                                outlined: "",
                                dense: "",
                              },
                              model: {
                                value: _vm.jobExportConfigTitle,
                                callback: function ($$v) {
                                  _vm.jobExportConfigTitle = $$v
                                },
                                expression: "jobExportConfigTitle",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "pt-1 pb-3" },
                              [
                                _c("DescriptionPrimary", {
                                  attrs: {
                                    textValue: "Work/Travel/Break Time in",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(VSelect, {
                              attrs: {
                                items: _vm.timeUnitTypes,
                                "item-value": "value",
                                "item-text": "title",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: _vm.exportableColumnTimeType,
                                callback: function ($$v) {
                                  _vm.exportableColumnTimeType = $$v
                                },
                                expression: "exportableColumnTimeType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "export-column-select-all" },
                          [
                            _c(VCheckbox, {
                              staticStyle: { "margin-top": "0 !important" },
                              attrs: {
                                label: "Select all",
                                color: "primary",
                                "hide-details": "",
                              },
                              on: { change: _vm.handleSelectAll },
                              model: {
                                value: _vm.isAllExportableColumnsSelected,
                                callback: function ($$v) {
                                  _vm.isAllExportableColumnsSelected = $$v
                                },
                                expression: "isAllExportableColumnsSelected",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.exportableColumns,
                          function (column, columnIndex) {
                            return _c(
                              "div",
                              { key: columnIndex },
                              [
                                _c(VCheckbox, {
                                  attrs: {
                                    label: column.title,
                                    color: "primary",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: column.isSelected,
                                    callback: function ($$v) {
                                      _vm.$set(column, "isSelected", $$v)
                                    },
                                    expression: "column.isSelected",
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
              ]),
          _c("div", { staticClass: "pt-14", staticStyle: { height: "100px" } }),
        ]
      ),
      _c(
        "div",
        { staticClass: "btns-bottom-box px-8 py-4 text-center" },
        [
          _c("Button", {
            attrs: {
              variant: "primary",
              color: "primary",
              disabled: _vm.jobExportLoading,
              loading: _vm.jobExportLoading,
              textValue: "Download",
              rightIcon: "mdi-arrow-right",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.exportJobs()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }