import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export var state = {
  jobsCalendarData: [],
  jobsCalendarStartDate: [],
  jobsCalendarEndDate: [],
  jobsCalendarLoader: false,
  jobsUnscheduledJobsData: [],
  jobsUnscheduledJobsSearchText: ''
};
export var getters = {
  jobsCalendarData: function jobsCalendarData(state) {
    return state.jobsCalendarData;
  },
  jobsCalendarStartDate: function jobsCalendarStartDate(state) {
    return state.jobsCalendarStartDate;
  },
  jobsCalendarEndDate: function jobsCalendarEndDate(state) {
    return state.jobsCalendarEndDate;
  },
  jobsCalendarLoader: function jobsCalendarLoader(state) {
    return state.jobsCalendarLoader;
  },
  jobsUnscheduledJobsData: function jobsUnscheduledJobsData(state) {
    return state.jobsUnscheduledJobsData;
  },
  jobsUnscheduledJobsSearchText: function jobsUnscheduledJobsSearchText(state) {
    return state.jobsUnscheduledJobsSearchText;
  }
};
export var mutations = {
  setJobsCalendarData: function setJobsCalendarData(state, data) {
    data.forEach(function (job) {
      return state.jobsCalendarData.push(job);
    });
  },
  resetJobsCalendarData: function resetJobsCalendarData(state) {
    state.jobsCalendarData = [];
    state.jobsCalendarStartDate = null;
    state.jobsCalendarEndDate = null;
    state.jobsCalendarLoader = false;
  }
};