import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c("Snackbar", {
        attrs: {
          deleteApiType: "farmers",
          status: _vm.farmerStatus,
          allItemsSelected: _vm.allFarmersSelected,
          someItemsSelected: _vm.someFarmersSelected,
          showArchiveOrDelete: true,
          showUpdateButton: true,
          integrationType: _vm.integrationType,
          loaderState: _vm.farmersLoader,
          items: _vm.farmersFilterList,
        },
        on: { "update-items-callback": _vm.updateCustomers },
      }),
      _c("ActionListingDialog", {
        attrs: {
          itemName: "Farmers",
          onClickEvent: "proceedToActionOnFarmers",
          itemsPreference: this.farmerStatus,
        },
      }),
      _c(
        VRow,
        {
          staticClass: "list-filters flex-grow-0 px-9 pt-4",
          attrs: { "no-gutters": "", cols: "12" },
        },
        [
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(
                VRow,
                { staticStyle: { "max-width": "500px" } },
                [
                  _c(
                    VCol,
                    { staticClass: "px-0", attrs: { cols: "9" } },
                    [
                      _c(VTextField, {
                        staticClass: "agd-txtfield mr-4",
                        attrs: {
                          placeholder: _vm.$stringConstants(
                            "searchCustomerPlaceholder"
                          ),
                          outlined: "",
                          autocomplete: "search-customer",
                          "prepend-inner-icon": "mdi-magnify",
                          clearable: "",
                          dense: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.applySearchTimer(
                              _vm.applySearchFarmerFilter
                            )
                          },
                        },
                        model: {
                          value: _vm.farmerSearchText,
                          callback: function ($$v) {
                            _vm.farmerSearchText = $$v
                          },
                          expression: "farmerSearchText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "px-0", attrs: { cols: "3" } },
                    [
                      _c(VSelect, {
                        staticClass: "rd-lf-no agd-dropdown",
                        attrs: {
                          items: _vm.enumActiveStatus,
                          "item-value": "value",
                          "item-text": "title",
                          placeholder:
                            _vm.$stringConstants("statusPlaceholder"),
                          dense: "",
                          outlined: "",
                          "append-icon": "mdi-chevron-down",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.getCustomersListByStatus()
                          },
                        },
                        model: {
                          value: _vm.farmerStatus,
                          callback: function ($$v) {
                            _vm.farmerStatus = $$v
                          },
                          expression: "farmerStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "6" } },
            [
              _c(
                VRow,
                { staticClass: "text-right" },
                [
                  _c(
                    VCol,
                    { staticClass: "pt-3 px-0", attrs: { cols: "12" } },
                    [
                      _c("Button", {
                        staticClass: "mx-3",
                        attrs: {
                          variant: "secondary",
                          outlined: "",
                          outlineColor: "gray",
                          textValue: "Manage",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.openManageFarmerForm()
                          },
                        },
                      }),
                      _c(
                        VMenu,
                        {
                          attrs: {
                            "content-class": "mt-1",
                            "offset-y": "",
                            bottom: "",
                            "min-width": "120",
                            left: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c("Button", {
                                    staticClass: "mr-3",
                                    attrs: {
                                      variant: "secondary",
                                      outlined: "",
                                      outlineColor: "gray",
                                      on: on,
                                      attrs: attrs,
                                      textValue: "Import",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm.loginUserRole == _vm.UserRoleType.Contractor
                            ? _c(
                                VList,
                                {
                                  staticClass: "import-menu",
                                  attrs: { disabled: _vm.importButtonDisabled },
                                },
                                [
                                  _vm.integrationType == "quickbooks"
                                    ? _c(
                                        VListItem,
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.getContactsListForImport()
                                            },
                                          },
                                        },
                                        [
                                          _c(VListItemTitle, [
                                            _c("div", { staticClass: "fw-6" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$stringConstants(
                                                    "farmerImportQuickbooksText"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm.integrationType == "xero"
                                    ? _c(
                                        VListItem,
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.getContactsListForImport()
                                            },
                                          },
                                        },
                                        [
                                          _c(VListItemTitle, [
                                            _c("div", { staticClass: "fw-6" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$stringConstants(
                                                    "farmerImportXeroText"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm.integrationType == "sage"
                                    ? _c(
                                        VListItem,
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.getContactsListForImport()
                                            },
                                          },
                                        },
                                        [
                                          _c(VListItemTitle, [
                                            _c("div", { staticClass: "fw-6" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$stringConstants(
                                                    "farmerImportSageText"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _c(
                                        VListItem,
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.integrateDialog = true
                                            },
                                          },
                                        },
                                        [
                                          _c(VListItemTitle, [
                                            _c("div", { staticClass: "fw-6" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$stringConstants(
                                                    "farmerImportIntegrationsText"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                  _c(
                                    VListItem,
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openInitiateImporterForm()
                                        },
                                      },
                                    },
                                    [
                                      _c(VListItemTitle, [
                                        _c("div", { staticClass: "fw-6" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "farmerImportFileText"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                VList,
                                {
                                  staticClass: "import-menu",
                                  attrs: { disabled: _vm.importButtonDisabled },
                                },
                                [
                                  _c(
                                    VListItem,
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openInitiateImporterForm()
                                        },
                                      },
                                    },
                                    [
                                      _c(VListItemTitle, [
                                        _c("div", { staticClass: "fw-6" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "farmerImportFileText"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c(
                        VMenu,
                        {
                          attrs: {
                            "content-class": "mt-1",
                            "offset-y": "",
                            bottom: "",
                            "min-width": "120",
                            left: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c("Button", {
                                    attrs: {
                                      variant: "secondary",
                                      outlined: "",
                                      outlineColor: "primary",
                                      on: on,
                                      attrs: attrs,
                                      color: "primary",
                                      text: "",
                                      textValue: "Add",
                                      leftIcon: "mdi-plus",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            VList,
                            [
                              _c(
                                VListItem,
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openAddFarmerForm()
                                    },
                                  },
                                },
                                [
                                  _c(VListItemTitle, [
                                    _c("div", { staticClass: "fw-6" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$stringConstants(
                                            "farmerInviteText"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _vm.shouldComponentDisplay(
                                _vm.componentsPermissions["createContact"]
                              )
                                ? _c(
                                    VListItem,
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openAddContact()
                                        },
                                      },
                                    },
                                    [
                                      _c(VListItemTitle, [
                                        _c("div", { staticClass: "fw-6" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$stringConstants(
                                                "farmerAddCustomerText"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.farmerConReqList.length > 0 && _vm.isShowConReq
        ? _c("div", [_c("farmersConnectionRequest")], 1)
        : _vm._e(),
      _vm.farmerFilterType == 0
        ? _c("List", {
            attrs: {
              listItems: _vm.farmersFilterList,
              containsAction: "",
              preserveListState: "",
            },
            on: {
              setFilterOptionsAndGetListData: _vm.getFarmersListView,
              listItemClick: _vm.openContactDetailsForm,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "list-heading",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "list-check width-perc-27" }, [
                        _c(
                          "div",
                          { staticClass: "ib" },
                          [
                            _c(VCheckbox, {
                              attrs: {
                                indeterminate: _vm.someFarmersSelected,
                                "input-value": _vm.allFarmersSelected,
                              },
                              on: { change: _vm.selectAllFarmers },
                              model: {
                                value: _vm.isChecked,
                                callback: function ($$v) {
                                  _vm.isChecked = $$v
                                },
                                expression: "isChecked",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "ib v-a-t pl-2" }, [
                          _vm._v(_vm._s(_vm.$stringConstants("businessName"))),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "width-perc-21 item-list-name-res " },
                        [_vm._v(_vm._s(_vm.$stringConstants("emailText")))]
                      ),
                      _c("div", { staticClass: "width-perc-21" }, [
                        _vm._v(_vm._s(_vm.$stringConstants("phoneText"))),
                      ]),
                      _c("div", { staticClass: "width-perc-21" }, [
                        _vm._v(_vm._s(_vm.$stringConstants("customerName"))),
                      ]),
                      _c("div", { staticClass: "width-perc-10 text-right" }, [
                        _vm._v(_vm._s(_vm.$stringConstants("action"))),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "list-item",
                  fn: function (ref) {
                    var listItem = ref.listItem
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "list-check width-perc-27 center-text-y",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ib" },
                            [
                              _c(VCheckbox, {
                                attrs: { value: listItem.id, multiple: "" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                                model: {
                                  value: _vm.selectedItems,
                                  callback: function ($$v) {
                                    _vm.selectedItems = $$v
                                  },
                                  expression: "selectedItems",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ib v-a-t pl-2" }, [
                            listItem.businessProfile != null
                              ? _c(
                                  "div",
                                  { staticClass: "fw-6 d-flex align-center" },
                                  [
                                    !listItem.isInternal
                                      ? _c("Avatar", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            initials:
                                              listItem.businessProfile.title,
                                            width: "28px",
                                            height: "28px",
                                          },
                                        })
                                      : _c("AvatarDisabled", {
                                          staticClass: "mr-2",
                                        }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "d-inline-block pl-1 pr-8",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(listItem.businessProfile.title)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", { staticClass: "fw-6" }, [
                                  _vm._v(" N/A "),
                                ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "width-perc-21 item-list-name-res " },
                        [
                          _c("div", { staticClass: "center-text-y" }, [
                            _vm._v(
                              _vm._s(listItem.email ? listItem.email : "-")
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "width-perc-21" }, [
                        _c("div", { staticClass: "center-text-y" }, [
                          _vm._v(
                            _vm._s(
                              listItem.primaryContactNumber
                                ? listItem.primaryContactNumber
                                : "-"
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "width-perc-21" }, [
                        _c("div", { staticClass: "center-text-y" }, [
                          _vm._v(" " + _vm._s(listItem.firstName) + " "),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-right position-relative width-perc-10",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pt-1" },
                            [
                              _c(
                                VMenu,
                                {
                                  attrs: {
                                    bottom: "",
                                    "min-width": "120",
                                    left: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "triple-dot-menu",
                                                    attrs: { icon: "" },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(VIcon, [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    VList,
                                    [
                                      _vm.farmerStatus ==
                                      _vm.ViewPreferenceType.NotArchived
                                        ? _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openContactDetailsForm(
                                                    listItem
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [_vm._v("mdi-eye")]
                                                  ),
                                                  _vm._v("View "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      listItem.isInternal &&
                                      _vm.farmerStatus ==
                                        _vm.ViewPreferenceType.NotArchived
                                        ? _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openContactEditForm(
                                                    listItem.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [_vm._v("mdi-pencil")]
                                                  ),
                                                  _vm._v("Edit "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.farmerStatus ==
                                        _vm.ViewPreferenceType.NotArchived &&
                                      listItem.isInternal
                                        ? _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.confirmActionOnFarmer(
                                                    listItem.id,
                                                    _vm.listAction.Archive
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-archive-arrow-down"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v("Archive "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !listItem.isInternal
                                        ? _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.confirmDeleteFarmer(
                                                    listItem.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [_vm._v("mdi-delete")]
                                                  ),
                                                  _vm._v("Delete "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.farmerStatus ==
                                      _vm.ViewPreferenceType.Archived
                                        ? _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.confirmActionOnFarmer(
                                                    listItem.id,
                                                    _vm.listAction.Restore
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [_vm._v("mdi-restore")]
                                                  ),
                                                  _vm._v("Restore "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "zero-state",
                  fn: function () {
                    return [
                      _vm.farmerFilterType == 0
                        ? _c("div", [
                            _c("h4", { staticClass: "mb-0" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$stringConstants(
                                    "farmersListZeroStateTitle"
                                  )
                                )
                              ),
                            ]),
                            _c("p", { staticClass: "pt-2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$stringConstants(
                                    "farmersListZeroStateSubtitle"
                                  )
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4176819225
            ),
          })
        : _vm._e(),
      _c("confirmDialogbox", {
        attrs: {
          item: _vm.integrateDialog,
          firstActionText: "Cancel",
          secondActionText: "Go",
        },
        on: {
          cancel: function ($event) {
            _vm.integrateDialog = false
          },
          proceed: _vm.proceedIntegrateDialog,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("farmerIntegrationsTitle")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("farmerIntegrationsSubTitle")) +
                    " "
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$stringConstants("farmerGoToIntegration")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("confirmDialogbox", {
        attrs: {
          item: _vm.showUpdateCustomersDialog,
          firstActionText: "Cancel",
          secondActionText: "Yes",
        },
        on: {
          cancel: function ($event) {
            _vm.showUpdateCustomersDialog = false
          },
          proceed: _vm.updateLinkedCustomers,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("farmersUpdateCustomerPromptTitle")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants("farmersUpdateCustomerPromptText1")
                    ) +
                    " " +
                    _vm._s(_vm.integrationType) +
                    " " +
                    _vm._s(
                      _vm.$stringConstants("farmersUpdateCustomerPromptText2")
                    ) +
                    " " +
                    _vm._s(
                      _vm.$stringConstants("farmersUpdateCustomerPromptText3")
                    ) +
                    " " +
                    _vm._s(_vm.integrationType) +
                    " " +
                    _vm._s(
                      _vm.$stringConstants("farmersUpdateCustomerPromptText4")
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DeleteItemDialog", {
        attrs: { item: _vm.dialogFarmer },
        on: {
          cancel: function ($event) {
            _vm.dialogFarmer = false
          },
          proceed: _vm.proceedFarmerDeletion,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm.actionType == _vm.listAction.Delete
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("farmersDeletePromtTitle")
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.actionType == _vm.listAction.Archive
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("farmersArchivePromtTitle")
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.actionType == _vm.listAction.Restore
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("farmersRestorePromtTitle")
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm.actionType == _vm.listAction.Delete
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("farmersDeletePromtSubtitle")
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.actionType == _vm.listAction.Archive
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("farmersArchivePromtSubtitle")
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.actionType == _vm.listAction.Restore
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$stringConstants("farmersRestorePromtSubtitle")
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("BulkActionResponseDialog"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }