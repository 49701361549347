import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators";
import { routeLogin, termsOfServiceURL, privacyPolicyURL } from "@/utils/endpoints";
import { notify, validateRegexPhoneNumber } from "../../utils/helpers";
import { ExternalIntegrationType } from "@/enum/externalIntegrationType";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import LoginRegistrationWrapper from "@/components/layouts/login-registration-wrapper.vue";
import { UserRoleType } from '@/enum/userRoleType';
import scriptsLoader from '@/mixins/scripts-loader';
import StringConstants from '@/plugins/stringConstants';
import router from '@/router';
import ConstantValues from '@/plugins/constantValues';
export default {
  mixins: [validationMixin, scriptsLoader],
  validations: {
    firstName: {
      required: required,
      minLength: minLength(ConstantValues.minlengthFirstName)
    },
    emailAddress: {
      required: required,
      email: email
    },
    phoneNumber: {
      required: required,
      minLength: minLength(ConstantValues.minlengthPhoneNumber)
    },
    passWord: {
      required: required,
      uppercaseConstraint: function uppercaseConstraint(password) {
        return /[A-Z]/.test(password);
      },
      lowercaseConstraint: function lowercaseConstraint(password) {
        return /[a-z]/.test(password);
      },
      numericConstraint: function numericConstraint(password) {
        return /[0-9]/.test(password);
      },
      minLength: minLength(ConstantValues.minlengthPassword),
      maxLength: maxLength(ConstantValues.maxlengthPassword)
    },
    confirmPassWord: {
      required: required,
      minLength: minLength(ConstantValues.minlengthPassword),
      sameAsPassword: sameAs(StringConstants.passWordField)
    },
    acceptTermsAndPrivacyPolicy: {
      required: required,
      sameAs: sameAs(function () {
        return true;
      })
    }
  },
  components: {
    Button: Button,
    LoginRegistrationWrapper: LoginRegistrationWrapper
  },
  data: function data() {
    return {
      firstName: "",
      lastName: "",
      fullName: "",
      passWord: "",
      emailAddress: "",
      phoneNumber: "",
      companyName: "",
      confirmPassWord: "",
      roleSelection: 0,
      routeLogin: routeLogin,
      termsOfServiceURL: termsOfServiceURL,
      privacyPolicyURL: privacyPolicyURL,
      showRoleToggle: true,
      value: String,
      valueConfirm: String,
      nameRegexPattern: /\W/g,
      isUnclickable: false,
      validPasswordRequirements: [{
        key: StringConstants.minLengthField,
        status: null,
        text: "At least 8 characters"
      }, {
        key: StringConstants.uppercaseConstraint,
        status: null,
        text: "At least 1 uppercase letter"
      }, {
        key: StringConstants.lowercaseConstraint,
        status: null,
        text: "At least 1 lowercase letter"
      }, {
        key: StringConstants.numericConstraint,
        status: null,
        text: "At least 1 number"
      }],
      acceptTermsAndPrivacyPolicy: false,
      UserRoleType: UserRoleType,
      enumRoleTitles: ['Contractor', 'Farmer', 'Manager', 'Team Lead', 'Operator'],
      connType: null,
      isEmailDisabled: false,
      ExternalIntegrationType: ExternalIntegrationType
    };
  },
  computed: {
    userSignUpRoles: {
      get: function get() {
        return this.$store.getters.userSignUpRoles;
      }
    },
    invitationType: {
      get: function get() {
        return this.$store.getters.invitationType;
      }
    },
    usersLoader: {
      get: function get() {
        return this.$store.getters.usersLoader;
      },
      set: function set(newVal) {
        this.$store.state.usersModule.usersLoader = newVal;
      }
    },
    showPasswordRequirementsTooltip: {
      get: function get() {
        return this.passwordFieldIsFocused;
      },
      set: function set(value) {
        this.passwordFieldIsFocused = value;
      }
    },
    isFormInvalid: {
      get: function get() {
        return this.$v.$invalid;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.loadScripts();
    var urlParams = new URLSearchParams(window.location.search);
    var invitationCode = urlParams.get("code");
    var invitationType = urlParams.get("type");
    if (invitationCode != null && invitationType != null) {
      if (invitationType == this.invitationType["invitation"]) {
        this.$store.dispatch("getInviteeProfile", invitationCode).then(function (result) {
          if (result) {
            _this.roleSelection = result.inviteeRole;
            if (!(_this.roleSelection == UserRoleType.Contractor || _this.roleSelection == UserRoleType.Farmer)) {
              _this.showRoleToggle = false;
            } else {
              _this.isUnclickable = true;
            }
            _this.emailAddress = result.inviteeEmail;
          }
        });
      }
    }
    this.checkAndInitializeExternalInvoicingSignup();
  },
  methods: {
    checkAndInitializeExternalInvoicingSignup: function checkAndInitializeExternalInvoicingSignup() {
      var urlParams = new URLSearchParams(window.location.search);
      var email = urlParams.get("email");
      var name = urlParams.get("name");
      var connType = urlParams.get("connType");
      if (email) {
        this.isEmailDisabled = true;
        this.emailAddress = email;
        this.firstName = name;
      }
      if (connType) this.connType = Number(connType);
      if (connType == ExternalIntegrationType.Xero && !email && !name) this.$store.dispatch("initializeLoginWithExternalInvoicingService", ExternalIntegrationType.Xero);
    },
    firstNameKeydown: function firstNameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    lastNameKeydown: function lastNameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    submit: function submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.proceedSignUp();
    },
    generateTermsAndPrivacyPolicyError: function generateTermsAndPrivacyPolicyError() {
      var errors = [];
      if (!this.$v.acceptTermsAndPrivacyPolicy.$dirty) return errors;
      if (!this.$v.acceptTermsAndPrivacyPolicy.sameAs) {
        errors.push(this.$stringConstants('userRequiredTermsAndPrivacyCheckbox'));
      }
      return errors;
    },
    generateValidationErrors: function generateValidationErrors(propertyName, validationName1, validationName2, userError1, userError2) {
      var errors = [];
      if (!this.$v["".concat(propertyName)].$dirty) return errors;
      if (!this.$v["".concat(propertyName)]["".concat(validationName1)]) {
        errors.push(userError1);
      } else if (!this.$v["".concat(propertyName)]["".concat(validationName2)]) {
        errors.push(userError2);
      }
      return errors;
    },
    generateValidationPasswordErrors: function generateValidationPasswordErrors(propertyName, validationName1, validationName2, validationName3, validationName4, validationName5, validationName6, userError1, userError2, userError3, userError4, userError5, userError6) {
      var errors = [];
      if (!this.$v["".concat(propertyName)].$dirty) return errors;
      if (!this.$v["".concat(propertyName)]["".concat(validationName1)]) {
        errors.push(userError1);
      }
      if (!this.$v["".concat(propertyName)]["".concat(validationName2)]) {
        errors.push(userError2);
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName2 ? requirement.status = false : null;
        });
      } else {
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName2 ? requirement.status = true : null;
        });
      }
      if (!this.$v["".concat(propertyName)]["".concat(validationName3)]) {
        errors.push(userError3);
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName3 ? requirement.status = false : null;
        });
      } else {
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName3 ? requirement.status = true : null;
        });
      }
      if (!this.$v["".concat(propertyName)]["".concat(validationName4)]) {
        errors.push(userError4);
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName4 ? requirement.status = false : null;
        });
      } else {
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName4 ? requirement.status = true : null;
        });
      }
      if (!this.$v["".concat(propertyName)]["".concat(validationName5)]) {
        errors.push(userError5);
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName5 ? requirement.status = false : null;
        });
      } else {
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName5 ? requirement.status = true : null;
        });
      }
      if (!this.$v["".concat(propertyName)]["".concat(validationName6)]) {
        errors.push(userError6);
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName6 ? requirement.status = false : null;
        });
      } else {
        this.validPasswordRequirements.find(function (requirement) {
          return requirement.key == validationName6 ? requirement.status = true : null;
        });
      }
      return errors;
    },
    handlePhoneNumberInput: function handlePhoneNumberInput(event) {
      var phoneNumberIsValid = validateRegexPhoneNumber(event);
      if (phoneNumberIsValid) {
        this.$v.phoneNumber.$touch();
      }
    },
    handlePasswordFieldInput: function handlePasswordFieldInput() {
      if (this.$v.passWord.$model.length > 0) {
        this.$v.passWord.$touch();
      } else {
        this.validPasswordRequirements.map(function (requirement) {
          return requirement.status = null;
        });
        this.$v.passWord.$reset();
      }
    },
    handlePasswordFieldBlur: function handlePasswordFieldBlur() {
      if (!this.$v.passWord.$invalid || this.$v.passWord.$model.length == 0) {
        this.showPasswordRequirementsTooltip = false;
      }
    },
    handlePasswordFieldFocus: function handlePasswordFieldFocus() {
      this.showPasswordRequirementsTooltip = true;
      if (this.$v.passWord.$model.length > 0) {
        this.$v.passWord.$touch();
      } else {
        this.$v.passWord.$reset();
      }
    },
    proceedSignUp: function proceedSignUp() {
      var _this2 = this;
      var urlParams = new URLSearchParams(window.location.search);
      var invitationCode = urlParams.get("code");
      var createUserInfo = null;
      if (invitationCode != null) {
        createUserInfo = {
          profile: {
            firstName: this.firstName.trim(),
            email: this.emailAddress,
            primaryContactNumber: this.phoneNumber
          },
          roles: [this.userSignUpRoles[this.roleSelection]],
          password: this.passWord,
          invitationCode: invitationCode
        };
      } else {
        createUserInfo = {
          profile: {
            firstName: this.firstName.trim(),
            email: this.emailAddress,
            primaryContactNumber: this.phoneNumber
          },
          roles: [this.userSignUpRoles[this.roleSelection]],
          password: this.passWord
        };
      }
      if (this.emailAddress) createUserInfo['externalConnectionType'] = this.connType;
      this.$store.dispatch("signUpUser", createUserInfo).then(function (response) {
        var _a;
        if (!response) return;
        var adminRoleTypes = [UserRoleType.Contractor, UserRoleType.Farmer, UserRoleType.Manager];
        if (adminRoleTypes.includes((_a = response === null || response === void 0 ? void 0 : response.roles[0]) === null || _a === void 0 ? void 0 : _a.type)) {
          var formData = new FormData();
          formData.append("username", _this2.emailAddress);
          formData.append("password", _this2.passWord);
          formData.append("clientType", ConstantValues.clientType);
          _this2.$store.dispatch("login", formData);
        } else {
          router.push(routeLogin);
          notify(StringConstants.userOperatorSignUpSuccessMessage, 'success');
        }
        _this2.isEmailDisabled = false;
      });
    }
  }
};