import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.object.to-string.js";
import $ from "jquery";
import { clearDateTimePicker, getDateDifferenceInDays, getDateDifferenceInMinutes, openOverlay } from "@/utils/helpers";
import { mapMutations } from "vuex";
import scrollerMixin from "@/mixins/scroller-mixin";
import businessesComputedMixin from "./su-businesses-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/search-timers';
import { getDateTimeFormat as _getDateTimeFormat, getDateTimeFormattedLocal as _getDateTimeFormattedLocal } from '@/utils/helpers';
import Avatar from '@/components/shared/avatars/avatar.vue';
import { SubscriptionStatusType, SubscriptionStatusTypeLabel } from '@/enum/subscriptionStatusType';
import { apiAdminBusinesses, routeBusinesses } from '@/utils/endpoints';
import router from '@/router';
import { ExcelExportModuleType } from '@/enum/excelExportModuleType';
import { excelParser } from "@/utils/helpers/excel-parser-helpers";
import { getCancellationDate as _getCancellationDate, getSusbcriptionAmountIfExists as _getSusbcriptionAmountIfExists, getTrialDaysLeft as _getTrialDaysLeft } from '@/utils/helpers/subscription-helper';
export default {
  name: "UsersListView",
  data: function data() {
    return {
      SubscriptionStatusType: SubscriptionStatusType,
      showExportDialog: false,
      totalRowCountTemp: null
    };
  },
  mixins: [scrollerMixin, businessesComputedMixin, Timers],
  components: {
    Button: Button,
    List: List,
    Avatar: Avatar
  },
  beforeMount: function beforeMount() {
    this.businesses = [];
    this.resetPaging();
    this.clearAllFilterStates();
  },
  beforeDestroy: function beforeDestroy() {
    this.resetPaging();
    this.clearBusinessesFilterOptions();
  },
  mounted: function mounted() {
    this.getBusinessesList();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearBusinessesFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging",
    resetUsersListFilter: "resetUsersListFilter"
  })), {}, {
    boldIfTrialTimeRemaining: function boldIfTrialTimeRemaining(trialEndDateTime) {
      if (trialEndDateTime && parseInt(getDateDifferenceInMinutes(trialEndDateTime)) > 0) {
        return 'fw-6';
      }
      return '';
    },
    getDateTimeFormat: function getDateTimeFormat(dateTime) {
      return _getDateTimeFormat(dateTime);
    },
    getDateTimeFormattedLocal: function getDateTimeFormattedLocal(dateTime) {
      return _getDateTimeFormattedLocal(dateTime);
    },
    openBusinessesFilterForm: function openBusinessesFilterForm() {
      this.businessesListFiltersApplied = false;
      openOverlay("businessesFilterFormOverlay");
    },
    clearAllFilterStates: function clearAllFilterStates() {
      this.clearBusinessesFilterOptions();
      this.resetPaging();
      this.businesses = [];
      this.businessCountry = null;
      this.businessType = null;
      this.businessSubscriptionStatus = null;
      this.businessesStartDate = this.businessesEndDate = null;
      $('.vdpr-datepicker input[type=text]').first().val('');
      $('.vdpr-datepicker input').first().css({
        width: "110px"
      });
      clearDateTimePicker(document.querySelector('.vdpr-datepicker'));
    },
    clearFilterStates: function clearFilterStates(filterType) {
      switch (filterType) {
        case 'businessCountry':
          this.businessCountry = null;
          break;
        case 'businessType':
          this.businessType = null;
          break;
        case 'businessSubscriptionStatus':
          this.businessSubscriptionStatus = null;
          break;
        case 'dateRange':
          this.businessesStartDate = this.businessesEndDate = null;
          $('.vdpr-datepicker input[type=text]').first().val('');
          $('.vdpr-datepicker input').first().css({
            width: "110px"
          });
          clearDateTimePicker(document.querySelector('.vdpr-datepicker'));
          this.businessesStartDate = this.businessesEndDate = null;
          break;
        default:
          return;
      }
      this.applyBusinessesFilter();
    },
    applyBusinessesFilter: function applyBusinessesFilter() {
      this.clearBusinessesFilterOptions();
      this.resetPaging();
      this.businesses = [];
      this.businessesListFiltersApplied = true;
      this.getBusinessesList();
    },
    clearAndResetUsersPagingStates: function clearAndResetUsersPagingStates() {
      $(window).unbind("scroll");
      this.$store.state.suBusinessesModule.businesses = [];
      this.clearBusinessesFilterOptions();
      this.resetPaging();
      this.businessesListFiltersApplied = false;
    },
    getBusinessesList: function getBusinessesList() {
      var _this = this;
      var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize];
      this.$store.dispatch('setBusinessesListFilter', filter).then(function () {
        _this.$store.dispatch('getBusinessesList');
      });
    },
    setFilterOptionsAndGetBusinesses: function setFilterOptionsAndGetBusinesses(emittedFilters) {
      if (!this.businessesSearchText) {
        this.clearBusinessesFilterOptions();
        this.businessesListFiltersApplied = false;
      }
      this.getBusinessesList(emittedFilters);
    },
    getBusinessType: function getBusinessType(businessType) {
      var _a;
      if (businessType !== undefined && this.businessTypesList.length > 0) {
        return (_a = this.businessTypesList.find(function (b) {
          return b.value == businessType;
        })) === null || _a === void 0 ? void 0 : _a.title;
      }
    },
    getSubscriptionStatusType: function getSubscriptionStatusType(statusType) {
      var _a;
      if (statusType !== undefined && this.businessSubscriptionStatusTypes.length > 0) {
        return (_a = this.businessSubscriptionStatusTypes.find(function (b) {
          return b.value == statusType;
        })) === null || _a === void 0 ? void 0 : _a.title;
      }
    },
    getSubscriptionStatusEnum: function getSubscriptionStatusEnum(status) {
      return status !== null ? SubscriptionStatusTypeLabel.get(status) : '-';
    },
    getDifferenceInDays: function getDifferenceInDays(date) {
      return parseInt(getDateDifferenceInDays(date));
    },
    getTrialDaysLeft: function getTrialDaysLeft(trialEndDate) {
      return _getTrialDaysLeft(trialEndDate);
    },
    getSusbcriptionAmountIfExists: function getSusbcriptionAmountIfExists(subscription) {
      return _getSusbcriptionAmountIfExists(subscription, true);
    },
    getCancellationDate: function getCancellationDate(subscriptions) {
      return _getCancellationDate(subscriptions);
    },
    openBusinessDetailsView: function openBusinessDetailsView(user) {
      router.push(routeBusinesses + '/' + user.id);
    },
    getCancellationReason: function getCancellationReason(subscriptions) {
      if (subscriptions && subscriptions.cancellationReason) {
        return subscriptions.cancellationReason;
      }
      return '';
    },
    initiateBusinessesExport: function initiateBusinessesExport() {
      this.totalRowCountTemp = this.totalRowCount;
      this.$store.commit('resetExportData');
      this.clearBusinessesFilterOptions();
      this.resetPaging();
      this.showExportDialog = true;
      this.getBusinesseswDataForDownload();
    },
    getBusinesseswDataForDownload: function getBusinesseswDataForDownload() {
      var _this2 = this;
      var exportFilters = [];
      if (this.businessesSearchText) {
        exportFilters.push({
          searchText: this.businessesSearchText
        });
      }
      if (this.businessCountry) {
        exportFilters.push({
          country: this.businessCountry
        });
      }
      if (this.businessType !== null) {
        exportFilters.push({
          businessType: this.businessType
        });
      }
      if (this.businessSubscriptionStatus !== null) {
        exportFilters.push({
          status: this.businessSubscriptionStatus
        });
      }
      if (this.businessesStartDate !== null) {
        exportFilters.push({
          start: this.businessesStartDate
        });
      }
      if (this.businessesEndDate !== null) {
        exportFilters.push({
          end: this.businessesEndDate
        });
      }
      this.$store.dispatch("setExportDataParameterFilters", exportFilters).then(function () {
        _this2.$store.dispatch("getDataListForExport", {
          apiUrl: apiAdminBusinesses,
          moduleType: ExcelExportModuleType.Businesses
        }).then(function () {
          _this2.getMoreBusinessesForExport();
        });
      });
    },
    resetPagingAndList: function resetPagingAndList() {
      this.resetPaging();
      this.clearBusinessesFilterOptions();
      this.resetUsersListFilter();
      this.totalRowCount = this.totalRowCountTemp;
      this.businesses = [];
      this.getBusinessesList();
    },
    getMoreBusinessesForExport: function getMoreBusinessesForExport() {
      var _this3 = this;
      this.$store.dispatch("getDataListForExport", {
        apiUrl: apiAdminBusinesses,
        moduleType: ExcelExportModuleType.Businesses
      }).then(function () {
        if (_this3.exportDataLoaded < _this3.exportDataTotal) {
          _this3.getMoreBusinessesForExport();
        } else {
          if (_this3.exportData.length > 0) {
            _this3.showExportDialog = false;
            excelParser().saveDataToExcel();
            _this3.resetPagingAndList();
          }
        }
      });
    }
  })
};