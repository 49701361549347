import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldComponentDisplay(_vm.componentsPermissions["productRead"])
    ? _c(
        "div",
        { staticClass: "list-container" },
        [
          _c("Snackbar", {
            attrs: {
              deleteApiType: "products",
              status: _vm.productStatus,
              allItemsSelected: _vm.allProductsSelected,
              someItemsSelected: _vm.someProductsSelected,
              items: _vm.productsList,
            },
          }),
          _c("ActionListingDialog", {
            attrs: {
              itemName: "products",
              onClickEvent: "proccedToActionOnProducts",
              itemsPreference: this.productStatus,
            },
          }),
          _c("ItemDialog", {
            attrs: { item: _vm.dialogProduct },
            on: {
              cancel: function ($event) {
                return _vm.cancelActionOnMachine(_vm.actionType)
              },
              proceed: function ($event) {
                return _vm.proceedActionOnMachine(_vm.actionType)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.fnGetHeaderString(_vm.actionType)) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.fnGetBodyString(_vm.actionType)) + " "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3378694121
            ),
          }),
          _c(
            VRow,
            {
              staticClass: "flex-grow-0 px-9 pt-4",
              attrs: { "no-gutters": "", cols: "12" },
            },
            [
              _c(
                VCol,
                { attrs: { cols: "6" } },
                [
                  _c(
                    VRow,
                    { staticStyle: { "max-width": "500px" } },
                    [
                      _c(
                        VCol,
                        { staticClass: "px-0", attrs: { cols: "9" } },
                        [
                          _c(VTextField, {
                            staticClass: "rd-rt-no agd-txtfield mr-4",
                            attrs: {
                              placeholder: _vm.$stringConstants(
                                "productSearchPlaceholder"
                              ),
                              outlined: "",
                              clearable: "",
                              "prepend-inner-icon": "mdi-magnify",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.applySearchTimer(
                                  _vm.applySearchProductFilter
                                )
                              },
                            },
                            model: {
                              value: _vm.productSearchText,
                              callback: function ($$v) {
                                _vm.productSearchText = $$v
                              },
                              expression: "productSearchText",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { staticClass: "px-0", attrs: { cols: "3" } },
                        [
                          _c(VSelect, {
                            staticClass: "rd-lf-no agd-dropdown",
                            attrs: {
                              items: _vm.enumActiveStatus,
                              "item-value": "value",
                              "item-text": "title",
                              placeholder:
                                _vm.$stringConstants("statusPlaceholder"),
                              dense: "",
                              outlined: "",
                              "append-icon": "mdi-chevron-down",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getProductListByStatus()
                              },
                            },
                            model: {
                              value: _vm.productStatus,
                              callback: function ($$v) {
                                _vm.productStatus = $$v
                              },
                              expression: "productStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.shouldComponentDisplay(
                _vm.componentsPermissions["productCreate"]
              )
                ? _c(
                    VCol,
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        VRow,
                        { staticClass: "text-right" },
                        [
                          _c(
                            VCol,
                            { staticClass: "px-0", attrs: { cols: "12" } },
                            [
                              _c("Button", {
                                staticClass: "mx-3",
                                attrs: {
                                  variant: "secondary",
                                  outlined: "",
                                  outlineColor: "gray",
                                  textValue: _vm.$stringConstants("importText"),
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.openImportForm()
                                  },
                                },
                              }),
                              _c("Button", {
                                attrs: {
                                  variant: "secondary",
                                  color: "primary",
                                  outlined: "",
                                  outlineColor: "primary",
                                  textValue: _vm.$stringConstants("addNewText"),
                                  leftIcon: "mdi-plus",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.openAddProductForm()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("List", {
            attrs: { listItems: _vm.productsList, containsAction: "" },
            on: {
              setFilterOptionsAndGetListData:
                _vm.setFilterOptionsAndGetProducts,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "list-heading",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "list-check width-perc-33" }, [
                        _c(
                          "div",
                          { staticClass: "ib" },
                          [
                            _c(VCheckbox, {
                              attrs: {
                                indeterminate: _vm.someProductsSelected,
                                "input-value": _vm.allProductsSelected,
                              },
                              on: { change: _vm.selectAllProducts },
                              model: {
                                value: _vm.isChecked,
                                callback: function ($$v) {
                                  _vm.isChecked = $$v
                                },
                                expression: "isChecked",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "ib v-a-t" }, [
                          _vm._v(_vm._s(_vm.$stringConstants("product"))),
                        ]),
                      ]),
                      _c("div", { staticClass: "width-perc-33" }, [
                        _vm._v(
                          _vm._s(_vm.$stringConstants("chargeableUnitText"))
                        ),
                      ]),
                      _c("div", { staticClass: "width-perc-25" }, [
                        _vm._v(_vm._s(_vm.$stringConstants("costText"))),
                      ]),
                      _c("div", { staticClass: "text-right width-perc-9" }, [
                        _vm._v(_vm._s(_vm.$stringConstants("action"))),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "list-item",
                  fn: function (ref) {
                    var listItem = ref.listItem
                    return [
                      _c("div", { staticClass: "list-check width-perc-33" }, [
                        _c(
                          "div",
                          { staticClass: "ib" },
                          [
                            _c(VCheckbox, {
                              attrs: { value: listItem.id, multiple: "" },
                              model: {
                                value: _vm.selectedItems,
                                callback: function ($$v) {
                                  _vm.selectedItems = $$v
                                },
                                expression: "selectedItems",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "ib v-a-t" }, [
                          _vm._v(_vm._s(listItem.title)),
                        ]),
                      ]),
                      _c("div", { staticClass: "width-perc-33" }, [
                        _vm._v(_vm._s(listItem.unit)),
                      ]),
                      _c("div", { staticClass: "width-perc-25" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.currencySymbol) +
                            _vm._s(listItem.cost) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-right position-relative width-perc-9",
                        },
                        [
                          _c(
                            "div",
                            {},
                            [
                              _c(
                                VMenu,
                                {
                                  attrs: {
                                    bottom: "",
                                    "min-width": "120",
                                    left: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "triple-dot-menu",
                                                    attrs: { icon: "" },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(VIcon, [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    VList,
                                    [
                                      _vm.shouldComponentDisplay(
                                        _vm.componentsPermissions[
                                          "productUpdate"
                                        ]
                                      ) &&
                                      _vm.productStatus ==
                                        _vm.ViewPreferenceType.NotArchived
                                        ? _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openProductEditForm(
                                                    listItem.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [_vm._v("mdi-pencil")]
                                                  ),
                                                  _vm._v("Edit "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.shouldComponentDisplay(
                                        _vm.componentsPermissions[
                                          "productArchive"
                                        ]
                                      ) &&
                                      _vm.productStatus ==
                                        _vm.ViewPreferenceType.NotArchived
                                        ? _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.confirmActionOnProduct(
                                                    listItem.id,
                                                    _vm.listAction.Archive
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-archive-arrow-down"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v("Archive "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.shouldComponentDisplay(
                                        _vm.componentsPermissions[
                                          "productRestore"
                                        ]
                                      ) &&
                                      _vm.productStatus ==
                                        _vm.ViewPreferenceType.Archived
                                        ? _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.confirmActionOnProduct(
                                                    listItem.id,
                                                    _vm.listAction.Restore
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [_vm._v("mdi-restore")]
                                                  ),
                                                  _vm._v("Restore "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.shouldComponentDisplay(
                                        _vm.componentsPermissions[
                                          "productDelete"
                                        ]
                                      )
                                        ? _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.confirmActionOnProduct(
                                                    listItem.id,
                                                    _vm.listAction.Delete
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemTitle,
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "icon-size pr-2",
                                                    },
                                                    [_vm._v("mdi-delete")]
                                                  ),
                                                  _vm._v("Delete "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "zero-state",
                  fn: function () {
                    return [
                      _vm.productStatus == _vm.TabFilterType.Active
                        ? _c("div", [
                            _c("h4", { staticClass: "mb-0" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$stringConstants(
                                    "productZeroStateHeading"
                                  )
                                )
                              ),
                            ]),
                            _c("p", { staticClass: "pt-2" }, [
                              _vm._v(
                                _vm._s(_vm.$stringConstants("productZeroState"))
                              ),
                            ]),
                          ])
                        : _vm.productStatus == _vm.TabFilterType.Inactive
                        ? _c("div", [
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$stringConstants(
                                    "productZeroStateInactive"
                                  )
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2540404142
            ),
          }),
        ],
        1
      )
    : _c("div", { staticClass: "pt-8" }, [
        _vm._v(" " + _vm._s(_vm.$stringConstants("productListDisabled")) + " "),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }