import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { getCapitalised, openOverlay, reduceText, getDateFormatted as _getDateFormatted } from "@/utils/helpers";
import { brandColor } from "@/utils/uiconstants";
import { routeTeamJobsDetails } from "@/utils/endpoints";
import InvoicesExternalContactsFlyout from "@/components/invoices-external/invoices-external-contacts/invoices-external-contacts.vue";
import moment from "moment";
import invoicesExternalComputed from "@/components/invoices-external/invoices-external-computed";
import { mapMutations } from "vuex";
import { getJobNumberPrefix as _getJobNumberPrefix, getJobNumberSuffix as _getJobNumberSuffix } from '@/utils/helpers/jobs-helpers';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import CloseButton from '@/components/shared/buttons/button-close.vue';
export default {
  data: function data() {
    return {
      menuDate: false,
      invoiceMenuDate: false,
      brandColor: brandColor,
      showMissingInfoWarningTaxRateActiveIndex: null,
      showMissingInfoWarningChartOfAccount: false,
      linkingContact: false
    };
  },
  mixins: [invoicesExternalComputed],
  components: {
    InvoicesExternalContactsFlyout: InvoicesExternalContactsFlyout,
    Button: Button,
    CloseButton: CloseButton
  },
  watch: {
    invoiceDraftPreviewDetailsExternalLineItems: function invoiceDraftPreviewDetailsExternalLineItems(val) {
      this.checkLineItemsValidity();
    }
  },
  computed: {
    computedDateFormatted: function computedDateFormatted() {
      return this.invoiceDueDateExternal ? moment(this.invoiceDueDateExternal).format("DD MMM YYYY") : "";
    },
    computedInvoiceDateFormatted: function computedInvoiceDateFormatted() {
      return this.invoiceDateExternal ? moment(this.invoiceDateExternal).format("DD MMM YYYY") : "";
    },
    canAddNewLineItem: function canAddNewLineItem() {
      return this.invoicesExternalInitialLineItem && this.invoicesExternalInitialLineItem.item.title && this.invoicesExternalInitialLineItem.unit && this.invoicesExternalInitialLineItem.item.unitPrice;
    },
    isAllAddLineItemViewClosed: function isAllAddLineItemViewClosed() {
      if (!this.invoicesExternalShowAddLineItemView.length) return;
      return this.invoicesExternalShowAddLineItemView.filter(function (isItemOpen) {
        return isItemOpen == false;
      }).length == this.invoicesExternalShowAddLineItemView.length;
    },
    invoiceExternalCurrencySymbol: function invoiceExternalCurrencySymbol() {
      var _a, _b;
      return ((_b = (_a = this.invoiceDraftPreviewDetailsExternal) === null || _a === void 0 ? void 0 : _a.currency) === null || _b === void 0 ? void 0 : _b.symbol) || this.currencySymbol;
    }
  },
  mounted: function mounted() {
    this.$store.state.tempUrl = '';
    this.getExternalInvoiceTitle();
    this.invoiceJobReportUrls = [];
    this.invoiceExternalLineItemSearchText = [];
    this.localCheckContactLinkStatus();
    this.$store.dispatch('getOperationUnits');
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    clearFilterOptions: "setClearFilterOptions",
    resetPaging: "resetPaging"
  })), {}, {
    localCheckContactLinkStatus: function localCheckContactLinkStatus() {
      var _this = this;
      if (this.invoiceDraftPreviewDetailsExternal && this.invoiceDraftPreviewDetailsExternal.customerId) {
        this.invoiceLoaderExternal = true;
        this.$store.dispatch("checkContactLinkStatus", [this.integrationType, this.invoiceDraftPreviewDetailsExternal.customerId]).then(function (res) {
          _this.invoiceLoaderExternal = false;
        });
      }
    },
    getExternalInvoiceTitle: function getExternalInvoiceTitle() {
      var invoiceTypeText = this.integrationType ? " " + getCapitalised(this.integrationType) + " " : " ";
      return invoiceTypeText;
    },
    updateLineItem: function updateLineItem(lineItem) {
      lineItem.description = lineItem.item.description;
      this.checkLineItemsValidity();
    },
    updateNewLineItem: function updateNewLineItem(lineItem) {
      lineItem.description = lineItem.item.description;
    },
    routeToJobDetails: function routeToJobDetails(jobId) {
      if (jobId) {
        window.open("".concat(routeTeamJobsDetails).concat(jobId), "_blank").focus();
      }
    },
    unlinkContact: function unlinkContact() {
      var _this2 = this;
      this.invoiceLoaderExternal = true;
      this.$store.dispatch("unlinkContact", [this.integrationType, this.invoiceDraftPreviewDetailsExternal.customerId, this.invoiceDraftPreviewDetailsExternal.contactId]).then(function (unlinkResult) {
        _this2.invoiceLoaderExternal = false;
        if (unlinkResult && unlinkResult.status == 200) {
          _this2.localCheckContactLinkStatus();
        }
      });
    },
    openContactsFlyout: function openContactsFlyout() {
      if (this.integrationType != null) {
        this.externalContactRadioGroup = 1;
        this.customerLoader = true;
        this.contacts = [];
        this.tempIntegratedContacts = [];
        this.clearFilterOptions();
        this.resetPaging();
      }
      this.linkingContact = true;
      openOverlay("contactsLinkFormOverlay");
    },
    viewReport: function viewReport(reportUrl) {
      window.open(reportUrl, "_blank");
    },
    removeAttachment: function removeAttachment(rIndx) {
      this.invoiceJobReportUrls.splice(rIndx, 1);
    },
    searchExternalLineItems: function searchExternalLineItems(lineItemIndex) {
      var _this3 = this;
      this.clearFilterOptions();
      this.$store.dispatch("setLineItemsExternalSearchText", lineItemIndex).then(function (res) {
        if (res != false) {
          _this3.$store.dispatch("getInvoiceLineItemsExternal", _this3.integrationType);
          if (_this3.invoiceExternalLineItemSearchText == "") {
            _this3.clearFilterOptions();
          }
        }
      });
    },
    getMissingInfoWarning: function getMissingInfoWarning() {
      return "Please update the missing information on ".concat(this.integrationType.charAt(0).toUpperCase() + this.integrationType.slice(1), " and refresh the products again.");
    },
    fetchLineItems: function fetchLineItems() {
      this.clearFilterOptions();
      this.invoiceExternalLineItems = [];
      this.$store.dispatch("getInvoiceLineItemsExternal", this.integrationType);
    },
    reduceTextLength: function reduceTextLength(textToReduce, maxTextLength) {
      return textToReduce ? reduceText(textToReduce, maxTextLength) : '';
    },
    showMissingInfoWarningTaxRate: function showMissingInfoWarningTaxRate(index) {
      this.showMissingInfoWarningTaxRateActiveIndex = index;
    },
    hideMissingInfoWarningTaxRate: function hideMissingInfoWarningTaxRate() {
      this.showMissingInfoWarningTaxRateActiveIndex = null;
    },
    getJobNumberPrefix: function getJobNumberPrefix(jobNumber) {
      return _getJobNumberPrefix(jobNumber);
    },
    getJobNumberSuffix: function getJobNumberSuffix(jobNumber) {
      return _getJobNumberSuffix(jobNumber);
    },
    showAddLineItemView: function showAddLineItemView(distinctJobIndex) {
      this.invoicesExternalShowAddLineItemView = this.invoicesExternalShowAddLineItemView.map(function (lineItemView) {
        return lineItemView = false;
      });
      this.invoicesExternalShowAddLineItemView[distinctJobIndex] = true;
    },
    resetToInitialLineItem: function resetToInitialLineItem() {
      this.invoicesExternalInitialLineItem = {
        "item": {
          "id": null,
          "title": null,
          "description": null,
          "unitPrice": 0,
          "taxRate": {
            "id": null,
            "title": null,
            "rate": 0
          },
          "chartOfAccount": {
            "id": null,
            "title": null
          }
        },
        "quantity": 0,
        "unit": null,
        "description": "",
        "reference": "",
        "date": ""
      };
    },
    addNewLineItem: function addNewLineItem(distinctJobIndex) {
      var jobReference = this.invoiceLineItemJobNumbersDistinctExternal[distinctJobIndex];
      var lastIndexToAppendTo = 0;
      this.invoiceDraftPreviewDetailsExternalLineItems.forEach(function (item, index) {
        if (item.reference == jobReference) {
          lastIndexToAppendTo = index;
        }
      });
      this.invoicesExternalInitialLineItem.reference = jobReference;
      this.invoiceDraftPreviewDetailsExternalLineItems.splice(lastIndexToAppendTo + 1, 0, this.invoicesExternalInitialLineItem);
      var anyLineItemWithSameJob = this.invoiceDraftPreviewDetailsExternalLineItems.find(function (lineItem) {
        return lineItem.reference == jobReference;
      });
      this.invoicesExternalInitialLineItem.date = anyLineItemWithSameJob && anyLineItemWithSameJob.date ? anyLineItemWithSameJob.date : '';
      this.resetToInitialLineItem();
      this.invoicesExternalShowAddLineItemView = this.invoicesExternalShowAddLineItemView.map(function (lineItemView) {
        return lineItemView = false;
      });
      this.invoicesExternalShowAddLineItemButton = true;
      this.checkLineItemsValidity();
    },
    cancelNewLineItemAdd: function cancelNewLineItemAdd() {
      this.resetToInitialLineItem();
      this.invoicesExternalShowAddLineItemView = this.invoicesExternalShowAddLineItemView.map(function (lineItemView) {
        return lineItemView = false;
      });
      this.invoicesExternalShowAddLineItemButton = true;
    },
    removeExternalLineItem: function removeExternalLineItem(lineitemIndex) {
      this.invoiceDraftPreviewDetailsExternalLineItems = this.invoiceDraftPreviewDetailsExternalLineItems.filter(function (item, index) {
        return index != lineitemIndex;
      });
      this.checkLineItemsValidity();
    },
    shouldShowLineItemRemoveButton: function shouldShowLineItemRemoveButton(distinctJobNumber) {
      var lineItemsWithSameJobNumber = this.invoiceDraftPreviewDetailsExternalLineItems.filter(function (item) {
        return item.reference == distinctJobNumber;
      });
      return lineItemsWithSameJobNumber.length > 1;
    },
    getDateFormatted: function getDateFormatted(date) {
      return _getDateFormatted(date);
    }
  })
};