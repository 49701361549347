import "core-js/modules/es.array.push.js";
import { formFieldPb } from '@/utils/uiconstants';
import accountComputedMixin from '@/components/account/account-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
export default {
  name: 'PersonalDetailsForm',
  mixins: [accountComputedMixin, validationMixin],
  validations: {
    userProfile: {
      email: {
        required: required,
        email: email
      }
    }
  },
  components: {
    SectionLeftHeadings: SectionLeftHeadings
  },
  data: function data() {
    return {
      formFieldPb: formFieldPb
    };
  },
  mounted: function mounted() {
    this.originalEmail = this.userProfile.email;
  },
  methods: {
    generateValidationErrors: function generateValidationErrors() {
      var errors = [];
      if (!this.$v.userProfile.email.$dirty) return errors;
      if (!this.$v.userProfile.email.required) {
        errors.push(this.$stringConstants('userRequiredEmailAddress'));
        this.accountEmailFieldInvalid = true;
      } else if (!this.$v.userProfile.email.email) {
        errors.push(this.$stringConstants('userFormatEmailAddress'));
        this.accountEmailFieldInvalid = true;
      } else {
        this.accountEmailFieldInvalid = false;
      }
      return errors;
    }
  }
};