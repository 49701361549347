import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.object.to-string.js";
import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { checkFieldsValidity, getAddressAsSingleString } from '@/utils/helpers';
import { BaseButton as Button } from '@/components/shared/buttons/index';
export default {
  name: 'ContactFarmsAddress',
  mixins: [contactComputedMixin],
  components: {
    SectionLeftHeadings: SectionLeftHeadings,
    Button: Button
  },
  props: ['contactId'],
  data: function data() {
    return {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      latitude: 0,
      longitude: 0,
      showFarmAddressAddFields: false
    };
  },
  computed: {
    isNewFarmFieldsValid: function isNewFarmFieldsValid() {
      if (checkFieldsValidity([this.addressLine1, this.postalCode])) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    isAddressValid: function isAddressValid(address) {
      if (checkFieldsValidity([address.addressLine1, address.postalCode])) {
        return true;
      } else {
        return false;
      }
    },
    removeSelectedAddress: function removeSelectedAddress(addressId) {
      var _this = this;
      this.$store.dispatch("deleteContactAddresses", [this.contactId, addressId]).then(function (res) {
        _this.$store.dispatch('getContactFarmsDetailsByAdmin', _this.contactId);
      });
    },
    Update: function Update(id) {
      var _this2 = this;
      var address = this.contactFarmAddresses.filter(function (x) {
        return x.id == id;
      })[0];
      this.$store.dispatch('updateContactAddresses', [address, this.contactId]).then(function (res) {
        _this2.$store.dispatch('getContactFarmsDetailsByAdmin', _this2.contactId);
      });
    },
    displayAddFarmAddressFields: function displayAddFarmAddressFields() {
      this.showFarmAddressAddFields = true;
    },
    addNewAddress: function addNewAddress() {
      var _this3 = this;
      var newAddressObj = {};
      newAddressObj = {
        addressLine1: this.addressLine1,
        addressLine2: this.addressLine2,
        city: this.city,
        state: this.state,
        postalCode: this.postalCode,
        country: this.country,
        coordinate: null
      };
      this.$store.dispatch('addContactAddresses', [newAddressObj, this.contactId]).then(function (res) {
        _this3.$store.dispatch('getContactFarmsDetailsByAdmin', _this3.contactId);
        _this3.hideAddFarmAddressFields();
      });
    },
    getFarmAddressInLine: function getFarmAddressInLine(address) {
      return getAddressAsSingleString(address);
    },
    hideAddFarmAddressFields: function hideAddFarmAddressFields() {
      this.addressLine1 = '';
      this.addressLine2 = '';
      this.city = '';
      this.state = '';
      this.postalCode = '';
      this.country = '';
      this.latitude = 0;
      this.longitude = 0;
      this.showFarmAddressAddFields = false;
    }
  }
};