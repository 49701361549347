import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-snackbar" },
    [
      _c(
        VSnackbar,
        {
          attrs: { timeout: _vm.timeout },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function (ref) {
                var attrs = ref.attrs
                return [
                  _vm.showArchiveOrDelete
                    ? _c(
                        "span",
                        [
                          _vm.status == _vm.ViewPreferenceType.NotArchived &&
                          _vm.showUpdateButton &&
                          _vm.integrationType !== null
                            ? _c(
                                VBtn,
                                _vm._b(
                                  {
                                    staticClass: "mr-4 snackbar-actions-btn",
                                    attrs: {
                                      color: "#fff",
                                      text: "",
                                      disabled:
                                        _vm.loaderState || _vm.bulkActionLoader,
                                    },
                                    on: { click: _vm.updateItems },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                [
                                  _vm.loaderState
                                    ? _c(
                                        VIcon,
                                        { staticClass: "rotating pr-2" },
                                        [_vm._v(" mdi-loading ")]
                                      )
                                    : _c(VIcon, { attrs: { left: "" } }, [
                                        _vm._v(" mdi-account "),
                                      ]),
                                  _vm._v(
                                    " Update from " +
                                      _vm._s(_vm.integrationType) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.status == _vm.ViewPreferenceType.NotArchived &&
                          _vm.showRoleChange
                            ? _c(
                                VBtn,
                                _vm._b(
                                  {
                                    staticClass: "mr-4 snackbar-actions-btn",
                                    attrs: {
                                      color: "#fff",
                                      text: "",
                                      disabled:
                                        _vm.loaderState || _vm.bulkActionLoader,
                                    },
                                    on: { click: _vm.changeRole },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                [
                                  _vm.loaderState
                                    ? _c(
                                        VIcon,
                                        { staticClass: "rotating pr-2" },
                                        [_vm._v(" mdi-loading ")]
                                      )
                                    : _c(VIcon, { attrs: { left: "" } }, [
                                        _vm._v(" mdi-account "),
                                      ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$stringConstants("roleChangeText")
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.status == _vm.ViewPreferenceType.Archived &&
                          !_vm.showOnlyDelete
                            ? _c(
                                VBtn,
                                _vm._b(
                                  {
                                    staticClass: "mr-4 snackbar-actions-btn",
                                    attrs: {
                                      color: "#fff",
                                      text: "",
                                      disabled:
                                        _vm.loaderState || _vm.bulkActionLoader,
                                    },
                                    on: { click: _vm.restoreSelectedRows },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                [
                                  _vm.bulkActionLoader &&
                                  _vm.listActionType == _vm.listAction.Restore
                                    ? _c(
                                        VIcon,
                                        { staticClass: "rotating pr-2" },
                                        [_vm._v(" mdi-loading ")]
                                      )
                                    : _c(VIcon, { attrs: { left: "" } }, [
                                        _vm._v(" mdi-restore "),
                                      ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$stringConstants("restoreText")
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            : _vm.status ==
                                _vm.ViewPreferenceType.NotArchived &&
                              !_vm.showOnlyDelete
                            ? _c(
                                VBtn,
                                _vm._b(
                                  {
                                    staticClass: "mr-4 snackbar-actions-btn",
                                    attrs: {
                                      color: "#fff",
                                      text: "",
                                      disabled:
                                        _vm.loaderState || _vm.bulkActionLoader,
                                    },
                                    on: { click: _vm.archiveSelectedRows },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                [
                                  _vm.bulkActionLoader &&
                                  (_vm.listActionType ==
                                    _vm.listAction.Delete ||
                                    _vm.listActionType ==
                                      _vm.listAction.Archive)
                                    ? _c(
                                        VIcon,
                                        { staticClass: "rotating pr-2" },
                                        [_vm._v(" mdi-loading ")]
                                      )
                                    : _c(VIcon, { attrs: { left: "" } }, [
                                        _vm._v(" mdi-archive-arrow-down "),
                                      ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$stringConstants(
                                          "archiveOrDeleteText"
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "span",
                        [
                          _vm.deleteApiType == "jobs"
                            ? _c(
                                "span",
                                [
                                  _vm.isContractorOnlyView
                                    ? _c(
                                        VBtn,
                                        _vm._b(
                                          {
                                            staticClass:
                                              "mr-4 snackbar-actions-btn",
                                            attrs: {
                                              color: "#fff",
                                              text: "",
                                              disabled:
                                                _vm.loaderState ||
                                                _vm.bulkActionLoader,
                                            },
                                            on: {
                                              click: _vm.approveSelectedRows,
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        [
                                          _vm.bulkActionLoader
                                            ? _c(
                                                VIcon,
                                                {
                                                  staticClass: "rotating pr-2",
                                                },
                                                [_vm._v(" mdi-loading ")]
                                              )
                                            : _c(
                                                VIcon,
                                                { attrs: { left: "" } },
                                                [_vm._v(" mdi-check-bold ")]
                                              ),
                                          _vm._v(" Approve "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isContractorOnlyView
                                    ? _c(
                                        VBtn,
                                        _vm._b(
                                          {
                                            staticClass:
                                              "mr-4 snackbar-actions-btn",
                                            attrs: {
                                              color: "#fff",
                                              text: "",
                                              disabled:
                                                _vm.loaderState ||
                                                _vm.bulkActionLoader,
                                            },
                                            on: {
                                              click: _vm.unapproveSelectedRows,
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        [
                                          _vm.bulkActionLoader
                                            ? _c(
                                                VIcon,
                                                {
                                                  staticClass: "rotating pr-2",
                                                },
                                                [_vm._v(" mdi-loading ")]
                                              )
                                            : _c(
                                                VIcon,
                                                { attrs: { left: "" } },
                                                [_vm._v(" mdi-cancel ")]
                                              ),
                                          _vm._v(" Un-approve "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    VBtn,
                                    _vm._b(
                                      {
                                        staticClass:
                                          "mr-4 snackbar-actions-btn",
                                        attrs: {
                                          color: "#fff",
                                          text: "",
                                          disabled:
                                            _vm.loaderState ||
                                            _vm.bulkActionLoader,
                                        },
                                        on: { click: _vm.exportSelectedRows },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    [
                                      _vm.bulkActionLoader
                                        ? _c(
                                            VIcon,
                                            { staticClass: "rotating pr-2" },
                                            [_vm._v(" mdi-loading ")]
                                          )
                                        : _c(
                                            VIcon,
                                            { attrs: { left: "" } },
                                            [_vm._v(" mdi-arrow-down ")]
                                          ),
                                      _vm._v(" Download "),
                                    ],
                                    1
                                  ),
                                  _vm.isContractorOnlyView
                                    ? _c(
                                        VBtn,
                                        _vm._b(
                                          {
                                            staticClass:
                                              "mr-4 snackbar-actions-btn",
                                            attrs: {
                                              color: "#fff",
                                              text: "",
                                              disabled:
                                                _vm.loaderState ||
                                                _vm.bulkActionLoader,
                                            },
                                            on: {
                                              click:
                                                _vm.markAsInvoicedSelectedRows,
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        [
                                          _vm.bulkActionLoader
                                            ? _c(
                                                VIcon,
                                                {
                                                  staticClass: "rotating pr-2",
                                                },
                                                [_vm._v(" mdi-loading ")]
                                              )
                                            : _c(
                                                VIcon,
                                                { attrs: { left: "" } },
                                                [_vm._v(" mdi-invoice-check ")]
                                              ),
                                          _vm._v(" Mark Invoiced "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.status == _vm.ViewPreferenceType.Archived &&
                          !_vm.showOnlyDelete
                            ? _c(
                                VBtn,
                                _vm._b(
                                  {
                                    staticClass: "mr-4 snackbar-actions-btn",
                                    attrs: {
                                      color: "#fff",
                                      text: "",
                                      disabled:
                                        _vm.loaderState || _vm.bulkActionLoader,
                                    },
                                    on: { click: _vm.restoreSelectedRows },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                [
                                  _vm.bulkActionLoader &&
                                  _vm.listActionType == _vm.listAction.Restore
                                    ? _c(
                                        VIcon,
                                        { staticClass: "rotating pr-2" },
                                        [_vm._v(" mdi-loading ")]
                                      )
                                    : _c(VIcon, { attrs: { left: "" } }, [
                                        _vm._v(" mdi-restore "),
                                      ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$stringConstants("restoreText")
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            : _vm.status ==
                                _vm.ViewPreferenceType.NotArchived &&
                              !_vm.showOnlyDelete
                            ? _c(
                                VBtn,
                                _vm._b(
                                  {
                                    staticClass: "mr-4 snackbar-actions-btn",
                                    attrs: {
                                      color: "#fff",
                                      text: "",
                                      disabled:
                                        _vm.loaderState || _vm.bulkActionLoader,
                                    },
                                    on: { click: _vm.archiveSelectedRows },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                [
                                  _vm.bulkActionLoader &&
                                  _vm.listActionType == _vm.listAction.Archive
                                    ? _c(
                                        VIcon,
                                        { staticClass: "rotating pr-2" },
                                        [_vm._v(" mdi-loading ")]
                                      )
                                    : _c(VIcon, { attrs: { left: "" } }, [
                                        _vm._v(" mdi-archive-arrow-down "),
                                      ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$stringConstants("archiveText")
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            VBtn,
                            _vm._b(
                              {
                                staticClass: "mr-4 snackbar-actions-btn",
                                attrs: {
                                  color: "#fff",
                                  text: "",
                                  disabled:
                                    _vm.loaderState || _vm.bulkActionLoader,
                                },
                                on: { click: _vm.deleteSectedRows },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            [
                              _vm.bulkActionLoader &&
                              _vm.listActionType == _vm.listAction.Delete
                                ? _c(
                                    VIcon,
                                    { staticClass: "rotating pr-2" },
                                    [_vm._v(" mdi-loading ")]
                                  )
                                : _c(VIcon, { attrs: { left: "" } }, [
                                    _vm._v(" mdi-archive "),
                                  ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants("listItemDelete")
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackbarShow,
            callback: function ($$v) {
              _vm.snackbarShow = $$v
            },
            expression: "snackbarShow",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-left": "280px !important",
                "font-size": "13px",
              },
            },
            [
              !_vm.allItemsDeleted
                ? _c(VRow, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.selectedItems.length) +
                          " " +
                          _vm._s(
                            _vm.$stringConstants("itemsSelectedOnPageText")
                          )
                      ),
                    ]),
                    _vm.allItemsSelected &&
                    _vm.selectedItems.length < _vm.totalRowCount
                      ? _c("div", { staticClass: "ml-4" }, [
                          _c(
                            "div",
                            {
                              staticClass: "slc_ol_itm",
                              on: { click: _vm.selectAllItemsData },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$stringConstants("selectAll")) +
                                  " " +
                                  _vm._s(_vm.selectedItemsCount) +
                                  " " +
                                  _vm._s(_vm.$stringConstants("itemsText")) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.allItemsDeleted
                ? _c(VRow, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$stringConstants("allText")) +
                          " " +
                          _vm._s(_vm.selectedItemsCount) +
                          " " +
                          _vm._s(_vm.$stringConstants("itemsAreSelectedText"))
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "slc_ol_itm ml-4",
                        on: { click: _vm.clearAllSelected },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("clearAllText")) +
                            " "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }