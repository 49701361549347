//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    textValue: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
};