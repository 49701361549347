import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.teamJobsLoader
      ? _c("div", [
          _c(
            "div",
            [
              _c(VSkeletonLoader, {
                attrs: { height: "40", type: "card" },
              }),
            ],
            1
          ),
        ])
      : _c("div", [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "field-input-label d-flex",
                  class: { "pb-1 pt-2": true },
                  staticStyle: { "justify-content": "space-between" },
                },
                [
                  _c("div", [_vm._v("Tags")]),
                  _c(
                    "div",
                    {
                      staticClass: "cursor-pointer",
                      style: { color: _vm.brandColor },
                      on: { click: _vm.handleTagsManagement },
                    },
                    [_vm._v(" Manage Tags ")]
                  ),
                ]
              ),
              _c(
                VChipGroup,
                { attrs: { "active-class": "primary--text", column: "" } },
                _vm._l(_vm.teamJobTagsSelected, function (tag, index) {
                  return _c(
                    VChip,
                    {
                      key: "" + tag + index,
                      staticClass: "job-tag-chip",
                      attrs: {
                        close: "",
                        label: "",
                        "text-color": "primary",
                        "close-icon": "mdi-close",
                      },
                      on: {
                        "click:close": function ($event) {
                          return _vm.removeJobTag(tag)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "tag-text" }, [
                        _vm._v(_vm._s(_vm.truncateTag(tag))),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "div",
              { staticClass: "position-relative flex-grow-1" },
              [
                _c(VAutocomplete, {
                  ref: "autoComplete",
                  style: { minWidth: "100%", maxWidth: "300px" },
                  attrs: {
                    items: _vm.teamJobTagsAvailable,
                    multiple: "",
                    placeholder: !_vm.teamJobTagsSelected ? "Select tags" : "",
                    "search-input": _vm.search,
                    disabled: _vm.isDisabled,
                    outlined: "",
                    dense: "",
                    flat: "",
                    attach: "",
                    solo: "",
                  },
                  on: {
                    "update:searchInput": function ($event) {
                      _vm.search = $event
                    },
                    "update:search-input": function ($event) {
                      _vm.search = $event
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.handleEnterKey($event)
                    },
                    change: _vm.emitUpdate,
                    blur: _vm.handleBlur,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "selection",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _c("div", { staticStyle: { display: "none" } }, [
                              _vm._v(_vm._s(data.item)),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "no-data",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "px-4" }, [
                            _vm._v(" Press "),
                            _c("span", { staticClass: "fw-5" }, [
                              _vm._v("Enter"),
                            ]),
                            _vm._v(" to add "),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.teamJobTagsSelected,
                    callback: function ($$v) {
                      _vm.teamJobTagsSelected = $$v
                    },
                    expression: "teamJobTagsSelected",
                  },
                }),
                !_vm.search
                  ? _c(
                      "div",
                      { staticClass: "position-absolute clickthrough" },
                      [_vm._v(" Select or type to add a new tag ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  VBtn,
                  {
                    staticClass: "ml-2 tags-refresh-btn",
                    attrs: {
                      disabled: _vm.isDisabled,
                      outlined: "",
                      height: "40",
                    },
                    on: { click: _vm.getTagsFromBusinessConfig },
                  },
                  [
                    _c(VIcon, { attrs: { color: "#888" } }, [
                      _vm._v("mdi-refresh"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }