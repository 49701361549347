import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.object.to-string.js";
export var state = {
  contractorFilterList: [],
  pendingContractorList: [],
  acceptedContractorTotalCount: 0,
  pendingContractorTotalCount: 0,
  noAcceptedContractors: false,
  noPendingContractors: false,
  contractorSearchText: '',
  contractorFilterType: 0,
  connectionRequestsSentList: [],
  connectionRequestsReceivedList: [],
  connectionRequestsReceivedLoader: false,
  connectionRequestsSentLoader: false
};
export var getters = {
  acceptedContractorTotalCount: function acceptedContractorTotalCount(state) {
    return state.acceptedContractorTotalCount;
  },
  pendingContractorTotalCount: function pendingContractorTotalCount(state) {
    return state.pendingContractorTotalCount;
  },
  noAcceptedContractors: function noAcceptedContractors(state) {
    return state.noAcceptedContractors;
  },
  noPendingContractors: function noPendingContractors(state) {
    return state.noPendingContractors;
  },
  contractorFilterList: function contractorFilterList(state) {
    return state.contractorFilterList;
  },
  pendingContractorList: function pendingContractorList(state) {
    return state.pendingContractorList;
  },
  contractorSearchText: function contractorSearchText(state) {
    return state.contractorSearchText;
  },
  contractorFilterType: function contractorFilterType(state) {
    return state.contractorFilterType;
  },
  connectionRequestsSentList: function connectionRequestsSentList(state) {
    return state.connectionRequestsSentList;
  },
  connectionRequestsReceivedList: function connectionRequestsReceivedList(state) {
    return state.connectionRequestsReceivedList;
  },
  connectionRequestsSentLoader: function connectionRequestsSentLoader(state) {
    return state.connectionRequestsSentLoader;
  },
  connectionRequestsReceivedLoader: function connectionRequestsReceivedLoader(state) {
    return state.connectionRequestsReceivedLoader;
  }
};
export var mutations = {
  setNoAcceptedContractors: function setNoAcceptedContractors(state, data) {
    state.noAcceptedContractors = data != null && data.length == 0;
  },
  setNoPendingContractors: function setNoPendingContractors(state, data) {
    state.noPendingContractors = data != null && data.length == 0;
  },
  setContractorFilteredList: function setContractorFilteredList(state, data) {
    data.map(function (data) {
      return state.contractorFilterList.push(data);
    });
  },
  setPendingContractorFilteredList: function setPendingContractorFilteredList(state, data) {
    data.map(function (x) {
      var contractor = {
        "code": x.code,
        "inviterName": x.inviterName,
        "inviteeFirstName": x.inviteeFirstName,
        "inviteeLastName": x.inviteeLastName,
        "inviteeEmail": x.inviteeEmail,
        "inviteeRole": x.inviteeRole,
        "timestamp": x.timestamp,
        "expiry": x.expiry
      };
      state.pendingContractorList.push(contractor);
    });
  },
  setConnectionRequestsSentList: function setConnectionRequestsSentList(state, data) {
    state.connectionRequestsSentList = [];
    state.connectionRequestsSentList = data;
  },
  setConnectionRequestsReceivedList: function setConnectionRequestsReceivedList(state, data) {
    state.connectionRequestsReceivedList = [];
    state.connectionRequestsReceivedList = data;
  }
};