import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.trim.js";
import accountComputedMixin from '@/components/account/account-computed';
import accountMapSearch from '@/components/account/account-map-search.vue';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
export default {
  name: 'TeamSettingsForm',
  mixins: [accountComputedMixin],
  components: {
    SectionLeftHeadings: SectionLeftHeadings,
    accountMapSearch: accountMapSearch,
    VueCtkDateTimePicker: VueCtkDateTimePicker,
    Button: Button
  },
  data: function data() {
    return {
      dialogSelectLocationOnMap: false,
      menuColor: false,
      addJobTag: null
    };
  },
  computed: {
    swatchStyle: function swatchStyle() {
      var menuColor = this.menuColor;
      return {
        backgroundColor: this.accountBusinessProfile.config.defaultColor,
        cursor: "pointer",
        height: "24px",
        width: "24px",
        borderRadius: menuColor ? "50%" : "50px",
        transition: "border-radius 200ms ease-in-out"
      };
    },
    filteredJobTags: function filteredJobTags() {
      return this.accountBusinessProfile.config.jobTags;
    },
    isTagValid: function isTagValid() {
      return this.addJobTag && !this.accountBusinessProfile.config.jobTags.includes(this.addJobTag.trim());
    }
  },
  methods: {
    openDialogSelectLocationOnMap: function openDialogSelectLocationOnMap() {
      this.dialogSelectLocationOnMap = true;
      this.$store.dispatch('getBusinessLocationCordinateOnMap');
    },
    confirmDialogSelectLocationOnMap: function confirmDialogSelectLocationOnMap() {
      this.dialogSelectLocationOnMap = false;
      this.$store.dispatch("getGoogleMapToShowDefaultMarker");
    },
    addJobTagToConfig: function addJobTagToConfig() {
      if (this.addJobTag) {
        this.accountBusinessProfile.config.jobTags.push(this.addJobTag);
        this.addJobTag = null;
      }
    },
    removeJobTag: function removeJobTag(tag) {
      var index = this.accountBusinessProfile.config.jobTags.indexOf(tag);
      if (index !== -1) {
        this.accountBusinessProfile.config.jobTags.splice(index, 1);
      }
    }
  }
};