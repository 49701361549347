import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import { wysiwygEditorConfig } from '@/utils/uiconstants';
import { VueEditor } from "vue2-editor";
export default {
  name: "TeamJobsInstructions",
  data: function data() {
    return {
      customToolbar: wysiwygEditorConfig,
      editorInstance: null
    };
  },
  watch: {
    tempTeamJobInstructions: {
      handler: function handler(newValue) {
        if (newValue !== this.teamJobInstructions) {
          var parsedValue = this.autoLinkUrls(newValue);
          this.tempTeamJobInstructions = parsedValue;
          this.teamJobInstructions = parsedValue;
        }
      },
      immediate: true
    }
  },
  mixins: [TeamJobsComputed, TeamJobsCommon],
  methods: {
    onChangeInstruction: function onChangeInstruction(delta, oldDelta, source) {
      if (!this.isExternalInstructionsUpdate) {
        this.enableTeamJobDiscard();
        this.hasUserEditedInstructions = true;
      }
    },
    handleKeyDown: function handleKeyDown() {
      this.hasUserEditedInstructions = true;
      this.enableTeamJobDiscard();
    },
    setupEditorKeyboardListener: function setupEditorKeyboardListener() {
      if (this.$refs.editor && this.$refs.editor.quill) {
        this.editorInstance = this.$refs.editor.quill;
        this.editorInstance.root.addEventListener('keydown', this.handleKeyDown);
      }
    }
  },
  components: {
    VueEditor: VueEditor
  },
  mounted: function mounted() {
    this.hasUserEditedInstructions = false;
    this.$nextTick(this.setupEditorKeyboardListener);
  },
  beforeDestroy: function beforeDestroy() {
    if (this.editorInstance && this.editorInstance.root) {
      this.editorInstance.root.removeEventListener('keydown', this.handleKeyDown);
    }
    this.hasUserEditedInstructions = false;
  }
};