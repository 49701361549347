export var listAction;
(function (listAction) {
  listAction[listAction["Delete"] = 0] = "Delete";
  listAction[listAction["Restore"] = 1] = "Restore";
  listAction[listAction["Archive"] = 2] = "Archive";
  listAction[listAction["Approve"] = 3] = "Approve";
  listAction[listAction["Unapprove"] = 4] = "Unapprove";
  listAction[listAction["Cancel"] = 5] = "Cancel";
  listAction[listAction["Export"] = 6] = "Export";
  listAction[listAction["MarkAsInvoiced"] = 7] = "MarkAsInvoiced";
})(listAction || (listAction = {}));