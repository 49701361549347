import "core-js/modules/es.array.push.js";
import Vue from "vue";
import NavMenu from "../src/components/layouts/sidebar.vue";
import { routeSubscriptions, routeAccount, routeSignUp } from "@/utils/endpoints";
import $ from "jquery";
import router from "@/router/index";
import { checkAppVersion } from "./utils/helpers/layout-helpers";
import { UserRoleType } from "@/enum/userRoleType";
import { AccountTabType } from "./enum/accountTabType";
import { defaultSidebarWidth } from "@/utils/uiconstants";
import { facebookPixelId, youtubeTutorialsLink } from '@/utils/constants';
import { getShortName as _getShortName } from "./utils/helpers";
import Notification from "@/components/shared/snackbar/notification.vue";
export default Vue.extend({
  name: "App",
  components: {
    "nav-menu-sidebar": NavMenu,
    Notification: Notification
  },
  beforeMount: function beforeMount() {
    checkAppVersion(this.$store.state);
  },
  mounted: function mounted() {
    if (this.$route.path === routeSignUp && process.env.NODE_ENV === 'production') {
      this.loadFacebookPixelScript(function () {
        fbq('init', facebookPixelId);
        fbq('track', 'PageView');
      });
    }
    this.$store.dispatch("subscribeUser", localStorage.getItem("businessId"));
  },
  computed: {
    loggedIn: function loggedIn() {
      return this.$store.getters.loggedIn;
    },
    dashboardTitle: function dashboardTitle() {
      return this.$store.getters.dashboardTitle;
    },
    loginUserName: function loginUserName() {
      return this.$store.getters.loginUserName;
    },
    subscriptionStatus: function subscriptionStatus() {
      return localStorage.getItem("subscriptionStatus");
    },
    shouldShowTrial: function shouldShowTrial() {
      return localStorage.getItem("showTrialInfo") == "1";
    },
    trialDaysRemaining: function trialDaysRemaining() {
      return localStorage.getItem("trialDaysRemaining");
    },
    getTrialClass: function getTrialClass() {
      if (this.trialDaysRemaining) {
        if (this.trialDaysRemaining < 7) return "ds-rmn-dg";
        return "ds-rmn-nr";
      } else {
        return "ds-rmn-nr";
      }
    },
    loginUserRole: {
      get: function get() {
        return this.$store.getters.loginUserRole;
      }
    },
    showAllDashboardViewsLocal: function showAllDashboardViewsLocal() {
      return localStorage.getItem("showAllDashboardViews");
    },
    accountTab: {
      get: function get() {
        return this.$store.getters['accountTab'];
      },
      set: function set(newVal) {
        this.$store.state['accountTab'] = newVal;
      }
    },
    breadcrumbsItems: {
      get: function get() {
        return this.$store.getters['breadcrumbsItems'];
      },
      set: function set(newVal) {
        this.$store.state['breadcrumbsItems'] = newVal;
      }
    },
    collapsedNavigation: {
      get: function get() {
        return this.$store.state.collapsedNavigation;
      },
      set: function set(newVal) {
        void 0;
      }
    },
    drawerIsOpen: function drawerIsOpen() {
      return this.$store.state.drawerOpen;
    },
    uniqueRouteKey: function uniqueRouteKey() {
      var uniqueRoute = this.$route.fullPath.split("/")[1];
      return uniqueRoute;
    }
  },
  data: function data() {
    return {
      routeAccount: routeAccount,
      routeSubscriptions: routeSubscriptions,
      UserRoleType: UserRoleType,
      sidebarWidth: defaultSidebarWidth,
      youtubeTutorialsLink: youtubeTutorialsLink,
      items: []
    };
  },
  methods: {
    closeModal: function closeModal() {
      $("#notiModal").fadeOut("fast");
      var message = document.getElementsByClassName("message")[0];
      message.innerHTML = "";
      $(".modal").css({
        "background-color": "unset"
      });
    },
    logoutClick: function logoutClick() {
      this.$store.dispatch("logout");
    },
    routeToSubscription: function routeToSubscription() {
      router.push(routeSubscriptions);
    },
    routeToAccount: function routeToAccount() {
      this.accountTab = AccountTabType.Profile;
      router.push(routeAccount);
    },
    closeDrawer: function closeDrawer() {
      this.$store.dispatch('revertTempPolygon');
      history.back();
    },
    openTutorialsLink: function openTutorialsLink() {
      window.open(youtubeTutorialsLink, '_blank');
    },
    getShortName: function getShortName(username) {
      return _getShortName(username, 10);
    },
    loadFacebookPixelScript: function loadFacebookPixelScript(callback) {
      var loadPixelScript = function loadPixelScript(f, b, e, v) {
        if (f.fbq) return;
        var n;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        var t = b.createElement(e);
        t.async = !0;
        t.src = v;
        var s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
        if (typeof callback === 'function') {
          t.onload = callback;
        }
      };
      loadPixelScript(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    }
  }
});