import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column h-percent-100" },
    [
      _c(
        VStepper,
        {
          staticClass: "flex-grow-1",
          model: {
            value: _vm.invoiceSelectedTab,
            callback: function ($$v) {
              _vm.invoiceSelectedTab = $$v
            },
            expression: "invoiceSelectedTab",
          },
        },
        [
          _c(
            VStepperHeader,
            {
              class: {
                noStepper:
                  _vm.invoiceDraftPreviewDetails != null &&
                  (_vm.invoiceDraftPreviewDetails.status != 0 ||
                    _vm.invoiceDraftPreviewDetails.status != 1 ||
                    _vm.invoiceDraftPreviewDetails.status != 2),
              },
            },
            [
              _vm._l(_vm.steps, function (n) {
                return [
                  _c(
                    VStepperStep,
                    {
                      key: n + "-step",
                      attrs: {
                        color: "#1D34AD",
                        complete: _vm.invoiceSelectedTab > n,
                        step: n,
                        editable: true,
                      },
                    },
                    [
                      n == 1
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$stringConstants("businessProfileText")
                              )
                            ),
                          ])
                        : _vm._e(),
                      n == 2
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$stringConstants("jobsText"))),
                          ])
                        : _vm._e(),
                      n == 3
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$stringConstants("invoice"))),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  n !== _vm.steps ? _c(VDivider, { key: n }) : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            VStepperItems,
            {
              staticClass: "h-percent-100 stepper-scroll",
              staticStyle: { "overflow-y": "scroll" },
            },
            _vm._l(_vm.steps, function (n) {
              return _c(
                VStepperContent,
                {
                  key: n + "-content",
                  staticClass: "px-0",
                  attrs: { step: n },
                },
                [
                  n == 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "inv-title pl-6 pt-3 pb-4" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants(
                                      "invoiceAddBusinessInfoTitle"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("BusinessProfileForInvoiceAdd"),
                        ],
                        1
                      )
                    : _vm._e(),
                  n == 2
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "inv-title pl-6 pt-3 pb-4" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$stringConstants(
                                      "invoiceAddJobsSelection"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("JobsListForInvoice"),
                        ],
                        1
                      )
                    : _vm._e(),
                  n == 3
                    ? _c("div", [_c("div", [_c("InvoiceDraftPreview")], 1)])
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fix-bar-inv-fixed btm-btn-bg py-4 flex-grow-0" },
        _vm._l(_vm.steps, function (n, idx) {
          return _c("div", { key: idx }, [
            n < _vm.steps && n != 3 && n != 2 && n == _vm.invoiceSelectedTab
              ? _c(
                  "div",
                  { staticClass: "px-6 py-0 btn-lt-sht text-right" },
                  [
                    _c("HoverTextButton", {
                      staticClass: "f-nn btn-wt-bl-text ml-2",
                      attrs: { width: 180, height: 36, textValue: "Cancel" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.cancelDraftInvoice()
                        },
                      },
                    }),
                    n != 1
                      ? _c("ButtonActionPrimary", {
                          staticClass: "f-nn ml-2",
                          attrs: {
                            elevation: "0",
                            width: 180,
                            height: 36,
                            textValue: "Previous",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.previousStep(n)
                            },
                          },
                        })
                      : _vm._e(),
                    _c("ButtonActionPrimary", {
                      staticClass: "f-nn ml-2",
                      attrs: {
                        elevation: "0",
                        width: 180,
                        height: 36,
                        textValue: "Next",
                        disabled: _vm.isNextDisabled(n),
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.nextStep(n)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            n == 2 && n == _vm.invoiceSelectedTab
              ? _c(
                  "div",
                  { staticClass: "px-6 py-0 btn-lt-sht text-right" },
                  [
                    _vm.selectedCustomerIdForInvoice &&
                    _vm.jobsListForInvoice.length > 0
                      ? _c("BaseButton", {
                          staticClass: "f-nn ml-2",
                          staticStyle: {
                            position: "absolute",
                            left: "20px",
                            "min-width": "240px !important",
                          },
                          attrs: {
                            variant: "secondary",
                            "min-wdith": "300",
                            color: "primary",
                            outlined: "",
                            outlineColor: "primary",
                            textValue: _vm.jobSelectionText,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toggleAllJobsSelection($event)
                            },
                          },
                        })
                      : _vm._e(),
                    _c("HoverTextButton", {
                      staticClass: "f-nn btn-wt-bl-text ml-2",
                      attrs: { width: 180, height: 36, textValue: "Cancel" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.cancelDraftInvoice()
                        },
                      },
                    }),
                    n != 1
                      ? _c("ButtonActionPrimary", {
                          staticClass: "f-nn ml-2",
                          attrs: {
                            elevation: "0",
                            width: 180,
                            height: 36,
                            textValue: "Previous",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.previousStep(n)
                            },
                          },
                        })
                      : _vm._e(),
                    _c("ButtonActionPrimary", {
                      staticClass: "f-nn ml-2",
                      attrs: {
                        elevation: "0",
                        width: 180,
                        height: 36,
                        textValue: _vm.$stringConstants("previewInvoiceText"),
                        disabled: _vm.isNextDisabled(n),
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.createDraft()
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            n == 3 && n == _vm.invoiceSelectedTab
              ? _c(
                  "div",
                  { staticClass: "px-6 py-0 btn-lt-sht text-right" },
                  [
                    _vm.isInvoiceOpened
                      ? _c("ButtonActionPrimaryBack", {
                          staticClass: "f-nn mr-2",
                          attrs: {
                            elevation: "0",
                            textValue: "Go back",
                            disabled:
                              _vm.invoiceLoader ||
                              !_vm.invoiceLineItemsHaveValidQuantity,
                            height: 40,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.goToPreviousRoute($event)
                            },
                          },
                        })
                      : _vm._e(),
                    !_vm.canOpenInvoice && !_vm.isInvoiceOpened
                      ? _c("HoverTextButton", {
                          staticClass: "f-nn btn-wt-bl-text ml-2 mr-4",
                          attrs: {
                            width: 180,
                            height: 40,
                            textValue: "Cancel",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.cancelDraftInvoice()
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.invoiceDraftPreviewDetails &&
                    (_vm.invoiceDraftPreviewDetails.emailStatus == 1 ||
                      _vm.invoiceDraftPreviewDetails.emailStatus == 0) &&
                    _vm.invoiceDraftPreviewDetails.status != 1 &&
                    _vm.canOpenInvoice
                      ? _c("HoverTextButton", {
                          staticClass: "f-nn btn-wt-bl-text ml-2 mr-4",
                          attrs: { width: 180, height: 40, textValue: "Back" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.cancelDraftInvoice()
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.invoiceDraftPreviewDetails &&
                    (_vm.invoiceDraftPreviewDetails.emailStatus == 1 ||
                      _vm.invoiceDraftPreviewDetails.emailStatus == 0) &&
                    _vm.invoiceDraftPreviewDetails.status != 1 &&
                    _vm.canOpenInvoice
                      ? _c(
                          "div",
                          {
                            staticClass: "ib pr-6",
                            staticStyle: { height: "30px !important" },
                          },
                          [
                            _c(VCheckbox, {
                              staticClass: "px-0 py-0 mx-0 my-0",
                              staticStyle: { transform: "translateY(5px)" },
                              attrs: { label: "Send later" },
                              model: {
                                value: _vm.invoiceShouldSendLater,
                                callback: function ($$v) {
                                  _vm.invoiceShouldSendLater = $$v
                                },
                                expression: "invoiceShouldSendLater",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.canOpenInvoice && !_vm.isInvoiceOpened
                      ? _c("ButtonActionPrimary", {
                          staticClass: "f-nn mr-2",
                          attrs: {
                            elevation: "0",
                            textValue: "Save",
                            disabled:
                              _vm.invoiceLoader ||
                              _vm.invoiceReportGenerationInProgress ||
                              !_vm.invoiceLineItemsHaveValidQuantity,
                            height: 40,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.createInvoiceDraft($event)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.canOpenInvoice && !_vm.isInvoiceOpened
                      ? _c("ButtonActionPrimary", {
                          staticClass: "f-nn mr-2",
                          attrs: {
                            elevation: "0",
                            textValue: "Save",
                            disabled:
                              _vm.invoiceLoader ||
                              _vm.invoiceReportGenerationInProgress ||
                              !_vm.invoiceLineItemsHaveValidQuantity,
                            height: 40,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.updateInvoiceDraft($event)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.canOpenInvoice &&
                    !_vm.isInvoiceOpened &&
                    _vm.invoiceShouldSendLater
                      ? _c("ButtonActionPrimary", {
                          staticClass: "f-nn mr-2",
                          attrs: {
                            elevation: "0",
                            textValue: "Submit",
                            disabled:
                              _vm.invoiceLoader ||
                              _vm.invoiceReportGenerationInProgress ||
                              !_vm.invoiceLineItemsHaveValidQuantity,
                            height: 40,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.createInvoiceFinalize($event)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.canOpenInvoice &&
                    !_vm.isInvoiceOpened &&
                    !_vm.invoiceShouldSendLater
                      ? _c("ButtonActionPrimary", {
                          staticClass: "f-nn mr-2",
                          attrs: {
                            elevation: "0",
                            textValue: "Submit and send",
                            disabled:
                              _vm.invoiceLoader ||
                              _vm.invoiceReportGenerationInProgress ||
                              !_vm.invoiceLineItemsHaveValidQuantity,
                            height: 40,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.createInvoiceFinalize($event)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.isInvoiceOpened &&
                    _vm.invoiceDraftPreviewDetails &&
                    _vm.invoiceDraftPreviewDetails.emailStatus == 1
                      ? _c("ButtonActionPrimary", {
                          staticClass: "f-nn mr-2",
                          attrs: {
                            elevation: "0",
                            textValue: "Send",
                            disabled:
                              _vm.invoiceLoader ||
                              _vm.invoiceReportGenerationInProgress ||
                              !_vm.invoiceLineItemsHaveValidQuantity,
                            height: 40,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.sendInvoice($event)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.isInvoiceOpened &&
                    _vm.invoiceDraftPreviewDetails.status != 2
                      ? _c("ButtonActionPrimary", {
                          staticClass: "f-nn mr-2",
                          attrs: {
                            elevation: "0",
                            textValue: "Mark as paid",
                            disabled:
                              _vm.invoiceLoader ||
                              !_vm.invoiceLineItemsHaveValidQuantity,
                            height: 40,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.markInvoiceAsPaid($event)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.isInvoiceOpened
                      ? _c("BaseButton", {
                          class: "f-nn mr-2",
                          attrs: {
                            variant: "secondary",
                            color: "primary",
                            elevation: "0",
                            textValue: "Download",
                            disabled:
                              _vm.invoiceLoader ||
                              !_vm.invoiceLineItemsHaveValidQuantity,
                            loading: _vm.invoiceLoader,
                            height: 40,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.downloadInvoice($event)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ])
        }),
        0
      ),
      _c("ReportDialog", {
        attrs: { item: _vm.regenerateJobReportPopup, "max-width": "460" },
        on: {
          cancel: function ($event) {
            return _vm.cancelReportRegeneration()
          },
          proceed: function ($event) {
            return _vm.proceedReportRegeneration()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants(
                        "invoiceExternalRegenerateJobReports"
                      )
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$stringConstants(
                        "invoiceExternalRegenerateJobReportsMessage"
                      )
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }