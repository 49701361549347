import ConstantValues from "@/plugins/constantValues";
import requests from "@/utils/requests";
export default {
  data: function data() {
    return {
      timerId: null
    };
  },
  methods: {
    applySearchTimer: function applySearchTimer(callback) {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.timerId = setTimeout(function () {
        requests.cancelPendingRequests();
        requests.initializeAbortControllerForJob();
        callback();
      }, ConstantValues.defaultDropdownSearchTimeout);
    }
  }
};