import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "px-9 pt-12", attrs: { "no-gutters": "", cols: "12" } },
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c("SectionLeftHeadings", {
                attrs: {
                  textValue1: _vm.$stringConstants(
                    "accountCompanyGeneralAndDefaultHeading1"
                  ),
                  textValue2: _vm.$stringConstants(
                    "accountCompanyLocationHeading2"
                  ),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "px-3", attrs: { cols: "7" } },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "pa-0", attrs: { cols: "12" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          _vm._s(_vm.$stringConstants("defaultFieldColor")) +
                            " *"
                        ),
                      ]),
                      _c(VTextField, {
                        staticClass: "ma-0 pa-0 pb-2",
                        attrs: { outlined: "", readonly: "", dense: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  VMenu,
                                  {
                                    attrs: {
                                      top: "",
                                      "nudge-bottom": "105",
                                      "nudge-left": "16",
                                      "close-on-content-click": false,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                { style: _vm.swatchStyle },
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.menuColor,
                                      callback: function ($$v) {
                                        _vm.menuColor = $$v
                                      },
                                      expression: "menuColor",
                                    },
                                  },
                                  [
                                    _c(
                                      VCard,
                                      [
                                        _c(
                                          VCardText,
                                          { staticClass: "pa-0" },
                                          [
                                            _c(VColorPicker, {
                                              attrs: {
                                                "hide-mode-switch": "",
                                                mode: "hexa",
                                                flat: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.accountBusinessProfile
                                                    .config.defaultColor,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.accountBusinessProfile
                                                      .config,
                                                    "defaultColor",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "accountBusinessProfile.config.defaultColor",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.accountBusinessProfile.config.defaultColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.accountBusinessProfile.config,
                              "defaultColor",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "accountBusinessProfile.config.defaultColor",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("fieldAreaUnit")) +
                            " "
                        ),
                      ]),
                      _c(VSelect, {
                        attrs: {
                          items: _vm.areaMeasurementUnitsList,
                          "item-text": "title",
                          "item-value": "value",
                          attach: "",
                          placeholder: _vm.$stringConstants(
                            "fieldUnitPlaceholder"
                          ),
                          dense: "",
                          outlined: "",
                        },
                        model: {
                          value:
                            _vm.accountBusinessProfile.config
                              .defaultAreaMeasurementUnit,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.accountBusinessProfile.config,
                              "defaultAreaMeasurementUnit",
                              $$v
                            )
                          },
                          expression:
                            "accountBusinessProfile.config.defaultAreaMeasurementUnit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                { staticClass: "px-0", attrs: { cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "px-0 pt-0 min-hd", attrs: { cols: "12" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$stringConstants("defaultLocationTitle")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticClass: "px-0",
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(VCol, { staticClass: "pa-0", attrs: { cols: "6" } }, [
                    _c("div", { staticClass: "pb-3 position-relative" }, [
                      _c("div", {
                        staticClass: "m-btn",
                        on: { click: _vm.openDialogSelectLocationOnMap },
                      }),
                      _c("div", {
                        staticClass: "accountMarkerMap",
                        attrs: { id: "accountMarkerMap" },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                VRow,
                { staticClass: "px-0", attrs: { cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "px-0 pt-4 min-hd", attrs: { cols: "12" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$stringConstants("teamWorkTimingTitle")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0 pr-2", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$stringConstants("startTime")) + " "
                        ),
                      ]),
                      _c("VueCtkDateTimePicker", {
                        staticClass: "sl-time",
                        attrs: {
                          color: "#1d34ad",
                          noKeyboard: "",
                          format: "HH:mm",
                          formatted: "HH:mm",
                          "append-icon": "mdi-clock-time-four-outline",
                          "no-label": "",
                          "only-time": "",
                          noClearButton: "",
                        },
                        model: {
                          value:
                            _vm.accountBusinessProfile.config
                              .workingStartTimeConverted,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.accountBusinessProfile.config,
                              "workingStartTimeConverted",
                              $$v
                            )
                          },
                          expression:
                            "accountBusinessProfile.config.workingStartTimeConverted",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0 pl-2", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$stringConstants("endTime")) + " "
                        ),
                      ]),
                      _c("VueCtkDateTimePicker", {
                        staticClass: "sl-time",
                        attrs: {
                          color: "#1d34ad",
                          noKeyboard: "",
                          format: "HH:mm",
                          formatted: "HH:mm",
                          "append-icon": "mdi-clock-time-four-outline",
                          "no-label": "",
                          "only-time": "",
                          noClearButton: "",
                        },
                        model: {
                          value:
                            _vm.accountBusinessProfile.config
                              .workingEndTimeConverted,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.accountBusinessProfile.config,
                              "workingEndTimeConverted",
                              $$v
                            )
                          },
                          expression:
                            "accountBusinessProfile.config.workingEndTimeConverted",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticClass: "pt-7",
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0 pr-2", attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$stringConstants("breakTimeMinutes")) +
                            " "
                        ),
                      ]),
                      _c(VTextField, {
                        attrs: {
                          outlined: "",
                          palceholder: "Break time (minutes)",
                          dense: "",
                          type: "number",
                          required: "",
                        },
                        model: {
                          value:
                            _vm.accountBusinessProfile.config
                              .allowedBreakTimeADayInMinutes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.accountBusinessProfile.config,
                              "allowedBreakTimeADayInMinutes",
                              _vm._n($$v)
                            )
                          },
                          expression:
                            "accountBusinessProfile.config.allowedBreakTimeADayInMinutes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                { attrs: { "no-gutters": "", cols: "12" } },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0 pr-2",
                      attrs: { id: "job-tags", cols: "6" },
                    },
                    [
                      _c("div", { staticClass: "field-input-label" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$stringConstants("jobTags")) + " "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex align-baseline" },
                        [
                          _c(VTextField, {
                            attrs: {
                              outlined: "",
                              palceholder: "Add job tag",
                              "hide-details": "",
                              dense: "",
                              maxlength: "50",
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.addJobTagToConfig($event)
                              },
                            },
                            model: {
                              value: _vm.addJobTag,
                              callback: function ($$v) {
                                _vm.addJobTag = $$v
                              },
                              expression: "addJobTag",
                            },
                          }),
                          _c("Button", {
                            staticClass: "ml-2",
                            attrs: {
                              variant: "secondary",
                              outlined: "",
                              outlineColor: "gray",
                              color: "primary",
                              disabled: !_vm.isTagValid,
                              textValue: "Add",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.addJobTagToConfig($event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(VCol, {
                    staticClass: "py-0 pr-2",
                    attrs: { cols: "6" },
                  }),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticClass: "pt-4",
                  staticStyle: { "max-width": "480px" },
                  attrs: { "no-gutters": "", cols: "12" },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0 pr-2", attrs: { cols: "12" } },
                    [
                      _c(
                        VChipGroup,
                        {
                          attrs: {
                            "active-class": "primary--text",
                            column: "",
                          },
                        },
                        _vm._l(_vm.filteredJobTags, function (tag, index) {
                          return _c(
                            VChip,
                            {
                              key: "" + tag + index,
                              staticClass: "job-tag-chip",
                              attrs: {
                                close: "",
                                label: "",
                                "text-color": "primary",
                                "close-icon": "mdi-close",
                              },
                              on: {
                                "click:close": function ($event) {
                                  return _vm.removeJobTag(tag)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(tag) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VRow,
        { attrs: { justify: "center" } },
        [
          _c(
            VDialog,
            {
              attrs: { persistent: "", "max-width": "900" },
              model: {
                value: _vm.dialogSelectLocationOnMap,
                callback: function ($$v) {
                  _vm.dialogSelectLocationOnMap = $$v
                },
                expression: "dialogSelectLocationOnMap",
              },
            },
            [
              _c(
                VCard,
                { staticClass: "pb-5" },
                [
                  _c(VCardText, { staticClass: "px-0" }, [
                    _c("div", { staticClass: "position-relative" }, [
                      _c(
                        "div",
                        {
                          staticClass: "position-absolute ma-5",
                          staticStyle: { "z-index": "1" },
                        },
                        [_c("accountMapSearch")],
                        1
                      ),
                      _c("div", {
                        staticClass: "selectBusinessCordinateMap",
                        attrs: { id: "selectBusinessCordinateMap" },
                      }),
                    ]),
                  ]),
                  _c(
                    VCardActions,
                    { staticClass: "py-0" },
                    [
                      _c(VSpacer),
                      _c("Button", {
                        attrs: {
                          variant: "secondary",
                          color: "primary",
                          textValue: _vm.$stringConstants("doneText"),
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.confirmDialogSelectLocationOnMap()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }