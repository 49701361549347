import _toConsumableArray from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TeamJobsGlobalComputed from "@/components/team-jobs/team-jobs-computed/_team-jobs-global";
import TeamJobsCommon from "@/components/team-jobs/team-jobs-details/team-jobs-details-common";
import { JobStatusType } from "@/enum/jobStatusType";
import { UserRoleType } from "@/enum/userRoleType";
import { brandColor } from "@/utils/uiconstants";
export default {
  name: "TeamJobTags",
  mixins: [TeamJobsGlobalComputed, TeamJobsCommon],
  props: {
    items: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    maxTagLength: {
      type: Number,
      default: 20
    }
  },
  data: function data() {
    return {
      search: null,
      menuOpen: false,
      jobStatusType: JobStatusType,
      brandColor: brandColor,
      UserRoleType: UserRoleType,
      maxCharLength: 100
    };
  },
  watch: {
    teamJobTagsSelected: {
      handler: function handler() {
        this.enableTeamJobDiscard();
      },
      deep: true
    }
  },
  computed: {
    isDisabled: function isDisabled() {
      return !this.teamJobViewPreference.tags.isEditable || this.teamJobTagsLoader;
    },
    loginUserRole: {
      get: function get() {
        return this.$store.getters.loginUserRole;
      }
    }
  },
  methods: {
    truncateTag: function truncateTag(tag) {
      if (tag.length > this.maxCharLength) {
        return tag.substring(0, this.maxCharLength) + '...';
      }
      return tag;
    },
    handleBlur: function handleBlur() {
      var _this = this;
      setTimeout(function () {
        _this.search = null;
      }, 100);
    },
    forceCloseMenu: function forceCloseMenu() {
      if (this.$refs.autoComplete) {
        this.$refs.autoComplete.isMenuActive = false;
        this.$refs.autoComplete.internalSearch = "";
        this.search = null;
      }
    },
    handleEnterKey: function handleEnterKey() {
      var _this2 = this;
      if (this.search && !this.teamJobTagsAvailable.includes(this.search)) {
        this.addNewTag(this.search);
        setTimeout(function () {
          _this2.forceCloseMenu();
          if (_this2.$refs.autoComplete && _this2.$refs.autoComplete.$el) {
            var input = _this2.$refs.autoComplete.$el.querySelector("input");
            if (input) {
              input.blur();
            }
          }
        }, 50);
      }
    },
    addNewTag: function addNewTag(tag) {
      if (tag && !this.teamJobTagsSelected.includes(tag)) {
        this.teamJobTagsSelected.push(tag);
        this.emitUpdate();
      }
    },
    removeTag: function removeTag(tag) {
      var index = this.teamJobTagsSelected.indexOf(tag);
      if (index >= 0) {
        this.teamJobTagsSelected.splice(index, 1);
        this.emitUpdate();
      }
    },
    emitUpdate: function emitUpdate() {
      this.$emit("input", _toConsumableArray(this.teamJobTagsSelected));
      this.$emit("change", _toConsumableArray(this.teamJobTagsSelected));
    },
    removeJobTag: function removeJobTag(tag) {
      this.removeTag(tag);
    },
    getTagsFromBusinessConfig: function getTagsFromBusinessConfig() {
      this.$store.dispatch("getTagsForTeamJobs");
    },
    handleTagsManagement: function handleTagsManagement() {
      window.open("/account?target=tags", "_blank");
    }
  },
  mounted: function mounted() {
    this.getTagsFromBusinessConfig();
  }
};