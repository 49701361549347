import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import ConstantValues from '@/plugins/constantValues';
import StringConstants from '@/plugins/stringConstants';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
var computedProperties = {
  'teamJobsListModule': ['teamJobsListSearchText', 'teamJobsList', 'teamJobsListFilterStartDate', 'teamJobsListFilterStartDateLocal', 'teamJobsListFilterEndDate', 'teamJobsListFilterAssignedUsers', 'teamJobsFilterStatusSelected', 'teamJobsDateFilterApplied', 'teamJobsFilterCustomerValue', 'teamJobsFilterCustomerSearchText', 'teamJobsFilterCustomersList', 'teamJobsFilterOperationsList', 'teamJobsFilterOperationSearchText', 'teamJobsFilterOperationValue', 'teamJobsFiltersInvoiceStatusList', 'teamJobsFilterInvoiceStatusValue', 'teamJobsFilterTeamMemberValue',
  // field filters
  'teamJobsFilterFieldValue', 'teamJobsFilterFieldsList', 'teamJobsFilterFieldsListSize', 'teamJobsFilterFieldsSearchText', 'teamJobsFieldsDropdownLoader',
  // vehicle filters
  'teamJobsFilterVehicleValue', 'teamJobsFilterVehiclesList', 'teamJobsFilterVehiclesListSize', 'teamJobsFilterVehiclesSearchText', 'teamJobsVehiclesDropdownLoader',
  // implement filters
  'teamJobsFilterImplementValue', 'teamJobsFilterImplementsList', 'teamJobsFilterImplementsListSize', 'teamJobsFilterImplementsSearchText', 'teamJobsImplementsDropdownLoader',
  // product filters
  'teamJobsFilterProductValue', 'teamJobsFilterProductsList', 'teamJobsFilterProductsListSize', 'teamJobsFilterProductsSearchText', 'teamJobsProductsDropdownLoader',
  // tag filter
  'teamJobFilterTagValue', 'teamJobFilterTagsList', 'teamJobsFilterTeamMemberSearchText', 'teamJobsFilterTeamMembersList', 'teamJobsFilterTeammembersListSize', 'teamJobsFiltersStatusList', 'teamJobsCustomersDropdownLoader', 'teamJobsTeamMembersDropdownLoader', 'teamJobsFilterCustomersListSize', 'routedFromJobDetails', 'jobViewType', 'calendarStartDate', 'calendarEndDate', 'teamJobVehiclesListForOperatorsLoader', 'teamJobOperatorToAddLoader', 'teamJobImplementsListForOperatorsLoader', 'calendarViewJobs'],
  'usersModule': ['userProfile'],
  'jobsInvoicesModule': ['jobDetails'],
  'excelExportModule': ['exportData', 'exportDataLength', 'exportDataLoaded', 'exportDataTotal'],
  'integrationsModule': ['showIntegrationPrompt'],
  'root': ['loginUserRole', 'routedFromTimesheets', 'filtersApplied', 'noData', 'listCount', 'listLoading', 'totalRowCount', 'isPageRelated', 'isRoutedFromReferencePage', 'breadcrumbsItems']
};
export default {
  name: 'TeamJobsGlobalComputedMixin',
  computed: _objectSpread(_objectSpread({}, getComputedProperties(computedProperties)), {}, {
    getLoggedInUserRole: {
      get: function get() {
        return getLoggedInUserRole();
      }
    },
    ownerRole: {
      get: function get() {
        return localStorage.getItem("ownerRole");
      }
    },
    getSenderId: function getSenderId() {
      return localStorage.getItem(ConstantValues.ownerId);
    },
    appendItemTypes: function appendItemTypes() {
      var itemTypes = {
        'customers': {
          'paginationModule': StringConstants.teamJobsFilterCustomersPaginationModule,
          'paginationSetter': 'setCustomersForJobsSearchFilters',
          'paginationGetter': 'getCustomersForJobsSearch'
        },
        'teamMembers': {
          'paginationModule': StringConstants.teamJobsFilterTeamMembersPaginationModule,
          'paginationSetter': 'setTeamMembersForJobsSearchFilters',
          'paginationGetter': 'getTeamMembersForJobsSearch'
        },
        'fields': {
          'paginationModule': StringConstants.teamJobsFilterFieldsPaginationModule,
          'paginationSetter': 'setFieldsForJobsSearchFilters',
          'paginationGetter': 'getFieldsForJobsSearch'
        },
        'vehicles': {
          'paginationModule': StringConstants.teamJobsFilterVehiclesPaginationModule,
          'paginationSetter': 'setVehiclesForJobsSearchFilters',
          'paginationGetter': 'getVehiclesForJobsSearch'
        },
        'implements': {
          'paginationModule': StringConstants.teamJobsFilterImplementsPaginationModule,
          'paginationSetter': 'setImplementsForJobsSearchFilters',
          'paginationGetter': 'getImplementsForJobsSearch'
        },
        'products': {
          'paginationModule': StringConstants.teamJobsFilterProductsPaginationModule,
          'paginationSetter': 'setProductsForJobsSearchFilters',
          'paginationGetter': 'getProductsForJobsSearch'
        }
      };
      return itemTypes;
    }
  })
};