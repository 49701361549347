import _objectSpread from "/home/vsts/work/1/s/src/web/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.object.to-string.js";
import $ from 'jquery';
import { clearDateTimePicker, getHoursAndMinsText, getShortName, openOverlay, initializeDateTimePlaceholders } from '@/utils/helpers';
import { mapMutations } from 'vuex';
import { routeJobs } from '@/utils/endpoints';
import permissionsMixin from '@/mixins/permissions-mixin';
import scrollerMixin from "@/mixins/scroller-mixin";
import router from '@/router/index';
import moment from 'moment';
import DatePicker from 'vue-time-date-range-picker/dist/vdprDatePicker';
import snackbarDelete from "@/components/shared/snackbar/snackbar-listing.vue";
import timesheetsComputedMixin from '@/components/timesheets/timesheets-computed';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { DeleteItemDialog, ActionListingDialog } from "@/components/shared/dialogs/index";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import { excelParser } from '@/utils/helpers/excel-parser-helpers';
import { removeAllLocalParamsJobs } from '@/utils/helpers/jobs-helpers';
import { TimeSheetType } from '@/enum/timeSheetType';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import StringConstants from '@/plugins/stringConstants';
import { defaultDateFormat } from '@/utils/constants';
export default {
  name: "TimeSheetListView",
  mixins: [permissionsMixin, scrollerMixin, timesheetsComputedMixin, filterParamsMixin],
  data: function data() {
    return {
      dialogTimeSheet: false,
      timeSheetDeleteId: null,
      dateInput: {
        placeholder: StringConstants.selectDatePlaceholder
      },
      format: defaultDateFormat,
      sameDateFormat: {
        from: defaultDateFormat,
        to: defaultDateFormat
      },
      showTimesheetExportDialog: false,
      isChecked: false,
      TimeSheetType: TimeSheetType,
      searchTimerMemberFilter: null
    };
  },
  computed: {
    someTimesheetsSelected: function someTimesheetsSelected() {
      return this.selectedItems.length > 0 && !this.allTimesheetsSelected;
    },
    allTimesheetsSelected: function allTimesheetsSelected() {
      return this.selectedItems.length == this.timesheetsList.length;
    }
  },
  watch: {
    selectedItems: function selectedItems(val) {
      if (this.selectedItems.length > 0) {
        this.snackbarShow = true;
      } else {
        this.snackbarShow = false;
        this.isChecked = false;
      }
    },
    timesheetListFilterType: function timesheetListFilterType() {
      this.$store.commit('clearSnackbarDetails');
    }
  },
  components: {
    DatePicker: DatePicker,
    snackbarDelete: snackbarDelete,
    ActionListingDialog: ActionListingDialog,
    DeleteItemDialog: DeleteItemDialog,
    Button: Button,
    List: List
  },
  beforeMount: function beforeMount() {
    this.clearAndResetTimeSheetsPagingStates();
  },
  beforeDestroy: function beforeDestroy() {
    this.clearAndResetTimeSheetsPagingStates();
    this.$store.commit('clearSnackbarDetails');
  },
  mounted: function mounted() {
    this.setPreviousMonthDateFilter();
    this.$store.commit('setDefaultMember', [this.loginUserName, this.loginUserRole]);
    this.$store.dispatch('getTimesheetMembersList');
    this.setFilterOptionsAndGetTimesheets();
    initializeDateTimePlaceholders();
  },
  methods: _objectSpread(_objectSpread({}, mapMutations({
    resetPaging: "resetPaging",
    clearTimeSheetFilterOption: "setClearFilterOptions",
    resetSnackbarDetails: "clearSnackbarDetails"
  })), {}, {
    applySearchTimeSheetFilter: function applySearchTimeSheetFilter() {
      this.timesheetsFilterLoader = true;
      this.clearTimeSheetFilterOption();
      this.resetPaging();
      this.timesheetsList = [];
      this.filtersApplied = true;
      this.setFilterOptionsAndGetTimesheets();
    },
    clearAndResetTimeSheetsPagingStates: function clearAndResetTimeSheetsPagingStates() {
      $(window).unbind("scroll");
      this.$store.state.timeSheetsModule.timesheetsList = [];
      this.clearTimeSheetFilterOption();
      this.resetPaging();
      this.resetSnackbarDetails();
      this.timesheetListFilterType = 2;
      this.filtersApplied = false;
    },
    filterTimesheetByDateRange: function filterTimesheetByDateRange(start, end) {
      this.timesheetsFilterStartDate = moment(start).utc().format();
      this.timesheetsFilterEndDate = moment(end).utc().format();
      this.timesheetDateFilterApplied = true;
      this.clearTimeSheetFilterOption();
      this.resetPaging();
      this.$store.state.timeSheetsModule.timesheetsList = [];
      this.filtersApplied = true;
      this.setFilterOptionsAndGetTimesheets();
    },
    clearTimesheetDateFilter: function clearTimesheetDateFilter() {
      $('.vdpr-datepicker input[type=text]').first().val('');
      clearDateTimePicker(this.$refs.datePicker.$el);
      this.timesheetDateFilterApplied = false;
      this.timesheetsFilterStartDate = null;
      this.timesheetsFilterEndDate = null;
      this.timesheetsList = [];
      this.clearTimeSheetFilterOption();
      this.resetPaging();
      this.setFilterOptionsAndGetTimesheets();
    },
    getDateFormatted: function getDateFormatted(date) {
      return moment.utc(date).local().format('DD MMM YYYY');
    },
    getDateTimeInExcelFormat: function getDateTimeInExcelFormat(date) {
      return moment.utc(date).local().format('DD_MMM_YYYY');
    },
    getTimeInitials: function getTimeInitials(date) {
      return moment.utc(date).local().format("LT");
    },
    openAddTimeListForm: function openAddTimeListForm() {
      this.$store.commit('setDefaultMember', [this.loginUserName, this.loginUserRole]);
      this.$store.dispatch('getTimesheetMembersList');
      this.$store.dispatch('getTimesheetMembersWorkTime', this.getSenderId);
      openOverlay("addTimeSheetFormOverlay");
    },
    openTimeSheetEditForm: function openTimeSheetEditForm(id) {
      var $this = this.$store;
      this.resetSnackbarDetails();
      $this.dispatch('getTimesheetDetails', id);
      openOverlay("editTimeSheetFormOverlay");
    },
    confirmDeleteTimeSheet: function confirmDeleteTimeSheet(id) {
      this.timeSheetDeleteId = id;
      this.dialogTimeSheet = true;
    },
    proceedTimeSheetDeletion: function proceedTimeSheetDeletion() {
      var _this = this;
      this.dialogTimeSheet = false;
      this.resetSnackbarDetails();
      this.$store.dispatch('deleteTimesheet', this.timeSheetDeleteId).then(function (res) {
        _this.clearTimeSheetFilterOption();
        _this.resetPaging();
        if (res != undefined) {
          _this.timesheetsList = [];
          _this.setFilterOptionsAndGetTimesheets();
        }
      });
    },
    openJobList: function openJobList(startTime, memberId) {
      if (startTime && memberId) {
        removeAllLocalParamsJobs();
        this.$store.state.isRoutedFromReferencePage = true;
        this.routedFromTimesheets = true;
        var startTimeUTC = moment(startTime).utc().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }).format();
        var endTimeUTC = moment(startTimeUTC).add('1', 'days').utc().format();
        if (startTimeUTC && endTimeUTC) {
          this.setJobsFilterParams(startTimeUTC, endTimeUTC, memberId);
        }
      }
      router.push(routeJobs);
    },
    setJobsFilterParams: function setJobsFilterParams(startTime, endTime, memberId) {
      this.setLocalParamsMx(startTime, 'jobStartDate');
      this.setLocalParamsMx(endTime, 'jobEndDate');
      this.setLocalParamsMx(memberId, 'jobAssignedUserIds');
    },
    getTotalAvgHours: function getTotalAvgHours(time) {
      var hour = Math.floor(time / 60);
      var min = time % 60;
      if (min != 0) {
        return hour + '.' + min;
      } else {
        return hour;
      }
    },
    getMemberTimesheet: function getMemberTimesheet(memberId) {
      this.timesheetsFilterLoader = true;
      this.resetPaging();
      this.$store.state.tempUrl = '';
      if (memberId == undefined) {
        this.clearTimeSheetFilterOption();
      } else {
        this.timesheetsSelectedMemberId = memberId;
      }
      this.timesheetsList = [];
      this.setFilterOptionsAndGetTimesheets();
    },
    updateSearchMemberText: function updateSearchMemberText(e) {
      var searchedText = e.target.value;
      if (searchedText.length == 0) {
        this.timesheetMembersSearchText = null;
        this.applyMembersFilterSearchTimer();
      } else {
        this.timesheetMembersSearchText = searchedText;
        this.applyMembersFilterSearchTimer();
      }
    },
    applyMembersFilterSearchTimer: function applyMembersFilterSearchTimer() {
      var _this2 = this;
      if (this.searchTimerMemberFilter) {
        clearTimeout(this.searchTimerMemberFilter);
        this.searchTimerMemberFilter = null;
      }
      this.searchTimerMemberFilter = setTimeout(function () {
        _this2.searchTeamMembersFilter();
      }, ConstantValues.defaultDropdownSearchTimeout);
    },
    searchTeamMembersFilter: function searchTeamMembersFilter() {
      var _this3 = this;
      this.clearTimeSheetFilterOption();
      this.$store.dispatch('setSearchTextForTimesheetMembers').then(function (res) {
        _this3.$store.dispatch('getTimesheetMembersList');
        if (_this3.timesheetMembersSearchText == '') {
          _this3.clearTimeSheetFilterOption();
        }
      });
    },
    setPreviousMonthDateFilter: function setPreviousMonthDateFilter() {
      var priorDate = new Date();
      priorDate = priorDate.setDate(priorDate.getDate() - 30);
      this.timesheetsFilterStartDate = moment(priorDate).utc().format();
    },
    getMoreTimesheetDataForExport: function getMoreTimesheetDataForExport() {
      var _this4 = this;
      this.$store.dispatch('getTimesheetDataForExport').then(function () {
        if (_this4.timesheetDataListLoadedCount < _this4.timesheetDataListTotalCount) {
          _this4.getMoreTimesheetDataForExport();
        } else {
          if (_this4.timesheetDataListForExport.length > 0) {
            _this4.showTimesheetExportDialog = false;
            _this4.saveTimesheetDataToExcel();
          }
        }
      });
    },
    getTimesheetDataForDownload: function getTimesheetDataForDownload() {
      var _this5 = this;
      this.$store.dispatch('setFilterForTimesheetDataExport').then(function () {
        _this5.$store.dispatch('getTimesheetDataForExport').then(function () {
          _this5.getMoreTimesheetDataForExport();
        });
      });
    },
    getExcelFilename: function getExcelFilename() {
      var _this6 = this;
      var memberName = 'all';
      if (this.timesheetsSelectedMemberId != null) memberName = this.timesheetsMembersList.filter(function (member) {
        return member.id == _this6.timesheetsSelectedMemberId;
      })[0].name;
      return 'timesheets_' + memberName + '_' + (this.timesheetsFilterStartDate ? this.getDateTimeInExcelFormat(this.timesheetsFilterStartDate) : '') + (this.timesheetsFilterEndDate ? '_to_' + this.getDateTimeInExcelFormat(this.timesheetsFilterEndDate) : '');
    },
    saveTimesheetDataToExcel: function saveTimesheetDataToExcel() {
      excelParser().exportDataFromJSON(this.timesheetDataListForExport, this.getExcelFilename(), ConstantValues.defaultListExportFormat);
    },
    initiateTimesheetExport: function initiateTimesheetExport() {
      this.$store.commit('resetTimesheetExportData');
      this.clearTimeSheetFilterOption();
      this.resetPaging();
      this.showTimesheetExportDialog = true;
      this.getTimesheetDataForDownload();
    },
    selectAllTimesheets: function selectAllTimesheets() {
      var _this7 = this;
      this.selectedItems = [];
      this.allItemsDeleted = false;
      if (this.isChecked) {
        this.timesheetsList.map(function (pro) {
          _this7.selectedItems.push(pro.id);
        });
      }
    },
    getTimeInHoursAndMins: function getTimeInHoursAndMins(durationInMins) {
      return getHoursAndMinsText(durationInMins);
    },
    getShortNote: function getShortNote(textNote) {
      return getShortName(textNote, 28);
    },
    setFilterOptionsAndGetTimesheets: function setFilterOptionsAndGetTimesheets() {
      var _this8 = this;
      var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize];
      this.$store.dispatch('setTimesheetsFilter', filters).then(function () {
        _this8.$store.dispatch('getTimesheetsList').then(function () {
          _this8.timesheetsFilterLoader = false;
        });
        _this8.$store.dispatch('getTimesheetsStatistics');
        if (_this8.timesheetsSearchText == '') {
          _this8.clearTimeSheetFilterOption();
          _this8.filtersApplied = false;
        }
      });
    }
  })
};